import { useEffect, useRef, useState } from 'react';
import { FolderOutlined } from '@ant-design/icons';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';

const MoveFile = ({ close, handleFileMove }) => {
	const [selectedFolder, setSelectedFolder] = useState(null);
	const [isVisible, setIsVisible] = useState(true);
	const [folders, setFolders] = useState([]);

	const popupRef = useRef(null);

	const onDoneTextClick = () => {
		if (!selectedFolder) {
			setIsVisible(false);
			setTimeout(() => {
				close();
			}, 250);
		} else {
			handleFileMove(selectedFolder.id);
			setIsVisible(false);
			setTimeout(() => {
				close();
			}, 250);
		}
	};

	const refreshFolders = async () => {
		try {
			const response = await axios.get(`${serverURL}/list_folders`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			});
			const retrievedFolders = response.data.folders.map((folder) => ({
				name: folder.name,
				id: folder.id,
				path: folder.path,
			}));
			setFolders(retrievedFolders);
		} catch (error) {
			console.error('Error fetching folders:', error);
		}
	};

	useEffect(() => {
		refreshFolders();
	}, []);

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				close();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div
			ref={popupRef}
			className='w-full bg-overlay-new-background overflow-hidden flex pt-[100px] px-0 pb-0 box-border leading-[normal] tracking-[normal]'
		>
			<div
				className={`h-screen flex-1 rounded-t-xl rounded-b-none bg-white flex flex-col pt-[27px] px-5 box-border gap-[28px] max-w-full text-left text-sm text-white font-montserrat transition-transform ${
					isVisible ? 'animate-slideUp' : 'animate-slideDown'
				}`}
			>
				<header className='self-stretch flex flex-row items-start justify-between top-[0] z-[99] sticky gap-[20px] text-left text-base text-updated-color-blue font-montserrat'>
					<div
						className='relative inline-block min-w-[55px] cursor-pointer'
						onClick={() => {
							setIsVisible(false);
							setTimeout(() => {
								close();
							}, 250);
						}}
					>
						Cancel
					</div>
					<div className='flex flex-col items-start justify-start py-0 pr-[13px] pl-0 text-updated-color-new-black font-semibold'>
						Select a Folder
					</div>
					<div
						className='w-11 relative text-right inline-block min-w-[44px] cursor-pointer'
						onClick={onDoneTextClick}
					>
						Done
					</div>
				</header>
				<div className='w-full flex flex-col items-center bg-updated-color-grey1 max-h-[75%] overflow-y-scroll gap-2 text-updated-color-grey2 rounded-lg p-2'>
					{folders.map((folder) => (
						<div
							key={folder.id}
							className={`self-stretch flex items-center justify-start py-2 px-3 gap-2 rounded-lg ${selectedFolder?.id === folder.id ? 'bg-white' : ''}`}
							onClick={() => setSelectedFolder(folder)}
						>
							<FolderOutlined className='h-6 w-6 shrink-0 text-xl' />
							<div className='min-w-[55px]'>{folder.name}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default MoveFile;
