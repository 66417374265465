import { useState, useCallback, useEffect, useRef } from 'react';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PortalPopup from './PortalPopup';
import { MobileNewFolder } from './MobileNewFolder';
import MobileFolder from './MobileFolder';
import { SearchOutlined } from '@ant-design/icons';

export const MobileFolders = () => {
	const [canCreateNewFolder, setCanCreateNewFolder] = useState(true);
	const [folders, setFolders] = useState([]);
	const [query, setQuery] = useState('');
	const [searching, setSearching] = useState(false);
	const searchBarRef = useRef(null);
	const navigate = useNavigate();
	const debounceTimeoutRef = useRef(null);
	const popupRef = useRef(null);
	const moreOutlinedRef = useRef(null);
	const [openPopup, setOpenPopup] = useState(false);
	const [createFolderOpen, setCreateFolderOpen] = useState(false);
	const openPopupRef = useRef(openPopup);

	const onIconFrameClick = () => {
		navigate('/docs');
	};

	const closeCreateANewFolder = () => {
		setCreateFolderOpen(false);
	};

	const openCreateANewFolder = useCallback(() => {
		setCreateFolderOpen(true);
	}, []);

	const refreshFolders = async () => {
		try {
			const response = await axios.get(`${serverURL}/list_folders`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			});
			const retrievedFolders = response.data.folders.map((folder) => ({
				name: folder.name,
				id: folder.id,
				path: folder.path,
			}));
			setFolders(retrievedFolders);
		} catch (error) {
			console.error('Error fetching folders:', error);
		}
	};

	const handleFolderNameSubmit = async (folderName) => {
		try {
			await fetch(serverURL + '/create_folder?folder_name=' + folderName, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			});
			console.log('Folder created successfully: ' + folderName);
		} catch (error) {
			console.error('Error creating folder: ' + error);
		}
		refreshFolders();
	};

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				if (!moreOutlinedRef.current.contains(event.target)) {
					setOpenPopup(!openPopupRef.current);
				}
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		openPopupRef.current = openPopup;
	}, [openPopup]);

	const adjustPopupPosition = () => {
		if (popupRef.current) {
			const rect = popupRef.current.getBoundingClientRect();
			if (rect.left < 0) {
				// 16px margin
				popupRef.current.style.left = '0px';
			}
		}
	};

	useEffect(() => {
		adjustPopupPosition();
	}, [openPopup]);

	const fetchFolders = async (text_search = '') => {
		try {
			await jwtTokenRef.current;
			const response = await axios.get(`${serverURL}/list_folders`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
				params: {
					folder_id: null, // Update as needed
					page_size: 10, // Update as needed
					page_number: 1, // Update as needed
					get_raw_transcript: false, // Update as needed
					text_search, // Added text_search parameter
				},
			});
			setFolders(response.data.folders);
		} catch (error) {
			console.error('Error fetching folders:', error);
		}
	};

	useEffect(() => {
		fetchFolders();
	}, []);

	useEffect(() => {
		if (searching) {
			setCanCreateNewFolder(false);
		} else {
			setCanCreateNewFolder(true);
		}
	}, [searching]);

	useEffect(() => {
		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}
		debounceTimeoutRef.current = setTimeout(() => {
			fetchFolders(query);
		}, 1000);
		return () => clearTimeout(debounceTimeoutRef.current);
	}, [query]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				searchBarRef.current &&
				!searchBarRef.current.contains(event.target)
			) {
				setSearching(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="max-w-screen flex flex-col pt-16 ml-[20px] mr-[20px] items-start justify-start gap-[1.25rem] text-left text-[1rem] text-white font-montserrat">
			{createFolderOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeCreateANewFolder}
				>
					<MobileNewFolder
						close={closeCreateANewFolder}
						handleFolderNameSubmit={handleFolderNameSubmit}
					/>
				</PortalPopup>
			)}

			<div className="w-full flex flex-row items-start justify-between gap-[1.25rem]">
				<img
					className="h-[2.25rem] w-[2.25rem] relative cursor-pointer"
					loading="lazy"
					alt=""
					src="/arrow-left.svg"
					onClick={onIconFrameClick}
				/>
			</div>
			<div
				ref={searchBarRef}
				className="w-full flex items-center justify-start py-[0.25rem] pl-[0.5rem] pr-[2rem] gap-[0.625rem] relative"
			>
				<SearchOutlined className="text-2xl text-updated-color-grey2 -mr-10 relative z-10 pt-2" />
				<input
					className={`w-full transition-all duration-300 ease-in-out pl-10 pr-${
						true ? '14' : '10'
					} [border:none] [outline:none] rounded-xl bg-updated-color-grey1 font-montserrat text-[1rem] h-8 leading-[1.375rem] text-updated-color-grey2 text-left inline-block`}
					placeholder="search"
					type="text"
					onClick={() => {
						setSearching(true);
					}}
					value={query}
					onChange={(e) => setQuery(e.target.value)}
				/>
				{searching && (
					<span
						className="right-0 text-base text-updated-color-blue transition-opacity duration-300 ease-in-out"
						onClick={() => {
							setSearching(false);
							setQuery('');
						}}
					>
						Cancel
					</span>
				)}
			</div>
			<div className="self-stretch flex flex-col items-center justify-start gap-[0.937rem]">
				{!searching && (
					<div className="self-stretch flex flex-row items-center text-[1.5rem] text-updated-color-new-black">
						<div className="relative font-semibold inline-block min-w-[5.75rem]">
							Folders
						</div>
					</div>
				)}
				<div className="w-full grid grid-cols-2 py-[0rem] gap-8 px-[0.562rem] gap-[1.25rem] text-[1rem] text-darkslategray-200">
					{canCreateNewFolder && (
						<div
							className="w-11/12 h-20 rounded-3xs bg-slate-100 shadow-[0px_1px_2.6px_rgba(62,_62,_62,_0.2)] flex flex-col items-start justify-start pt-[0.625rem] px-[0.625rem] pb-[2.5rem] text-not-black"
							onClick={openCreateANewFolder}
						>
							<img
								className="w-10 h-10 relative overflow-hidden object-contain"
								alt=""
								src="/plus.svg"
							/>
							<div className="relative font-medium inline-block min-w-[5.625rem]">
								Create a folder
							</div>
						</div>
					)}
					{folders.map((folder, index) => (
						<MobileFolder
							key={index}
							folder={folder}
							refreshFolders={refreshFolders}
						></MobileFolder>
					))}
				</div>
			</div>
		</div>
	);
};
