// reads the languages.json list and parses it.
import languages from './languages.json';
console.log(languages);
var top100WorldLanguages = [];
for (var i = 0; i < languages.length; i++) {
  top100WorldLanguages.push(languages[i]['English']);
}

var languageToCode = {};
for (var i = 0; i < languages.length; i++) {
  languageToCode[languages[i]['English']] = languages[i]['alpha2'];
}

var fileTranslateLanguages = [
  "English", "Simplified Chinese", "Traditional Chinese (Hong Kong)", "Traditional Chinese (Taiwan)", 
  "Traditional Chinese", "Spanish", "Portuguese", "Russian", "Italian", "French", "German", 
  "Ukrainian", "Swedish", "Japanese", "Japanese (Honorifics)", "Japanese (Humble)", "Japanese (Polite)", 
  "Polish", "Turkish", "Korean", "Malay", "Lao", "Thai", "Vietnamese", "Bengali", "Burmese", 
  "Finnish", "Georgian", "Greek", "Albanian", "Arabic", "Armenian", "Azerbaijani", "Belarusian", 
  "Bulgarian", "Canadian French", "Catalan", "Croatian", "Czech", "Danish", "Dutch", "Estonian", 
  "Filipino", "Gujarati", "Haitian Creole", "Hebrew", "Hindi", "Hungarian", "Icelandic", 
  "Indonesian", "Irish", "Kazakh", "Khmer", "Latin", "Latin American Spanish", "Latvian", 
  "Lithuanian", "Macedonian", "Maltese", "Mongolian (Cyrillic)", "Norwegian", "Persian", 
  "Romanian", "Serbian (Cyrillic letters)", "Serbian (Latin letters)", "Sinhala", "Slovak", 
  "Slovenian", "Swahili", "Tamil", "Telugu", "Oriya", "Kannada", "Punjabi", "Marathi", 
  "Malayalam", "Uzbek", "Urdu", "Amharic", "Uyghur", "Cantonese", "Yiddish", "Tigrinya", 
  "Any Language"
]


console.log(top100WorldLanguages);

export { top100WorldLanguages, languageToCode, fileTranslateLanguages };
