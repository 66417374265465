import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	serverURL,
	jwtTokenRef,
	userInfoRef,
	updateAccountInfo,
} from '../../httpContext';
import {
	LockOutlined,
	EyeOutlined,
	EyeInvisibleOutlined,
} from '@ant-design/icons';
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
} from 'firebase/auth';
import axios from 'axios';

const Login = ({ setIsLoggedIn, setShowMobile }) => {
	const navigate = useNavigate();
	const [password1, setPassword1] = useState('');
	const [email, setEmail] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const emailField = useRef(null);
	const passwordField = useRef(null);
	const [error, setError] = useState('');
	const [submitted, setSubmitted] = useState(false);

	const auth = getAuth();
	
	const provider = new GoogleAuthProvider();

	const onForgetPasswordTextClick = useCallback(() => {
		navigate('/forgot-password');
	}, [navigate]);

	const checkUserAndLogin = async (user) => {
		if (user) {
			try {
				const token = await user.getIdToken();
				const response = await axios.post(`${serverURL}/login-with-google`, {
					token: token,
				});
				jwtTokenRef.current = response.data.access_token;
				localStorage.setItem('jwtToken', jwtTokenRef.current);
				await updateAccountInfo(jwtTokenRef, userInfoRef);

				setIsLoggedIn(true);
				navigate('/');
			} catch (error) {
				console.error('Error handling authentication: ' + error);
				setError('Authentication failed. Please try again.');
			}
		}
	};

	const signInWithGoogle = async () => {
		try {
			const result = await signInWithPopup(auth, provider);
			checkUserAndLogin(result.user);
		} catch (error) {
			console.error('Error during Google sign-in: ', error);
			setError('Google sign-in failed. Please try again.');
		}
	};

	const onLogin = useCallback(
		async (e) => {
			e.preventDefault();
			setError('');
			setSubmitted(true);

			if (email === '' || password1 === '') {
				setError('Please fill in all fields');
				setSubmitted(false);
				return;
			}

			try {
				const response = await fetch(`${serverURL}/login`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: email || emailField.current.value,
						password: password1 || passwordField.current.value,
					}),
				});

				const response_json = await response.json();

				if (response.status === 200) {
					jwtTokenRef.current = response_json.access_token;
					localStorage.setItem('jwtToken', jwtTokenRef.current);
					await updateAccountInfo(jwtTokenRef, userInfoRef);

					setError('');
					setIsLoggedIn(true);
					navigate('/');
				} else {
					setError(response_json.detail || 'Invalid email or password');
				}
			} catch (e) {
				console.error('Error during login: ', e);
				setError('Internal server error');
			} finally {
				setSubmitted(false);
			}
		},
		[email, password1, navigate, setIsLoggedIn]
	);

	const onDontHaveAnClick = useCallback(() => {
		navigate('/signup');
	}, [navigate]);

	useEffect(() => {
		if (jwtTokenRef.current && userInfoRef.current) {
			if (userInfoRef.current.verified_email) {
				setIsLoggedIn(true);
				navigate('/');
			} else {
				navigate('/email-verification');
			}
		}
	}, [navigate, setIsLoggedIn]);

	return (
		<div className="h-full pt-16 pb-4 px-4 sm:px-6 lg:px-8 tracking-[normal] text-center text-xs text-gray-100 font-montserrat flex flex-col justify-center items-center overflow-y-scroll">
			<div className="w-full max-w-md flex flex-col items-center gap-6 sm:gap-8 text-left text-2xs text-gray-100 font-poppins">
				<div className="w-full flex flex-row items-center">
					<img
						className="h-8 w-8 sm:h-10 sm:w-10 relative overflow-hidden shrink-0 cursor-pointer"
						loading="lazy"
						alt=""
						src="/mobileback.svg"
						onClick={() => {
							navigate('/signup');
						}}
					/>
				</div>
				<form
					onSubmit={onLogin}
					className="w-full flex flex-col items-center gap-10 sm:gap-12"
				>
					<div className="w-full flex flex-col items-center gap-[7rem]">
						<div className="self-start text-lg sm:text-xl font-semibold font-montserrat text-updated-color-new-black text-center">
							Login
						</div>
						<div className="w-full flex flex-col gap-4 sm:gap-5">
							<div className="w-full flex flex-row flex-wrap items-center justify-center gap-2 sm:gap-3">
								<img
									className="h-5 w-5 relative overflow-hidden shrink-0"
									alt=""
									src="/email.svg"
								/>
								<div className="flex-1 border-0 flex flex-row items-center py-2.5 px-0 min-w-0 border-b border-solid border-updated-color-grey2">
									<input
										className="w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0"
										placeholder="Enter your email"
										ref={emailField}
										type="email"
										name="email"
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
										}}
										autoComplete="email"
										id="email-input"
									/>
								</div>
							</div>
							<div className="w-full flex flex-row flex-wrap items-center justify-center gap-2 sm:gap-3">
								<LockOutlined className="text-lg text-black relative overflow-hidden shrink-0" />
								<div className="flex-1 border-0 flex flex-row items-center pt-2 px-0 pb-1.5 relative gap-2 sm:gap-3 min-w-0 min-h-[37px] border-b border-solid border-updated-color-grey2">
									<input
										className="w-full [border:none] [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0"
										placeholder="Enter your password"
										ref={passwordField}
										type={showPassword ? 'text' : 'password'}
										name="password"
										onChange={(e) => setPassword1(e.target.value)}
										value={password1}
										autoComplete="current-password"
										id="password-input"
									/>
									{showPassword ? (
										<EyeOutlined
											className="text-lg text-black absolute right-0 overflow-hidden shrink-0 cursor-pointer"
											onClick={() => setShowPassword(false)}
										/>
									) : (
										<EyeInvisibleOutlined
											className="text-lg text-black absolute right-0 overflow-hidden shrink-0 cursor-pointer"
											onClick={() => setShowPassword(true)}
										/>
									)}
								</div>
							</div>
							<div
								className="self-end text-[0.75rem] [text-decoration:underline] font-medium text-updated-color-blue min-w-[6.875rem] cursor-pointer"
								onClick={onForgetPasswordTextClick}
							>
								Forgot Password?
							</div>
						</div>
					</div>
					<button
						className="[border:none] text-base font-semibold font-montserrat text-white py-2.5 px-5 bg-transparent w-full rounded-3xs [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] flex flex-row items-center justify-center"
						type="submit"
					>
						{submitted && (
							<svg
								className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									strokeWidth="4"
								></circle>
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
						)}
						{!submitted ? 'Login' : 'Logging in...'}
					</button>
				</form>
				{error && (
					<p className="text-red-500 text-lg text-center w-full h-fit">
						{error}
					</p>
				)}
				<div className="w-full flex flex-col items-center gap-6 sm:gap-8">
					<div className="w-full flex flex-row items-center justify-center">
						<div className="w-full flex flex-row items-center justify-center gap-2 sm:gap-4">
							<div className="flex w-full justify-end">
								<div className="w-1/2 h-[1px] bg-updated-color-grey2" />
							</div>
							<div className="flex items-center justify-center">
								<span className="text-updated-color-grey2 font-medium text-lg">
									OR
								</span>
							</div>
							<div className="w-full flex">
								<div className="w-1/2 h-[1px] bg-updated-color-grey2" />
							</div>
						</div>
					</div>
					<form className=" flex flex-col items-center gap-5">
						<div
							className="w-full rounded-lg bg-updated-color-grey1 flex items-center py-3 px-5 gap-12"
							onClick={() => {
								signInWithGoogle();
							}}
						>
							<img
								className="h-8 w-8"
								alt="Sign up with Google"
								src="/google@2x.png"
							/>
							<span className="text-lg font-medium font-montserrat text-updated-color-grey2">
								Sign In with Google
							</span>
						</div>
					</form>
				</div>
			</div>
			<div className="mt-8 flex flex-row items-center justify-center w-full">
				<div
					className="text-sm sm:text-base font-medium cursor-pointer"
					onClick={onDontHaveAnClick}
				>
					<span className="text-updated-color-grey2">
						Don't have an account?
					</span>{' '}
					<span className="text-dodgerblue-200 underline">
						Create an Account
					</span>
				</div>
			</div>
		</div>
	);
};

export default Login;
