import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sharedRoomIdRef } from '../socketContext';
import { socket } from '../socketContext';
import RoomLinkAndQRCode from './mobile/RoomLinkAndQRCode';

const SelectTypesOfRoomToStart = ({ onOutsideClickFunc }) => {
	const selectRoomRef = useRef(null);

	const [isTemporaryRoomWindowOpen, setIsTemporaryRoomWindowOpen] =
		useState(false);
	const isTemporaryRoomWindowOpenRef = useRef(isTemporaryRoomWindowOpen);

	useEffect(() => {
		isTemporaryRoomWindowOpenRef.current = isTemporaryRoomWindowOpen;
	}, [isTemporaryRoomWindowOpen]);

	const [isCustomRoomWindowOpen, setIsCustomRoomWindowOpen] = useState(false);
	const isCustomRoomRef = useRef(isCustomRoomWindowOpen);

	useEffect(() => {
		isCustomRoomRef.current = isCustomRoomWindowOpen;
	}, [isCustomRoomWindowOpen]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				selectRoomRef.current &&
				!selectRoomRef.current.contains(event.target) &&
				!isTemporaryRoomWindowOpenRef.current
			) {
				onOutsideClickFunc();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [onOutsideClickFunc]);

	const TemporaryRoomCreated = () => {
		const navigate = useNavigate();
		const inputRefs = useRef([]);

		const [isLinkAndQRCodeVisible, setIsLinkAndQRCodeVisible] = useState(false);
		const isLinkAndQRCodeVisibleRef = useRef(isLinkAndQRCodeVisible);
		const temporaryRoomContainerRef = useRef(null); // Create a ref for the div
		const linkAndQRCodeRef = useRef(null); // Create a ref for the div

		useEffect(() => {
			isLinkAndQRCodeVisibleRef.current = isLinkAndQRCodeVisible;
		}, [isLinkAndQRCodeVisible]);

		const [pin, setPin] = useState(['', '', '', '']);
		const pinRef = useRef(pin);
		useEffect(() => {
			socket.on('room', (data) => {
				if (data === undefined || data === null || data === '') {
					return;
				}
				sharedRoomIdRef.current = data;
				setPin(data.split(''));
			});
		}, []);

		// keep the pinRef and pin state in sync
		useEffect(() => {
			pinRef.current = pin;
		}, [pin]);

		useEffect(() => {
			const handleClickOutside = (event) => {
				if (
					isLinkAndQRCodeVisibleRef.current &&
					linkAndQRCodeRef.current &&
					!linkAndQRCodeRef.current.contains(event.target)
				) {
					setIsLinkAndQRCodeVisible(false);
				}
				if (
					temporaryRoomContainerRef.current &&
					!temporaryRoomContainerRef.current.contains(event.target) &&
					isTemporaryRoomWindowOpenRef.current &&
					!isLinkAndQRCodeVisibleRef.current
				) {
					onOutsideClickFunc();
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			return () =>
				document.removeEventListener('mousedown', handleClickOutside);
		}, []);

		return (
			<div className="absolute flex flex-col justify-center items-center m-auto left-0 right-0 top-0 bottom-0">
				{isLinkAndQRCodeVisible && (
					<RoomLinkAndQRCode
						divRef={linkAndQRCodeRef}
						link={
							'https://interpretapp.ai/broadcast/' + sharedRoomIdRef.current
						}
						navigateUrl={'/broadcast-host/' + sharedRoomIdRef.current}
						onOutsideClickFunc={() => {
							setIsLinkAndQRCodeVisible(false);
						}}
					></RoomLinkAndQRCode>
				)}
				{!isLinkAndQRCodeVisible && (
					<div
						ref={temporaryRoomContainerRef}
						className="absolute flex flex-col justify-center items-center bg-gray-100 px-8 py-[45px] pb-[20px] rounded-xl z-10"
					>
						<div className="font-semibold inline-block mb-[20px] text-center">
							Enter the PIN
						</div>
						<div className="inline-flex flex-row items-start justify-center gap-[10px] mx-auto">
							{[...Array(4)].map((_, index) => (
								<input
									key={index}
									ref={(el) => (inputRefs.current[index] = el)}
									maxLength="1"
									className={`rounded-3xs box-border flex flex-col items-center justify-center p-[19px] min-w-[46px] max-w-[59px] border-[1px] border-solid ${pin[index] ? 'border-updated-color-new-black' : 'bg-updated-color-grey2'} text-center`}
									type="text"
									pattern="[0-9]*"
									inputMode="numeric"
									readOnly
									value={pin[index]}
								/>
							))}
						</div>
						<button
							onClick={() => {
								navigate(`/broadcast-host/${sharedRoomIdRef.current}`);
							}}
							className={`mt-4 w-full cursor-pointer [border:none] py-2.5 px-[75.5px] rounded-[10px] ${pin.join('').length === 4 ? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]' : 'bg-updated-color-grey2'} flex items-center justify-center whitespace-nowrap`}
						>
							<div className="relative text-base font-medium font-montserrat text-white text-center">
								Enter
							</div>
						</button>
						<button
							onClick={() => {
								if (navigator.share) {
									navigator.share({
										title: 'Interpret App',
										text: 'Join my room on Interpret App',
										url:
											'https://interpretapp.ai/broadcast/' +
											sharedRoomIdRef.current,
									});
								}
							}}
							className={`mt-4 w-full [border:none] py-2.5 px-[50px] rounded-[10px] ${pin.join('').length === 4 ? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]' : 'bg-updated-color-grey2'} flex items-center justify-center whitespace-nowrap`}
						>
							<div className="relative text-base font-medium font-montserrat text-white text-center inline-block min-w-[119px]">
								Share PIN
							</div>
						</button>
					</div>
				)}
			</div>
		);
	};

	const CustomRoom = () => {
		const [customRoomName, setCustomRoomName] = useState('');
		const inputRef = useRef(null);
		const [isLinkAndQRCodeVisible, setIsLinkAndQRCodeVisible] = useState(false);
		const isLinkAndQRCodeVisibleRef = useRef(isLinkAndQRCodeVisible);
		const customRoomInputWindowRef = useRef(null);
		const linkAndQRCodeRef = useRef(null);

		useEffect(() => {
			isLinkAndQRCodeVisibleRef.current = isLinkAndQRCodeVisible;
		}, [isLinkAndQRCodeVisible]);

		const handleInputChange = (e) => {
			setCustomRoomName(e.target.value);
		};

		const handleInputClick = () => {
			inputRef.current.focus();
		};

		useEffect(() => {
			socket.on('room', (data) => {
				console.log('created room: ', data);
				if (data === undefined || data === null || data === '') {
					return;
				}
				sharedRoomIdRef.current = data;
				setIsLinkAndQRCodeVisible(true);
			});
		}, []);

		useEffect(() => {
			const handleClickOutside = (event) => {
				if (
					isLinkAndQRCodeVisibleRef.current &&
					!linkAndQRCodeRef.current.contains(event.target) &&
					linkAndQRCodeRef.current
				) {
					setIsLinkAndQRCodeVisible(false);
				}
				if (
					customRoomInputWindowRef.current &&
					!customRoomInputWindowRef.current.contains(event.target) &&
					isCustomRoomRef.current
				) {
					setIsCustomRoomWindowOpen(false);
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			return () =>
				document.removeEventListener('mousedown', handleClickOutside);
		}, [onOutsideClickFunc]);

		return (
			<div className="h-full flex flex-col justify-center items-center z-10">
				{isLinkAndQRCodeVisible && (
					<RoomLinkAndQRCode
						divRef={linkAndQRCodeRef}
						link={
							'https://interpretapp.ai/broadcast/' + sharedRoomIdRef.current
						}
						navigateUrl={'/broadcast-host/' + sharedRoomIdRef.current}
						onOutsideClickFunc={() => {
							setIsLinkAndQRCodeVisible(false);
						}}
					/>
				)}
				{!isLinkAndQRCodeVisible && (
					<div
						ref={customRoomInputWindowRef}
						className="relative flex flex-col justify-center items-center bg-gray-100 px-[35px] py-[45px] pb-[20px] rounded-xl z-10"
					>
						<img
							className="w-6 h-6 absolute top-4 right-4"
							loading="lazy"
							alt=""
							src="/interface--close-circle-1.svg"
							onClick={() => {
								setIsCustomRoomWindowOpen(false);
							}}
						/>
						<div className="font-semibold inline-block mb-[20px] text-center">
							Name your room
						</div>
						<input
							ref={inputRef}
							className="rounded-3xs box-border flex flex-col items-center justify-center p-[19px] min-w-[46px] max-w-full border-[1px] border-solid border-updated-color-new-black text-center"
							type="text"
							pattern="[0-9]*"
							inputMode="text"
							value={customRoomName}
							onChange={handleInputChange}
							onClick={handleInputClick}
						/>
						<button
							onClick={() => {
								socket.emit('join_room', customRoomName);
							}}
							className={`mt-4 cursor-pointer [border:none] py-2.5 px-[75.5px] rounded-[10px] ${
								customRoomName.trim() !== ''
									? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]'
									: 'bg-updated-color-grey2'
							} flex items-center justify-center whitespace-nowrap`}
						>
							<div className="relative text-base font-medium font-montserrat text-white text-center inline-block min-w-[119px]">
								Create
							</div>
						</button>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="w-1/2 h-fit relative text-left text-lg text-updated-color-new-black font-montserrat">
			{isTemporaryRoomWindowOpen && <TemporaryRoomCreated />}
			{isCustomRoomWindowOpen && <CustomRoom />}
			{!isTemporaryRoomWindowOpen && !isCustomRoomWindowOpen && (
				<div
					ref={selectRoomRef}
					className="p-10 cursor-pointer rounded-xl bg-gray-100 flex flex-col box-border gap-[25px]"
				>
					<div
						className="flex flex-col pt-0 pb-6 border-[0px] border-b-[0.5px] border-solid border-updated-color-grey2"
						onClick={() => {
							setIsTemporaryRoomWindowOpen(true);
							socket.emit('create_room');
						}}
					>
						<div className="flex flex-col items-start justify-start gap-[5px]">
							<div className="m-0 relative text-inherit font-medium font-inherit">
								One-Time Use Room
							</div>
							<div className="relative text-xs">
								Create a Temporary Room with a one-time PIN for immediate use.
								Each PIN is unique and expires after the session ends.
							</div>
						</div>
					</div>
					<div
						className="w-full flex flex-col items-start justify-start gap-[5px]"
						onClick={() => {
							if (!isTemporaryRoomWindowOpenRef.current) {
								setIsCustomRoomWindowOpen(true);
							}
						}}
					>
						<h3 className="m-0 relative text-inherit font-medium font-inherit">
							Reusable Room
						</h3>
						<div className="self-stretch relative text-xs">
							Create a Reusable Room for ongoing access. Name your space and
							we'll provide a link that's durable for continuous use.
						</div>
					</div>
					<img
						className="w-6 h-6 absolute !m-[0] top-[10px] right-[10px] overflow-hidden shrink-0 cursor-pointer"
						alt=""
						src="/interface--close-circle-1.svg"
						onClick={onOutsideClickFunc}
					/>
				</div>
			)}
		</div>
	);
};

export default SelectTypesOfRoomToStart;
