import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';
import axios from 'axios';
import { format, parseISO, set } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { top100WorldLanguages } from '../../languages';

const FileTranscript = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [fileContent, setFileContent] = useState(null);
	const [file, setFile] = useState(null);
	const [summary, setSummary] = useState(null);
	const [showSummary, setShowSummary] = useState(false);
	const [editableContent, setEditableContent] = useState('');
	const showSummaryRef = useRef(null);
	const [outputLanguage, setOutputLanguage] = useState('Original');
	const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
	const [isDownloadDropdownOpen, setDownloadDropdownOpen] = useState(false);
	const [searchBarInput, setSearchBarInput] = useState('');
	const languageDropdownRef = useRef(null);

	const addBotToMeeting = async (meetingUrl) => {
		if (jwtTokenRef.current == undefined) {
			return;
		}

		const response = await axios.post(
			`${serverURL}/add_bot_to_zoom`,
			{ meeting_url: meetingUrl },
			{
				headers: {
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			}
		);
		if (response.status === 200) {
			console.log('Bot added to meeting');
		} else {
			console.error('Failed to add bot to meeting');
		}
		meetingUrl = extractMeetingId(meetingUrl);
		if (meetingUrl) {
			navigate(`/broadcast/${meetingUrl}`);
		}
	};

	useEffect(() => {
		let isMounted = true; // flag to check if the component is mounted

		const fetchData = async () => {
			let retrievedFile;
			do {
				try {
					const response = await axios.get(`${serverURL}/get_transcript`, {
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${jwtTokenRef.current}`,
						},
						params: {
							transcript_id: new URLSearchParams(location.search).get('id'),
						},
					});

					retrievedFile = response.data;

					console.log('response:', response);

					if (retrievedFile && isMounted) {
						// Only update state if the component is still mounted
						let rawTranscriptJson = JSON.parse(retrievedFile.raw_transcript);

						// setShowSummary(false);
						// setSummary(null);

						if (
							retrievedFile.transcript_type === 'gpu' ||
							retrievedFile.transcript_type === 'zoom'
						) {
							setFileContent(retrievedFile.transcript);
							// setEditableContent(retrievedFile.transcript);
						} else if (retrievedFile.transcript_type === 'image') {
							setFileContent(rawTranscriptJson.transcription);
						} else if (retrievedFile.transcript_type === 'file') {
							var raw_transcript = retrievedFile.raw_transcript;
							if (
								raw_transcript != null &&
								JSON.parse(raw_transcript)['status'] === 'transcribing'
							) {
								setFileContent('Translating...');
							} else if (
								raw_transcript != null &&
								JSON.parse(raw_transcript)['status'] === 'transcribed'
							) {
								setFileContent('Transcription is ready to download.');
							}
						}

						setFile(retrievedFile);
					}
				} catch (error) {
					if (error.response && error.response.status === 404) {
						console.log('File not found, retrying...');
						// You can also add additional logic here if needed, such as a counter for retries or a timeout
					} else {
						console.error('An error occurred:', error);
						// Handle other types of errors (e.g., network issues)
					}
				}

				await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
			} while (
				isMounted &&
				(!retrievedFile ||
					(!retrievedFile.transcript &&
						retrievedFile.transcript_type != 'file') ||
					(retrievedFile &&
						JSON.parse(retrievedFile.raw_transcript).status === 'transcribing'))
			);
		};

		fetchData();

		return () => {
			isMounted = false; // Set the flag to false to exit the loop
		};
	}, [
		location.state?.file,
		location.search,
		new URLSearchParams(location.search).get('id'),
	]);

	const updateTranscript = async (newTranscript) => {
		const payload = {
			transcript_id: file.id,
			transcript: newTranscript,
		};

		try {
			const response = await fetch(serverURL + '/update_transcript', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				const data = await response.json();
				console.log('Transcript updated:', data);
			} else {
				console.error('Error updating transcript:', response);
			}
		} catch (error) {
			console.error('Error updating transcript:', error);
		}
	};

	const useDebouncedCallback = (callback, delay) => {
		const timeoutRef = useRef(null);

		const debouncedCallback = useCallback(
			(...args) => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
				timeoutRef.current = setTimeout(() => {
					callback(...args);
				}, delay);
			},
			[callback, delay]
		);

		return debouncedCallback;
	};

	const debouncedUpdateTranscript = useDebouncedCallback((newText) => {
		const formattedText = newText
			.replace(/<br>/g, '\n')
			.replace(/<\/div><div>/g, '\n')
			.replace(/<\/?div>/g, ''); // This removes any remaining <div> tags
		updateTranscript(formattedText);
	}, 1000);

	// const handleEditableContentChange = (e) => {
	// 	setEditableContent(e.target.value);
	// 	debouncedUpdateTranscript(e.target.value);
	// };

	const getSummaryFromFile = (file) => {
		let raw_transcript = JSON.parse(file.raw_transcript);
		const description = raw_transcript.description;
		if (description) {
			return description;
		}
		const summaries = raw_transcript.summaries;
		if (summaries) {
			const firstSummary = Object.values(summaries)[0];
			if (firstSummary && firstSummary.summary) {
				if (firstSummary.summary.summary) {
					return firstSummary.summary.summary;
				}
				return firstSummary.summary;
			}
		}
		return '';
	};

	const handleSummarize = async () => {
		if (showSummaryRef.current) {
			setShowSummary(false);
			return;
		}
		if (getSummaryFromFile(file) !== '') {
			console.log('Summary already exists', getSummaryFromFile(file));
			setSummary(getSummaryFromFile(file));
		} else {
			setSummary('Summarizing...');
			try {
				const response = await axios.post(
					`${serverURL}/summarize_transcript?transcript_id=${file.id}&target_language=en`,
					{},
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${jwtTokenRef.current}`,
						},
					}
				);
				if (response.status !== 200) {
					throw new Error('Network response was not ok.');
				}
			} catch (error) {
				console.error('Error summarizing:', error);
				return;
			}

			const getSummary = async (retryCount = 0, maxRetries = 200) => {
				try {
					const response = await axios.get(`${serverURL}/get_raw_transcript`, {
						params: { transcript_id: file.id },
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${jwtTokenRef.current}`,
						},
					});
					if (response.status !== 200) {
						throw new Error('Network response was not ok.');
					}

					const data = response.data;
					if (
						data.summaries &&
						data.summaries.en &&
						data.summaries.en.summary &&
						data.summaries.en.summary.summary
					) {
						setSummary(data.summaries.en.summary.summary);
					} else if (retryCount < maxRetries) {
						setTimeout(() => getSummary(retryCount + 1, maxRetries), 2000);
					} else {
						console.error('Maximum retries reached, no summary available.');
					}
				} catch (error) {
					console.error('Error generating summary:', error);
				}
			};
			getSummary();
		}
		setShowSummary(true);
	};

	useEffect(() => {
		showSummaryRef.current = showSummary;
	}, [showSummary]);

	const convertNewLinesToHTML = (text) => {
		return text.replace(/\n/g, '<br>');
	};

	const translateFileContent = async (targetLanguage, text) => {
		console.log('Translating to:', targetLanguage);
		console.log('Text:', text);

		// Split the text at \n\n to get the paragraphs
		const paragraphs = text.split('\n\n');

		// filter out empty paragraphs
		const nonEmptyParagraphs = paragraphs.filter(
			(paragraph) => paragraph.trim() !== ''
		);

		// Concatenate paragraphs with \n\n while the total length is less than 1000 characters
		const newParagraphs = [];
		let currentParagraph = '';
		for (let i = 0; i < nonEmptyParagraphs.length; i++) {
			const paragraph = nonEmptyParagraphs[i];
			if (currentParagraph.length + paragraph.length < 1000) {
				currentParagraph += paragraph + '\n\n';
			} else {
				newParagraphs.push(currentParagraph.trim());
				currentParagraph = paragraph + '\n\n';
			}
		}
		if (currentParagraph) {
			newParagraphs.push(currentParagraph.trim());
		}

		const translatedTexts = await translateTexts(newParagraphs, targetLanguage);

		setFileContent(translatedTexts.join('\n\n'));
	};

	return (
		<div className="relative bg-white h-full text-left text-xl text-updated-color-new-black font-montserrat">
			<ProfileDropdown />
			<div className="flex justify-center gap-10 py-10 px-10 h-full items-center">
				<div className="flex flex-col gap-4 w-full">
					<img
						className="w-fit cursor-pointer"
						alt=""
						src="/group-1707478204.svg"
						onClick={() => {
							navigate('/docs');
						}}
					/>
					<div className="[filter:drop-shadow(1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset)] rounded-xl box-border h-[641px] border-[0.5px] border-solid border-updated-color-grey1">
						<div className="flex justify-between items-end">
							<div className="flex p-4 justify-between border-solid border-b-[.5px] border-x-0 border-t-0 w-full">
								<div className="flex flex-col pt-0 gap-[5px] text-base font-montserrat">
									<div className="relative font-semibold">{file?.name}</div>
									{file?.created_at && (
										<div className="relative text-sm">
											Created{' '}
											{format(parseISO(file.created_at), 'MMM dd, yyyy')}
										</div>
									)}
								</div>
								<div className="flex items-center gap-2">
									{file != null && file.transcript_type !== 'file' && (
										<div
											className="relative rounded-md w-full flex items-center justify-center p-2.5 border border-gray-300 cursor-pointer"
											onClick={() =>
												setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
											}
										>
											<div className="relative font-medium">
												{outputLanguage}
											</div>
											<img
												className="w-[18px] relative h-[18px] overflow-hidden shrink-0 ml-2"
												alt=""
												src="/arrow--arrow-down-2.svg"
											/>
											{isLanguageDropdownOpen && (
												<div
													ref={languageDropdownRef}
													className="absolute top-12 w-96 px-10 grid grid-cols-4 gap-6 z-10 bg-white border border-gray-300 rounded-md shadow-lg max-h-96 overflow-y-auto"
												>
													<div className="flex col-span-4 items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300">
														<input
															type="text"
															placeholder="Search..."
															className="w-full p-1 border-none outline-none text-lg"
															value={searchBarInput}
															onClick={(e) => {
																e.stopPropagation();
															}}
															onChange={(e) => {
																setSearchBarInput(e.target.value);
															}}
														/>
														<img
															className="w-5 h-5 ml-2"
															alt=""
															src="/general--search.svg"
														/>
													</div>
													<div
														key={'Original'}
														className="p-2 hover:bg-gray-200 cursor-pointer"
														onClick={() => {
															setOutputLanguage('Original');
															setIsLanguageDropdownOpen(false);
															setFileContent(file.transcript);
														}}
													>
														{'Original'}
													</div>
													{top100WorldLanguages
														.filter((language) =>
															language
																.toLowerCase()
																.includes(searchBarInput?.toLowerCase() || '')
														)
														.map((language) => (
															<div
																key={language}
																className="p-2 hover:bg-gray-200 cursor-pointer w-full"
																onClick={() => {
																	setOutputLanguage(language);
																	setIsLanguageDropdownOpen(false);
																	translateFileContent(
																		language,
																		file.transcript
																	);
																}}
															>
																{language}
															</div>
														))}
												</div>
											)}
										</div>
									)}
									{file != null &&
										(file.transcript_type == 'gpu' ||
											(file.transcript_type === 'file' &&
												file.raw_transcript != null &&
												JSON.parse(file.raw_transcript)['status'] !==
													'transcribing')) && (
											<div
												className="relative rounded-8xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 text-white cursor-pointer z-20"
												onClick={() => {
													setDownloadDropdownOpen(!isDownloadDropdownOpen);
												}}
											>
												<div className="relative leading-[112.4%] font-medium">
													Download
												</div>
												<img
													className="w-[18px] relative h-[18px] overflow-hidden shrink-0"
													alt=""
													src="/arrow--arrow-down-2.svg"
												/>
												{isDownloadDropdownOpen && (
													<div className="absolute z-10 text-not-black top-12 w-fit flex flex-col gap-2 rounded-8xs bg-white shadow-lg p-2">
														{file != null &&
															file.transcript_type !== 'file' && (
																<div
																	className="rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
																	onClick={() => {
																		const element = document.createElement('a');
																		const file = new Blob([fileContent], {
																			type: 'text/plain',
																		});
																		element.href = URL.createObjectURL(file);
																		element.download = 'transcript.txt';
																		document.body.appendChild(element);
																		element.click();
																		document.body.removeChild(element);
																	}}
																>
																	<div className="relative leading-[112.4%] font-medium">
																		Download as TXT
																	</div>
																</div>
															)}
														{
															<div
																className="rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
																onClick={async () => {
																	try {
																		const response = await axios.get(
																			`${serverURL}/download_file?transcript_id=${file.id}`,
																			{
																				headers: {
																					'Content-Type': 'application/json',
																					Authorization: `Bearer ${jwtTokenRef.current}`,
																				},
																				responseType: 'blob', // Ensure the response is a blob (file)
																			}
																		);

																		var raw_transcript = JSON.parse(
																			file.raw_transcript
																		);
																		const name =
																			raw_transcript['original_file_name'];

																		if (response.status === 200) {
																			const blob = new Blob([response.data]);
																			const element =
																				document.createElement('a');
																			element.href = URL.createObjectURL(blob);
																			element.download = name; // Use a generic name for the downloaded file
																			document.body.appendChild(element);
																			element.click();
																			document.body.removeChild(element);
																		} else {
																			console.error('Failed to download file.');
																		}
																	} catch (error) {
																		console.error(
																			'Error occurred while downloading PDF file:',
																			error
																		);
																	}
																}}
															>
																<div className="relative leading-[112.4%] font-medium">
																	Download Translation
																</div>
															</div>
														}
														{file.transcript_type == 'gpu' && (
															<div
																className="rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
																onClick={async () => {
																	try {
																		var params = {
																			transcript_id: file.id,
																			language: outputLanguage,
																		};

																		if (outputLanguage === 'Original') {
																			params = { transcript_id: file.id };
																		}
																		const response = await axios.get(
																			`${serverURL}/get_srt_from_transcript`,
																			{
																				params: params,
																				responseType: 'blob', // Ensure the response is a blob (file)
																				headers: {
																					'Content-Type': 'application/json',
																					Authorization: `Bearer ${jwtTokenRef.current}`,
																				},
																			}
																		);

																		if (response.status === 200) {
																			const file = new Blob([response.data], {
																				type: 'application/x-subrip',
																			});
																			const element =
																				document.createElement('a');
																			element.href = URL.createObjectURL(file);
																			element.download = 'transcript.srt';
																			document.body.appendChild(element);
																			element.click();
																			document.body.removeChild(element);
																		} else {
																			console.error(
																				'Failed to download SRT file.'
																			);
																		}
																	} catch (error) {
																		console.error(
																			'Error occurred while downloading SRT file:',
																			error
																		);
																	}
																}}
															>
																<div className="relative leading-[112.4%] font-medium">
																	Download as SRT
																</div>
															</div>
														)}
													</div>
												)}
											</div>
										)}
								</div>
							</div>
						</div>
						<div className="p-10 flex flex-col box-border text-base font-montserrat gap-4 h-full">
							{file != null && file.transcript_type != 'file' && (
								<div className="w-fit relative h-[23.9px] flex gap-2 self-end">
									<div
										className={`h-full ${
											showSummary
												? '[background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]'
												: 'bg-white shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)]'
										} rounded-8xl flex flex-row items-start justify-start py-[1.3px] px-[13.3px] box-border cursor-pointer`}
										onClick={handleSummarize}
									>
										<img
											className="w-[17.5px] relative h-[21.2px]"
											alt=""
											src={showSummary ? '/ai-summary1.svg' : '/ai-summary.svg'}
										/>
									</div>
									<div className=" font-medium text-transparent !bg-clip-text [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] font-montserrat">
										AI Summary
									</div>
								</div>
							)}
							<div className="w-full relative leading-[25px] overflow-y-auto max-h-[85%]">
								{file?.transcript_type === 'gpu' && !showSummary ? (
									<div
										contentEditable
										suppressContentEditableWarning
										className="bg-transparent border-none outline-none flex-grow"
										onInput={(e) => {
											const newText = e.currentTarget.innerHTML.replace(
												/<br>/g,
												'\n'
											);
											debouncedUpdateTranscript(newText);
										}}
										dangerouslySetInnerHTML={{
											__html: convertNewLinesToHTML(fileContent),
										}}
									></div>
								) : (
									<ReactMarkdown>
										{showSummary ? summary : fileContent}
									</ReactMarkdown>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-2/6 self-start gap-10">
					<div className="flex items-center w-full rounded-full">
						<svg
							width="16"
							height="17"
							viewBox="0 0 16 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="-mr-6"
						>
							<path
								d="M2 3.83337H11.3333V11.8334C11.3333 12.5698 10.7364 13.1667 10 13.1667H3.33333C2.59695 13.1667 2 12.5698 2 11.8334V3.83337Z"
								stroke="#007EF3"
								stroke-width="1.67"
								stroke-linejoin="round"
							/>
							<path
								d="M11.3335 6.50004L13.1718 6.04046C13.5926 5.93527 14.0002 6.25351 14.0002 6.68722V10.3129C14.0002 10.7466 13.5926 11.0648 13.1718 10.9596L11.3335 10.5V6.50004Z"
								stroke="#007EF3"
								stroke-width="1.67"
								stroke-linejoin="round"
							/>
						</svg>
						<input
							placeholder="Paste meeting URL to get caption"
							className="px-4 pl-6 py-[5px] w-full border-solid rounded-full border-[.5px] focus:outline-none  bg-transparent"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									addBotToMeeting(e.target.value);
								}
							}}
						/>
					</div>
					<RecentsSidebar />
				</div>
			</div>
		</div>
	);
};

export default FileTranscript;
