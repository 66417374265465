const FrameComponent = () => {
	return (
		<>
			<div className='flex justify-center mb-4'>
				<div className='flex gap-[0.375rem]'>
					<div className='h-[0.313rem] w-[0.438rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
					<div className='h-[0.313rem] w-[0.438rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
					<div className='h-[0.313rem] w-[1.375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
					<div className='h-[0.313rem] w-[0.438rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
				</div>
			</div>
			<div className='text-center'>
				<div className='flex flex-col gap-1 mb-6'>
					<p className='font-semibold text-lg'>Inclusive Sharing Platform</p>
					<p className='text-[#7B7B7B]'>
						Create and join broadcast rooms with a simple code, ensuring every
						voice is understood.
					</p>
				</div>
			</div>
		</>
	);
};

export default FrameComponent;
