import { useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { serverURL } from "../../httpContext";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    const verificationCodeParam = params.get("verification_code");

    if (emailParam) setEmail(emailParam);
    if (verificationCodeParam) setVerificationCode(verificationCodeParam);
  }, [location]);

  const onGroupIconClick = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    setError(""); // Reset error message before submitting

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(`${serverURL}/change-password-forgot`, {
        email: email,
        new_password: password,
        email_verification_code: verificationCode,
      });

      if (response.data) {
        setSuccess(true);
      }
    } catch (error) {
      setError("Failed to reset password. Please try again.");
    }
  };

  const handleReturnToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="w-full relative bg-white h-[982px] overflow-hidden text-left text-13xl text-black font-montserrat">
      <div className="absolute top-[0px] left-[756px] bg-updated-color-grey1 w-[756px] h-[982px]" />
      <div className="absolute top-[443px] left-[977px] leading-[123%] font-semibold text-center">
        {/* <p className="m-0">placeholder</p>
        <p className="m-0">use scenario photo</p> */}
      </div>
      <div className="absolute top-[0px] left-[50px] w-[1362px] h-24 flex flex-row items-center justify-start pt-[55px] px-0 pb-[30px] box-border gap-[796px] text-5xl text-updated-color-blue">
        <div className="w-[183px] h-[41px] flex flex-row items-center justify-start">
          <div className="w-44 relative h-[41px]">
            <div className="absolute w-[73.86%] top-[14.63%] left-[26.14%] tracking-[-0.02em] font-medium inline-block">
              interpret
            </div>
            <img
              className="absolute h-full w-[23.3%] top-[0%] right-[76.7%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/1024px-x-1024px-round-w-text1.svg"
            />
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[50px] text-center text-xl text-not-black-black">
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px]">
            <div className="relative font-medium opacity-[0]">About</div>
          </div>
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px]">
            <div className="relative font-medium opacity-[0]">Pricing</div>
          </div>
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px]">
            <div className="relative font-medium opacity-[0]">Login</div>
          </div>
        </div>
      </div>
      <img
        className="absolute h-[2.75%] w-[1.79%] top-[22.2%] right-[92.59%] bottom-[75.05%] left-[5.62%] max-w-full overflow-hidden max-h-full cursor-pointer"
        alt=""
        src="/group-1707478204.svg"
        onClick={onGroupIconClick}
      />
      <div className="absolute top-[207px] left-[216px] w-[460px] flex flex-col items-start justify-start gap-[125px] text-21xl text-not-black-black">
        <div className="w-[434px] flex flex-col items-start justify-start gap-[20px]">
          <div className="self-stretch relative leading-[123%] font-semibold">
            {success ? "Password successfully changed!" : "Reset Password"}
          </div>
          <div className="self-stretch relative text-lg text-unselected-text">
            {success
              ? ""
              : "You’re a step away from accessing your account."}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[60px] text-center text-lg text-updated-color-grey2">
          {!success && (
            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              <div className="flex flex-row items-center justify-start gap-[10px]">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0"
                  alt=""
                  src="/general--lock1.svg"
                />
                <input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  className="w-[418px] py-2.5 px-0 rounded-md"
                  disabled={success}
                />
              </div>
              <div className="flex flex-row items-center justify-start gap-[10px]">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0"
                  alt=""
                  src="/general--lock1.svg"
                />
                <input
                  type="password"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  className="w-[418px] py-2.5 px-0 rounded-md"
                  disabled={success}
                />
              </div>
            </div>
          )}
          {error && (
            <div className="self-stretch flex flex-col items-start justify-start text-left text-red-600">
              <div className="relative">{error}</div>
            </div>
          )}
          <div
            className="self-stretch flex flex-col items-start justify-start cursor-pointer text-left text-9xl text-white"
            onClick={success ? handleReturnToLogin : handleSubmit}
          >
            <div className="flex flex-col items-start justify-start">
              <div className="w-[460px] rounded-3xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 box-border">
                <div className="relative leading-[123%] font-semibold">
                  {success ? "Return to Login" : "Reset"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
