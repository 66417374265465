import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProfileDropdown from '../../components/ProfileDropdown';
import { serverURL, userInfoRef, jwtTokenRef } from '../../httpContext';
import imageCompression from 'browser-image-compression';

const ProfileAccountInfo = () => {
	const navigate = useNavigate();
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [reenterPassword, setReenterPassword] = useState('');
	const [error, setError] = useState('');
	const [previewImage, setPreviewImage] = useState('');
	const [success, setSuccess] = useState('');

	const avatarRef = useRef(null);
	const handleUpdatePicture = async () => {
		if (avatarRef.current) {
			try {
				// Compress the selected image
				const compressedImage = await imageCompression(avatarRef.current, {
					maxSizeMB: 1, // Adjust the maximum size as needed
					maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
				});

				console.log('compressedImage', compressedImage);

				const formData = new FormData();
				formData.append('email', userInfoRef.current.email);
				formData.append(
					'profile_picture',
					compressedImage,
					avatarRef.current.name
				);

				const response = await axios.post(
					`${serverURL}/update-profile-picture`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${jwtTokenRef.current}`,
							'Content-Type': 'multipart/form-data',
						},
					}
				);

				// Update the user info with the new profile picture URL
				userInfoRef.current.profile_picture = response.data.profile_picture;
			} catch (error) {
				console.log('Error updating profile picture:', error);
				// setErrorMessage("Failed to update profile picture. Please try again.");
			}
		}
	};

	const handleChangePassword = async () => {
		if (newPassword !== reenterPassword) {
			setError('New passwords do not match.');
			return;
		}

		try {
			const response = await axios.post(`${serverURL}/change-password`, {
				email: userInfoRef.current?.email,
				old_password: currentPassword,
				new_password: newPassword,
			});

			if (response.status === 200) {
				setSuccess('Password changed successfully.');
				setError('');
			}
		} catch (err) {
			const errorMessage =
				err.response.data.detail ||
				'Error changing password. Please try again.';
			setError(errorMessage);
			setSuccess('');
		}
	};

	return (
		<div className="relative bg-white h-full text-left text-lg text-updated-color-blue font-montserrat flex flex-col">
			<ProfileDropdown />
			<div className="flex justify-center items-center py-10">
				<div className="rounded-xl flex flex-col items-center justify-start pt-[30px] pb-10 px-10 gap-[50px] text-base text-updated-color-new-black border-[0.5px] border-solid border-updated-color-grey2">
					<div
						className="relative cursor-pointer"
						onClick={() => {
							const input = document.createElement('input');
							input.type = 'file';
							input.accept = 'image/*';
							input.onchange = (e) => {
								const file = e.target.files[0];

								avatarRef.current = file;
								setPreviewImage(URL.createObjectURL(file));
								handleUpdatePicture();
							};
							input.click();
						}}
					>
						<img
							className="w-[83px] relative h-[83px] rounded-full"
							alt=""
							// src={userInfoRef?.current?.profile_picture || '/no-picture.svg'}
							src={
								previewImage ||
								userInfoRef.current?.profile_picture ||
								'/uploadphoto.svg'
							}
						/>
						<div className="absolute bottom-0 right-0 w-6 h-6 bg-updated-color-blue text-white flex items-center justify-center rounded-full">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								className="w-4 h-4"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M12 4v16m8-8H4"
								/>
							</svg>
						</div>
					</div>
					<div className="flex flex-col items-start justify-start gap-[50px]">
						{/* Account Section */}
						<div className="flex flex-col items-start justify-start pt-0 px-0 pb-5 gap-[20px] border-b-[0.5px] border-none border-updated-color-grey1">
							<div className="relative leading-[112.4%] font-semibold">
								Account
							</div>
							<div className="flex flex-row items-start justify-start gap-[20px]">
								<div className="flex flex-col items-start justify-start gap-[10px]">
									{/* Email Section */}
									<div className="relative leading-[112.4%] font-medium">
										Email Address
									</div>
									<div className="w-[292px] rounded-8xs box-border flex flex-row items-center justify-start py-2.5 pr-[180px] pl-2.5 border-[0.5px] border-solid border-updated-color-grey2">
										<div className="relative leading-[112.4%] font-light">
											{userInfoRef.current?.email}
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Password Section */}
						<div className="flex flex-col items-start justify-start pt-0 px-0 pb-5 gap-5 border-b border-none border-updated-color-grey1">
							<div className="flex flex-col items-start justify-start gap-5">
								<div className="relative leading-[112.4%] font-semibold">
									Password
								</div>
								<div className="flex flex-row items-start justify-start gap-5">
									<div className="flex flex-col items-start justify-start gap-2.5">
										<div className="relative leading-[112.4%] font-medium">
											Current Password
										</div>
										<input
											type="password"
											value={currentPassword}
											onChange={(e) => setCurrentPassword(e.target.value)}
											className="rounded-8xs flex flex-row items-center justify-start py-2.5 pr-20 pl-2.5 border border-solid border-updated-color-grey2"
										/>
									</div>
									<div className="flex flex-col items-start justify-start gap-2.5">
										<div className="relative leading-[112.4%] font-medium">
											New Password
										</div>
										<input
											type="password"
											value={newPassword}
											onChange={(e) => setNewPassword(e.target.value)}
											className="rounded-8xs flex flex-row items-center justify-start py-2.5 pr-20 pl-2.5 border border-solid border-updated-color-grey2"
										/>
									</div>
									<div className="flex flex-col items-start justify-start gap-2.5">
										<div className="relative leading-[112.4%] font-medium">
											Re-enter Password
										</div>
										<input
											type="password"
											value={reenterPassword}
											onChange={(e) => setReenterPassword(e.target.value)}
											className="rounded-8xs flex flex-row items-center justify-start py-2.5 pr-20 pl-2.5 border border-solid border-updated-color-grey2"
										/>
									</div>
								</div>
							</div>
							<div
								className="rounded-8xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 text-white cursor-pointer"
								onClick={handleChangePassword}
							>
								<div className="relative leading-[112.4%] font-medium">
									Change Password
								</div>
							</div>
							{error && <div className="text-red-500 mt-2">{error}</div>}
							{success && <div className="text-green-500 mt-2">{success}</div>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileAccountInfo;
