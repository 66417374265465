import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';

const RoomLinkAndQRCode = ({
	link,
	navigateUrl,
	onOutsideClickFunc,
	divRef,
	isWeb,
}) => {
	const navigate = useNavigate();
	return (
		<div
			ref={divRef}
			className="absolute flex flex-col justify-center items-center bg-gray-100 px-[35px] py-[45px] pb-[20px] rounded-xl"
		>
			<div className="font-semibold inline-block min-w-[125px] mb-[20px] text-center">
				Here is the link!
			</div>
			<div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px] text-center text-sm mb-[20px]">
				<input
					type="text"
					className="self-stretch flex-1 py-2.5 px-0 bg-transparent focus:outline-none border-0 border-b border-solid border-updated-color-grey2"
					placeholder="Enter the link"
					style={{ backgroundColor: 'transparent' }}
					value={link}
					readOnly
				/>
			</div>
			<img
				className="w-6 h-6 absolute !m-[0] top-[10px] right-[10px] overflow-hidden shrink-0 cursor-pointer"
				loading="lazy"
				alt=""
				src="/interface--close-circle-1.svg"
				onClick={onOutsideClickFunc}
			/>
			<div className="self-stretch flex flex-col items-center justify-center gap-[10px]">
				<div className="flex items-center justify-center">
					<QRCode value={link} size={150} />
				</div>
				{!isWeb && (
					<button
						className={`cursor-pointer [border:none] min-w-[250px] py-2.5 px-5 self-stretch rounded-3xs flex flex-row items-center justify-center [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))] `}
						onClick={() => {
							navigate(navigateUrl);
						}}
					>
						<div className="relative text-base font-medium font-montserrat text-white text-left inline-block min-w-[34px]">
							Enter
						</div>
					</button>
				)}
				<button
					className={`cursor-pointer [border:none] min-w-[250px] py-2.5 px-5 self-stretch rounded-3xs flex flex-row items-center justify-center [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))] `}
					onClick={() => {
						navigator.clipboard.writeText(link);
					}}
				>
					<div className="relative text-base font-medium font-montserrat text-white text-left inline-block min-w-[34px]">
						Copy the link
					</div>
				</button>
			</div>
		</div>
	);
};

export default RoomLinkAndQRCode;
