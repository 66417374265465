import { Link } from 'react-router-dom';

export const Onboarding0 = () => {
	return (
		<div className="h-full pb-24 pt-[90px] px-[0.625rem] gap-[0.8125rem] tracking-[normal] font-montserrat text-updated-color-new-black flex flex-col justify-center items-center">
			<img className="w-full h-[40%] object-cover" alt="" src="/onb0.png" />
			<div className="py-6 gap-[2rem] flex flex-col">
				<div className="flex justify-center mb-4">
					<div className="flex gap-[0.375rem]">
						<div className="h-[0.3125rem] w-[1.375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
						<div className="h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
						<div className="h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
						<div className="h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
					</div>
				</div>
				<div className="text-center">
					<div className="flex flex-col gap-5 pb-[1.5rem] mb-8">
						<div className="text-lg font-semibold">First 1 Hour Free</div>
						<div className="text-[#7B7B7B]">
							Experience our transcription, translation, and documentation
							services with 60 minutes of complimentary usage.
						</div>
					</div>
					<div className="flex justify-between items-center pl-[1.25rem] pr-[0.6875rem]">
						<Link
							to="/signup"
							onClick={() => {
								localStorage.setItem('isOnboarded', 'true');
							}}
							className="leading-[1.5rem] text-[1rem] text-updated-color-new-black font-medium min-w-[2.25rem] tracking-[0.1px]"
						>
							Skip
						</Link>
						<Link to="/onboarding-1">
							<img
								className="h-[4.938rem] w-[4.938rem] relative overflow-hidden shrink-0 object-contain cursor-pointer"
								loading="lazy"
								alt=""
								src="/mobilecont.svg"
							/>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
