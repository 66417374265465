import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	serverURL,
	jwtTokenRef,
	userInfoRef,
	updateAccountInfo,
} from '../../httpContext';

const VerificationEmailSent = ({ setIsLoggedIn }) => {
	const navigate = useNavigate();
	const [error, setError] = useState('');

	const handleResend = async () => {
		console.log('Resend verification');
		console.log(jwtTokenRef.current);
		console.log(serverURL);
		try {
			const response = await fetch(`${serverURL}/request-email-verification`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + jwtTokenRef.current,
				},
				body: JSON.stringify({
					// Add any required data for the verification here
				}),
			});
			if (response.status === 200) {
				setError('');
			} else if (response.status < 500) {
				const response_json = await response.json();
				if (typeof response_json.detail === 'string') {
					setError(response_json.detail);
				} else {
					setError('Invalid verification code');
				}
			} else {
				setError('Internal server error');
			}
			// Handle the response data here
		} catch (error) {
			// Handle any errors here
			setError('Internal server error');
		}
	};

	useEffect(() => {
		if (jwtTokenRef == undefined || jwtTokenRef.current == undefined) {
			navigate('/login');
		}

		if (userInfoRef != undefined && userInfoRef.current != undefined) {
			if (userInfoRef.current.verified_email) {
				setIsLoggedIn(true);
				navigate('/');
			}
		}

		if (userInfoRef.current == undefined) {
			updateAccountInfo(jwtTokenRef, userInfoRef);
		}

		var interval = setInterval(async () => {
			if (jwtTokenRef == undefined || jwtTokenRef.current == undefined) {
				clearInterval(interval);
				navigate('/login');
			}

			console.log('Checking for verified email');
			if (userInfoRef.current == undefined) {
				await updateAccountInfo(jwtTokenRef, userInfoRef);
			}
			console.log('user info: ' + userInfoRef.current.email);
			await updateAccountInfo(jwtTokenRef, userInfoRef);
			if (userInfoRef.current != undefined) {
				if (userInfoRef.current.verified_email) {
					console.log('Verified email');
					clearInterval(interval);
					setIsLoggedIn(true);
					navigate('/');
				}
			}
		}, 3000);
	}, []);

	return (
		<div className='w-full h-full flex flex-col pt-16 px-[1.25rem] pb-[24.25rem] gap-[17.313rem] tracking-[normal] text-center text-[1rem] text-updated-color-new-black'>
			<img
				className='w-[2.5rem] h-[2.5rem] overflow-hidden shrink-0'
				loading='lazy'
				alt=''
				src='/mobileback.svg'
				onClick={() => {
					jwtTokenRef.current = undefined;
					userInfoRef.current = undefined;
					localStorage.removeItem('jwtToken');
					navigate('/login');
				}}
			/>
			<div className='self-stretch flex py-[0rem] pr-[3.25rem] pl-[3.313rem]'>
				<div className='flex-1 flex flex-col gap-[1.25rem]'>
					<div className='self-stretch font-medium'>
						Verification code has been sent to {userInfoRef.current.email}
					</div>
					<div className='self-stretch text-[0.875rem] text-darkgray'>
						<span>{`Didn’t receive email? `}</span>
						<span
							onClick={handleResend}
							className='[text-decoration:underline] text-dodgerblue-100'
						>
							Resend
						</span>
					</div>
					<p className='text-red-500 text-lg text-center w-full'>{error}</p>
				</div>
			</div>
		</div>
	);
};

export default VerificationEmailSent;
