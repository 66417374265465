import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

export const AboutPopup = ({ close }) => {
	const [isVisible, setIsVisible] = useState(true);

	const divRef = useRef(null);

	const handleOutsideClick = (event) => {
		if (divRef.current && !divRef.current.contains(event.target)) {
			setIsVisible(false);
			setTimeout(() => {
				close();
			}, 250);
		}
	};

	return (
		<div
			className="fixed inset-0 flex items-end justify-center font-montserrat"
			onClick={handleOutsideClick}
		>
			<div
				ref={divRef}
				className={`h-fit font-medium text-dodgerblue-200 rounded-t-xl w-full flex flex-col gap-6 pb-10 justify-center items-center transition-transform ${isVisible ? 'animate-slideUp' : 'animate-slideDown'} p-4 [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff]`}
			>
				<div
					className="self-end"
					onClick={() => {
						setIsVisible(false);
						setTimeout(() => {
							close();
						}, 250);
					}}
				>
					Done
				</div>
				<Link to="/tos" className="text-dodgerblue-200 underline">
					Terms of Service
				</Link>
				<Link to="/privacy" className="text-dodgerblue-200 underline">
					Privacy Policy
				</Link>
			</div>
		</div>
	);
};
