import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProfileDropdown from '../../components/ProfileDropdown';
import { serverURL, userInfoRef, jwtTokenRef } from '../../httpContext';

const ProfileCurrentPlan = () => {
	const navigate = useNavigate();
	const [plan, setPlan] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [credits, setCredits] = useState(0);

	const handleCancelOrBuyPlan = async () => {
		if (disabled) return;

		try {
			if (plan === 'Free Trial') {
				// Handle Buy Plan action
				navigate('/buy_plan');
			} else {
				const response = await axios.post(
					`${serverURL}/unsubscribe`,
					{
						email: userInfoRef.current.email,
					},
					{
						headers: {
							Authorization: `Bearer ${jwtTokenRef.current}`,
						},
					}
				);

				// Handle success response
				console.log('Plan cancelled successfully:', response.data);
				// Navigate or update UI as needed
			}
		} catch (error) {
			// Handle error response
			console.error('Error cancelling plan:', error);
		}
	};

	useEffect(() => {
		const loadUser = async () => {
			while (
				userInfoRef.current === undefined ||
				userInfoRef.current === null ||
				userInfoRef.current.email === null
			) {
				await new Promise((resolve) => setTimeout(resolve, 100));
			}
			console.log('userInfoRef.current: ', userInfoRef.current);
			var plan = userInfoRef.current?.plan;
			var credits = userInfoRef.current?.credits;
			credits = parseInt(credits / 10);
			if (plan === null || plan.length === 0) {
				plan = 'Free Trial';
				setDisabled(false);
			} else {
				setDisabled(false);
			}

			if (plan === 'pro') {
				plan = 'Pro Plan';
			}

			if (plan === 'lite') {
				plan = 'Lite Plan';
			}

			setPlan(plan);
			setCredits(credits);
		};
		loadUser();
	}, []);

	return (
		<div className="w-full relative bg-white h-[982px] overflow-y-auto text-left text-base text-updated-color-new-black font-montserrat">
			<ProfileDropdown />
			<div className="absolute top-[247px] left-[calc(50%_-_506px)] rounded-xl bg-white overflow-hidden flex flex-col items-center justify-start pt-[30px] px-[60px] pb-10 border-[0.5px] border-solid border-updated-color-grey2">
				<div className="overflow-y-auto flex flex-col items-start justify-start">
					<div className="flex flex-col items-start justify-start pt-0 px-0 pb-5 gap-[20px] border-b-[0.5px] border-updated-color-grey1">
						<div className="relative leading-[112.4%] font-semibold">
							Current Plan
						</div>
						<div className="flex flex-row items-center justify-start gap-[591px] text-5xl">
							<div className="rounded-8xs flex flex-col items-start justify-center gap-[10px]">
								<div className="relative leading-[112.4%] font-semibold">
									{plan}
								</div>
								{plan === 'Free Trial' && (
									<div className="relative text-xl leading-[112.4%] font-semibold">
										{credits} minutes remaining
									</div>
								)}
								{plan === 'Lite Plan' && (
									<div className="relative text-xl leading-[112.4%] font-semibold">
										$8.99 per month - Use up to 10 hours of our services
									</div>
								)}
								{plan === 'Pro Plan' && (
									<div className="relative text-xl leading-[112.4%] font-semibold">
										$24.99 per month - Unlimited time use of our services
									</div>
								)}
							</div>
							<div
								className={`rounded-8xs flex flex-row items-center justify-center p-2.5 text-base border-[1px] border-solid ${
									plan === 'Free Trial'
										? 'border-updated-color-blue cursor-pointer'
										: disabled
											? 'border-gray-400 text-gray-400 cursor-not-allowed'
											: 'border-updated-color-blue cursor-pointer'
								}`}
								onClick={handleCancelOrBuyPlan}
							>
								<div className="relative leading-[112.4%] font-medium">
									{plan === 'Free Trial' ? 'Buy Plan' : 'Cancel Plan'}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileCurrentPlan;
