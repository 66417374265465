import {
	EditOutlined,
	CopyOutlined,
	FolderOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';

export const EditFileFolder = ({
	isFile,
	file,
	folder,
	handleFileRemove,
	handleFolderRemove,
	setIsRenamingFile,
	setIsRenamingFolder,
	setOpenPopup,
	setOpenMoveFilePopup,
}) => {
	const renameHandler = (e) => {
		if (isFile) {
			setIsRenamingFile(true);
		} else {
			setIsRenamingFolder(true);
		}
		setOpenPopup(false);
		e.stopPropagation();
		console.log('Renaming');
	};

	const removeHandler = (e) => {
		e.stopPropagation();

		if (isFile) {
			handleFileRemove(file);
		} else {
			handleFolderRemove(folder);
		}
		setOpenPopup(false);
	};

	const moveHandler = (e) => {
		e.stopPropagation();
		setOpenPopup(false);
		setOpenMoveFilePopup(true);
	};

	const copyHandler = (e) => {
		e.stopPropagation();

		const copyAsync = async () => {
			const retrievedFile = await axios.get(
				`${serverURL}/get_transcript?transcript_id=${file.id}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
				}
			);

			if (isFile) {
				console.log('Copying file', retrievedFile.data.transcript);
				if (retrievedFile.data.transcript) {
					navigator.clipboard.writeText(retrievedFile.data?.transcript);
				}
			} else {
				// handleFolderCopy(folder)
			}
		};
		setOpenPopup(false);
		copyAsync();
	};

	return (
		<div className="shadow rounded-3xs w-56 bg-updated-color-grey1 overflow-hidden flex flex-col gap-[5px] text-left text-lg text-updated-color-new-black font-montserrat">
			<div className="self-stretch flex flex-col">
				<div
					className="self-stretch flex bg-white items-center border-x-0 border-t-0 border-b-[.5px] border-updated-color-grey2 border-solid justify-between py-[9px] px-[25px] gap-[20px]"
					onClick={renameHandler}
				>
					<div className="w-[78px]">Rename</div>
					<EditOutlined className="h-6 w-6 overflow-hidden shrink-0 text-xl" />
				</div>
				{/* <div className="self-stretch bg-white flex flex-row items-center justify-between py-[9px] px-[25px] gap-[20px] border-b-[0.5px] border-solid border-updated-color-grey2">
          <div className="relative font-medium inline-block min-w-[89px]">
            Duplicate
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            alt=""
            src="/files--file-add.svg"
          />
        </div> */}
				{isFile && (
					<div
						className="self-stretch bg-white flex items-center border-x-0 border-t-0 border-b-[.5px] border-updated-color-grey2 border-solid justify-between py-[9px] px-[25px] gap-[20px]"
						onClick={copyHandler}
					>
						<div className="relative inline-block min-w-[47px]">Copy</div>
						<CopyOutlined className="h-6 w-6 overflow-hidden shrink-0 text-xl" />
					</div>
				)}
				{/* <div className="self-stretch bg-white flex flex-row items-center justify-between py-[9px] px-[25px] gap-[20px] border-b-[0.5px] border-solid border-updated-color-grey2">
          <div className="relative font-medium inline-block min-w-[53px]">
            Share
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            alt=""
            src="/communication--share-2.svg"
          />
        </div>
        <div className="self-stretch bg-white flex flex-row items-center justify-between py-[9px] px-[25px] gap-[20px] border-b-[0.5px] border-solid border-updated-color-grey2">
          <div className="relative font-medium inline-block min-w-[48px]">
            Color
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            alt=""
            src="/upalette.svg"
          />
        </div> */}
				{isFile && (
					<div
						className="self-stretch bg-white flex items-center justify-between py-2.5 px-[25px] gap-[20px]"
						onClick={moveHandler}
					>
						<div className="relative inline-block min-w-[50px]">Move</div>
						<FolderOutlined className="h-6 w-6 overflow-hidden shrink-0 text-xl" />
					</div>
				)}
			</div>
			<div
				className="bg-white flex items-center justify-between py-2.5 px-[25px] gap-[20px]"
				onClick={(e) => {
					removeHandler(e);
				}}
			>
				<div className="w-[61px] font-montserrat text-lg text-updated-color-red text-left">
					Delete
				</div>
				<DeleteOutlined className="h-6 w-6 overflow-hidden shrink-0 text-xl text-updated-color-red" />
			</div>
		</div>
	);
};
