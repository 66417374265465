import FrameComponent from '../../components/mobile/FrameComponent';
import { Link } from 'react-router-dom';

export const Onboarding2 = () => {
	return (
		<div className="h-full pb-24 pt-[90px] px-[0.625rem] gap-[0.8125rem] tracking-[normal] font-montserrat text-updated-color-new-black flex flex-col justify-center items-center">
			<img className="w-full h-[40%] object-cover" alt="" src="/globe.svg" />
			<div className="py-6 gap-[2rem] flex flex-col">
				<FrameComponent />
				<div className="flex justify-between items-center pl-[1.25rem] pr-[0.6875rem]">
					<Link
						to="/signup"
						onClick={() => {
							localStorage.setItem('isOnboarded', 'true');
						}}
						className="leading-[1.5rem] text-[1rem] text-updated-color-new-black font-medium min-w-[2.25rem] tracking-[0.1px]"
					>
						Skip
					</Link>
					<Link to="/onboarding-3">
						<img
							className="h-[4.938rem] w-[4.938rem] relative overflow-hidden shrink-0 object-contain cursor-pointer"
							loading="lazy"
							alt=""
							src="/mobilecont.svg"
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};
