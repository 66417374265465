import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	jwtTokenRef,
	serverURL,
	updateAccountInfo,
	userInfoRef,
} from '../../httpContext';
import Subscription from './Subscription';
import PortalPopup from './PortalPopup';
import axios from 'axios';
import {
	EyeInvisibleOutlined,
	EyeOutlined,
	PictureOutlined,
	CloseCircleFilled,
	CameraOutlined,
	RightOutlined,
} from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import { signOut, getAuth } from 'firebase/auth';
import { CancelPlan } from './CancelPlan';
import { AboutPopup } from './AboutPopup';

function formatMinutesToHHMM(totalMinutes) {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	const paddedHours = String(hours).padStart(2, '0');
	const paddedMinutes = String(minutes).padStart(2, '0');

	return `${paddedHours}:${paddedMinutes}`;
}

const ChangePicturePopup = ({ isChangePictureOpenRef, closeFunc }) => {
	const [previewImage, setPreviewImage] = useState(
		userInfoRef.current.profile_picture || '/no-picture.svg'
	);
	const [errorMessage, setErrorMessage] = useState('');

	const changePicturePopupRef = useRef(null);
	const selectedImageRef = useRef(null);

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			selectedImageRef.current = file;
			setPreviewImage(URL.createObjectURL(file));
		}
	};

	const handleUpdatePicture = async () => {
		if (selectedImageRef.current) {
			try {
				// Compress the selected image
				const compressedImage = await imageCompression(
					selectedImageRef.current,
					{
						maxSizeMB: 1, // Adjust the maximum size as needed
						maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
					}
				);

				const formData = new FormData();
				formData.append('email', userInfoRef.current.email);
				formData.append(
					'profile_picture',
					compressedImage,
					selectedImageRef.current.name
				);

				const response = await axios.post(
					`${serverURL}/update-profile-picture`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${jwtTokenRef.current}`,
							'Content-Type': 'multipart/form-data',
						},
					}
				);

				// Update the user info with the new profile picture URL
				userInfoRef.current.profile_picture = response.data.profile_picture;
				closeFunc();
			} catch (error) {
				console.log('Error updating profile picture:', error);
				setErrorMessage('Failed to update profile picture. Please try again.');
			}
		}
	};

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				!changePicturePopupRef?.current?.contains(event.target) &&
				isChangePictureOpenRef.current
			) {
				console.log('clicked outside');
				closeFunc();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [closeFunc]);

	return (
		<div
			ref={changePicturePopupRef}
			className="absolute w-full bottom-0 left-0 rounded-t-xl rounded-b-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start pt-5 pb-[50px]"
		>
			<div className="w-11/12 flex justify-between mb-4">
				<div className="font-medium" onClick={closeFunc}>
					Cancel
				</div>
				<button
					type="button"
					disabled={!selectedImageRef.current}
					className={`font-medium ${selectedImageRef.current ? '' : 'text-updated-color-grey2 font-medium text-base'}`}
					onClick={handleUpdatePicture}
				>
					Done
				</button>
			</div>
			<div className="flex flex-col items-center gap-[25px] text-base">
				<div className="relative w-fit">
					<img
						className="w-[100px] h-[100px] rounded-full object-cover"
						alt="Profile"
						src={previewImage}
					/>
					<CloseCircleFilled
						className="absolute top-1 right-0 text-xl text-updated-color-grey1 bg-white rounded-full"
						onClick={() => {
							setPreviewImage('/no-picture.svg');
							selectedImageRef.current = null;
						}}
					/>
				</div>
				<div className="flex items-center gap-12 justify-center">
					<input
						type="file"
						accept="image/*"
						onChange={handleImageChange}
						className="hidden"
						id="profilePictureInput"
					/>
					<label
						htmlFor="profilePictureInput"
						className="p-4 rounded-full flex items-center justify-center bg-white"
					>
						<PictureOutlined className="text-4xl text-updated-color-new-black" />
					</label>
					<input
						type="file"
						accept="image/*"
						onChange={handleImageChange}
						className="hidden"
						id="cameraPictureInput"
						capture="environment"
					/>
					<label
						htmlFor="cameraPictureInput"
						className="p-4 rounded-full flex items-center justify-center bg-white"
					>
						<CameraOutlined className="text-4xl text-updated-color-new-black" />
					</label>
				</div>
				{errorMessage && (
					<div className="mt-4 text-red-500 text-center">{errorMessage}</div>
				)}
			</div>
		</div>
	);
};

const ChangePasswordPopup = ({ isChangePasswordOpenRef, closeFunc }) => {
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const changePasswordPopupRef = useRef(null);

	const isFormValid = () => {
		if (
			(userInfoRef.current?.has_password && oldPassword.trim() === '') ||
			newPassword.trim() === '' ||
			confirmPassword.trim() === ''
		) {
			return false;
		}
		if (newPassword !== confirmPassword) {
			return false;
		}
		if (newPassword === oldPassword) {
			return false;
		}
		return true;
	};

	const handleChangePassword = async () => {
		if (isFormValid()) {
			try {
				const response = await axios.post(
					`${serverURL}/change-password`,
					{
						email: userInfoRef.current.email,
						old_password: oldPassword,
						new_password: newPassword,
						jwt_token: jwtTokenRef.current,
					},
					{
						headers: {
							Authorization: `Bearer ${jwtTokenRef.current}`,
						},
					}
				);
				await updateAccountInfo(jwtTokenRef, userInfoRef);
				setSuccessMessage(response.data.message);
				setErrorMessage('');
				// Clear form fields after successful password change
				setOldPassword('');
				setNewPassword('');
				setConfirmPassword('');
			} catch (error) {
				if (error.response) {
					setErrorMessage(error.response.data.detail);
				} else {
					console.log('error: ', error);
					setErrorMessage('An error occurred. Please try again.');
				}
				setSuccessMessage('');
			}
		} else {
			setErrorMessage('Please check your input and try again.');
			setSuccessMessage('');
		}
	};

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				changePasswordPopupRef.current &&
				!changePasswordPopupRef.current.contains(event.target) &&
				isChangePasswordOpenRef.current
			) {
				closeFunc();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [closeFunc]);

	return (
		<div className="h-full flex flex-col justify-end">
			<div
				ref={changePasswordPopupRef}
				className="rounded-t-xl rounded-b-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start pt-5 px-5 pb-[50px] max-h-[80vh] overflow-y-auto"
			>
				<div className="w-full flex justify-between mb-4">
					<div
						className="relative font-medium cursor-pointer"
						onClick={() => {
							closeFunc();
						}}
					>
						Back
					</div>
				</div>
				<div className="flex flex-col items-center justify-start gap-[20px] text-5xl text-updated-color-new-black w-full">
					<div className="relative font-semibold">Change password</div>
					<div className="flex flex-col items-center justify-start gap-[25px] text-base w-full">
						{userInfoRef.current?.has_password && (
							<div className="relative w-full">
								<input
									type={oldPasswordVisible ? 'text' : 'password'}
									className="w-full border-none bg-transparent border-b border-updated-color-red"
									placeholder="Old Password"
									value={oldPassword}
									onChange={(e) => setOldPassword(e.target.value)}
								/>
								<button
									onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
									className="absolute top-1/2 right-0 transform -translate-y-1/2 text-xl"
								>
									{oldPasswordVisible ? (
										<EyeOutlined />
									) : (
										<EyeInvisibleOutlined />
									)}
								</button>
							</div>
						)}
						<div className="relative w-full">
							<input
								type={newPasswordVisible ? 'text' : 'password'}
								className="w-full border-none bg-transparent border-b border-updated-color-red"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
							<button
								onClick={() => setNewPasswordVisible(!newPasswordVisible)}
								className="absolute top-1/2 right-0 transform -translate-y-1/2 text-xl"
							>
								{newPasswordVisible ? (
									<EyeOutlined />
								) : (
									<EyeInvisibleOutlined />
								)}
							</button>
						</div>
						<div className="relative w-full">
							<input
								type={confirmPasswordVisible ? 'text' : 'password'}
								className="w-full border-none bg-transparent border-b border-updated-color-red"
								placeholder="Confirm Password"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							<button
								onClick={() =>
									setConfirmPasswordVisible(!confirmPasswordVisible)
								}
								className="absolute top-1/2 right-0 transform -translate-y-1/2 text-xl"
							>
								{confirmPasswordVisible ? (
									<EyeOutlined />
								) : (
									<EyeInvisibleOutlined />
								)}
							</button>
						</div>
						{errorMessage && (
							<div className="text-red-500 text-sm mt-2">{errorMessage}</div>
						)}
						{successMessage && (
							<div className="text-green-500 text-sm mt-2">
								{successMessage}
							</div>
						)}
						<button
							onClick={() => {
								if (isFormValid()) {
									handleChangePassword();
								} else {
									setErrorMessage('Please check your input and try again.');
								}
							}}
							className={`mt-4 cursor-pointer [border:none] py-2.5 px-[75.5px] rounded-[10px] ${
								isFormValid()
									? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]'
									: 'bg-updated-color-grey2'
							} flex items-center justify-center whitespace-nowrap`}
						>
							<div className="relative text-base font-medium font-montserrat text-white text-center inline-block min-w-[119px]">
								Update Password
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const Profile = ({ email, closeFunc, setIsLoggedIn }) => {
	const navigate = useNavigate();

	const [isSubscriptionsOpen, setIsSubscriptionsOpen] = useState(false);
	const isSubscriptionsOpenRef = useRef(isSubscriptionsOpen);

	const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
	const isChangePasswordOpenRef = useRef(isChangePasswordOpen);

	const [isChangePictureOpen, setIsChangePictureOpen] = useState(false);
	const isChangePictureOpenRef = useRef(isChangePictureOpen);

	const [creditBalance, setCreditBalance] = useState('00:00');
	const [plan, setPlan] = useState(undefined);
	const [isVisible, setIsVisible] = useState(true);
	const [showPopup, setShowPopup] = useState(false);
	const [isAboutOpen, setIsAboutOpen] = useState(false);

	const auth = getAuth();

	useEffect(() => {
		isSubscriptionsOpenRef.current = isSubscriptionsOpen;
		isChangePasswordOpenRef.current = isChangePasswordOpen;
		isChangePictureOpenRef.current = isChangePictureOpen;
	}, [isSubscriptionsOpen, isChangePasswordOpen, isChangePictureOpen]);

	const handleSignOut = async () => {
		await signOut(auth);
		localStorage.removeItem('jwtToken');
		if (jwtTokenRef.current != undefined) {
			jwtTokenRef.current = undefined;
			userInfoRef.current = undefined;
		}
		setIsLoggedIn(false);
		navigate('/login');
	};

	const profileRef = useRef(null);

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				profileRef.current &&
				!profileRef.current.contains(event.target) &&
				!isSubscriptionsOpenRef.current &&
				!isAboutOpen &&
				!showPopup
			) {
				setIsVisible(false);
				setTimeout(() => {
					closeFunc();
				}, 250);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [closeFunc, isSubscriptionsOpenRef, isAboutOpen, showPopup, profileRef]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (userInfoRef.current == undefined) {
				return;
			}
			let credits = userInfoRef.current.credits;
			let plan = userInfoRef?.current?.plan;
			if (credits != undefined) {
				setCreditBalance(formatMinutesToHHMM(Math.floor(credits)));
			}

			if (plan != undefined) {
				setPlan(plan);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const closePopup = () => {
		setShowPopup(false);
	};

	return (
		<div
			className={`relative h-full cursor-pointer max-h-full text-left text-base text-dodgerblue-100 font-montserrat overflow-hidden`}
		>
			{isChangePictureOpen && (
				<ChangePicturePopup
					isChangePictureOpenRef={isChangePictureOpenRef}
					closeFunc={() => {
						setIsChangePictureOpen(false);
					}}
				></ChangePicturePopup>
			)}
			{isChangePasswordOpen && !isChangePictureOpen && (
				<ChangePasswordPopup
					isChangePasswordOpenRef={isChangePasswordOpenRef}
					closeFunc={() => {
						setIsChangePasswordOpen(false);
					}}
				></ChangePasswordPopup>
			)}
			{!isChangePasswordOpen && !isChangePictureOpen && (
				<div
					className={`h-full flex flex-col justify-end transition-transform ${isVisible ? 'animate-slideUp' : 'animate-slideDown'}`}
				>
					<div
						ref={profileRef}
						className="rounded-t-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start pt-5 px-5 pb-[50px] max-h-[80vh] overflow-y-auto"
					>
						<div className="w-full flex justify-end mb-4">
							<div
								className="relative font-medium cursor-pointer"
								onClick={() => {
									setIsVisible(false);
									setTimeout(() => {
										closeFunc();
									}, 250);
								}}
							>
								Done
							</div>
						</div>
						<div className="flex flex-col items-center justify-start gap-[20px] text-5xl text-updated-color-new-black w-full">
							<div className="relative font-semibold">Profile</div>
							<div className="flex flex-col items-center justify-center gap-[25px] text-base w-full">
								<div className="w-full rounded-3xs bg-white flex flex-row items-center justify-start py-[15px] px-5 box-border gap-[20px] text-lg text-dodgerblue-200">
									<img
										className="w-[63px] relative h-[63px] object-cover rounded-full"
										alt=""
										src={
											userInfoRef?.current?.profile_picture || '/no-picture.svg'
										}
										onClick={() => {
											setIsChangePictureOpen(true);
										}}
									/>
									<div className="flex flex-col justify-center gap-3 pt-2">
										<div className="flex gap-2 font-medium text-base flex-wrap">
											<div>{email}</div>
											{plan && (
												<span className="text-white flex items-center justify-center p-1 px-2 text-xs h-fit bg-updated-color-blue rounded-lg">
													Pro
												</span>
											)}
										</div>
										<div className="flex gap-2 text-updated-color-new-black text-xs font-medium items-center justify-center">
											<div className="w-full">
												{creditBalance}{' '}
												<span className="text-[10px] text-updated-color-grey2">
													time credits
												</span>
											</div>
											{plan && (
												<div
													onClick={() => setShowPopup(!showPopup)}
													className="text-dodgerblue-200 text-xs underline w-full"
												>
													cancel subscription
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="rounded-3xs bg-white flex flex-col items-center justify-start py-0 px-4">
									<div className="w-full flex flex-col items-center justify-start">
										<div
											className="w-full flex flex-row items-center justify-between py-2.5 px-0"
											onClick={() => {
												setIsSubscriptionsOpen(true);
												isSubscriptionsOpenRef.current = true;
											}}
										>
											<div className="relative font-medium">Subscribe</div>
											<RightOutlined />
										</div>
										<img
											className="w-full relative max-h-full"
											alt=""
											src="/vector-274.svg"
										/>
									</div>
									<div className="w-full flex flex-col items-center justify-start">
										<div
											className="w-full flex flex-row items-center justify-between py-2.5 px-0"
											onClick={() => {
												setIsChangePasswordOpen(true);
											}}
										>
											<div className="relative font-medium">
												Change Password
											</div>
											<RightOutlined />
										</div>
										<img className="w-full relative h-0 opacity-[0]" alt="" />
									</div>
								</div>
								<div className=" rounded-3xs bg-white flex flex-col items-center justify-start py-0 px-4">
									{/* <div className="w-full flex flex-col items-center justify-start" onClick={() => {}}>
                <div className="w-full flex flex-row items-center justify-between py-2.5 px-0">
                  <div className="relative font-medium">Get Help</div>
                  <img
                    className="w-5 relative h-5 object-contain"
                    alt=""
                    src="/icon-frame1.svg"
                  />
                </div>
                <img
                  className="w-full relative max-h-full"
                  alt=""
                  src="/vector-274.svg"
                />
              </div> */}
									<div className="flex flex-col items-center justify-start">
										<div
											className="w-full flex flex-row items-center justify-between py-2.5 px-0"
											onClick={() => {
												setIsAboutOpen(true);
											}}
										>
											<div className="relative font-medium">About</div>
											<RightOutlined />
										</div>
										<img
											className="w-full relative max-h-full"
											alt=""
											src="/vector-274.svg"
										/>
									</div>
									<div className="w-full flex flex-col items-center justify-start cursor-pointer text-updated-color-red">
										<div
											className="w-full flex flex-row items-center justify-between py-2.5 px-0"
											onClick={handleSignOut}
										>
											<div className="relative font-medium">Logout</div>
										</div>
										<img className="w-full relative h-0" alt="" />
									</div>
								</div>
								{/* <div className="w-full rounded-3xs bg-white flex flex-col items-center justify-start py-0 px-4 box-border">
              <div className="w-full flex flex-col items-center justify-start">
                <div className="w-full flex flex-row items-center justify-between py-2.5 px-0">
                  <div className="relative font-medium">Delete Account</div>
                  <img
                    className="w-5 relative h-5 object-contain"
                    alt=""
                    src="/icon-frame1.svg"
                  />
                </div>
                <img className="w-full relative h-0" alt="" />
              </div>
            </div> */}
							</div>
						</div>
					</div>
				</div>
			)}
			{isSubscriptionsOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					zIndex={101}
				>
					<Subscription
						closeFunc={() => {
							setIsSubscriptionsOpen(false);
							isSubscriptionsOpenRef.current = false;
						}}
						email={email}
					/>
				</PortalPopup>
			)}
			{showPopup && (
				<PortalPopup overlayColor="rgba(113, 113, 113, 0.3)" zIndex={101}>
					<CancelPlan close={closePopup} />
				</PortalPopup>
			)}
			{isAboutOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					zIndex={101}
				>
					<AboutPopup close={() => setIsAboutOpen(false)} />
				</PortalPopup>
			)}
		</div>
	);
};

export default Profile;
