import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	jwtTokenRef,
	userInfoRef,
	serverURL,
	updateAccountInfo,
} from '../../httpContext';
import {
	LockOutlined,
	EyeOutlined,
	EyeInvisibleOutlined,
} from '@ant-design/icons';

export const FrameComponent2 = ({ error, setError }) => {
	const [password1, setPassword1] = useState('');
	const [email, setEmail] = useState('');
	const [password2, setPassword2] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const onSignUpClick = useCallback(
		async (e) => {
			e.preventDefault();
			setError('');
			setSubmitted(true);
			if (email === '' || password1 === '' || password2 === '') {
				setError('Please fill in all fields');
				setSubmitted(false);
				return;
			}

			if (password1 !== password2) {
				setError('Passwords do not match');
				setSubmitted(false);
				return;
			}
			const response = await fetch(`${serverURL}/signup`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
					password: password1,
					// Add any required data for the verification here
				}),
			});

			const response_json = await response.json();

			if (response.status === 201) {
				jwtTokenRef.current = response_json.access_token;
				await updateAccountInfo(jwtTokenRef, userInfoRef);
				try {
					localStorage.setItem('jwtToken', jwtTokenRef.current);
				} catch (e) {
					setError('Internal server error');
					return;
				}

				console.log(response_json);
				setError('');
				navigate('/email-verification');
			} else if (response.status < 500) {
				if (typeof response_json.detail === 'string') {
					setError(response_json.detail);
				} else {
					setError('Invalid email or password');
				}
			} else {
				setError('Internal server error');
			}
			setSubmitted(false);
		},
		[navigate, password1, password2]
	);

	useEffect(() => {
		if (jwtTokenRef.current != undefined && userInfoRef.current != undefined) {
			if (userInfoRef.current.verified_email) {
				navigate('/');
			} else {
				navigate('/email-verification');
			}
		}
	}, []);

	return (
		<form className='w-full flex flex-col items-center gap-10 sm:gap-12'>
			<div className='w-full flex flex-col items-center gap-[7rem]'>
				<div className='self-start text-lg sm:text-xl font-semibold font-montserrat text-updated-color-new-black text-center'>
					Sign Up
				</div>
				<div className='w-full flex flex-col gap-4 sm:gap-5'>
					<div className='w-full flex flex-row flex-wrap items-center justify-center gap-2 sm:gap-3'>
						<img
							className='h-5 w-5 relative overflow-hidden shrink-0'
							alt=''
							src='/email.svg'
						/>
						<div className='flex-1 border-0 flex flex-row items-center  py-2.5 px-0 min-w-0 border-b border-solid border-updated-color-grey2'>
							<input
								className='w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0'
								placeholder='Enter your email'
								type='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div className='w-full flex flex-row flex-wrap items-center justify-center gap-2 sm:gap-3'>
						<LockOutlined className='text-lg text-black relative overflow-hidden shrink-0' />
						<div className='flex-1 border-0 flex flex-row items-center  pt-2 px-0 pb-1.5 relative gap-2 sm:gap-3 min-w-0 min-h-[37px] border-b border-solid border-updated-color-grey2'>
							<input
								className='w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0'
								placeholder='New password'
								type={showPassword ? 'text' : 'password'}
								autoComplete='new-password'
								onChange={(e) => setPassword1(e.target.value)}
								value={password1}
							/>
							{showPassword ? (
								<EyeOutlined
									className='text-lg text-black absolute right-0 overflow-hidden shrink-0'
									onClick={() => setShowPassword(false)}
								/>
							) : (
								<EyeInvisibleOutlined
									className='text-lg text-black absolute right-0 overflow-hidden shrink-0'
									onClick={() => setShowPassword(true)}
								/>
							)}
						</div>
					</div>
					<div className='w-full flex flex-row flex-wrap items-center justify-center gap-2 sm:gap-3'>
						<LockOutlined className='text-lg text-black relative overflow-hidden shrink-0' />
						<div className='flex-1 border-0 flex flex-row items-center  pt-2 px-0 pb-1.5 relative gap-2 sm:gap-3 min-w-0 min-h-[37px] border-b border-solid border-updated-color-grey2'>
							<input
								className='w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0'
								placeholder='Confirm password'
								type={showPassword ? 'text' : 'password'}
								autoComplete='new-password'
								onChange={(e) => setPassword2(e.target.value)}
								value={password2}
							/>
							{showPassword ? (
								<EyeOutlined
									className='text-lg text-black absolute right-0 overflow-hidden shrink-0'
									onClick={() => setShowPassword(false)}
								/>
							) : (
								<EyeInvisibleOutlined
									className='text-lg text-black absolute right-0 overflow-hidden shrink-0'
									onClick={() => setShowPassword(true)}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<button
				className='cursor-pointer [border:none] py-[0.625rem] px-[1.25rem] bg-[transparent] self-stretch rounded-3xs [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] flex flex-row items-center justify-center'
				onClick={onSignUpClick}
			>
				{submitted && (
					<svg
						className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
					>
						<circle
							className='opacity-25'
							cx='12'
							cy='12'
							r='10'
							stroke='currentColor'
							strokeWidth='4'
						></circle>
						<path
							className='opacity-75'
							fill='currentColor'
							d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
						></path>
					</svg>
				)}
				<div className='w-[4.75rem] relative text-[1rem] font-semibold font-montserrat text-white text-center inline-block min-w-[4.75rem]'>
					{submitted ? 'Registering...' : 'Continue'}
				</div>
			</button>
			{error && (
				<p className='text-red-500 text-lg text-center w-full'>{error}</p>
			)}
		</form>
	);
};
