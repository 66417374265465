import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { socket, sharedRoomIdRef } from '../../socketContext';
import { top100WorldLanguages } from '../../languages';
import { jwtTokenRef, translateTexts } from '../../httpContext';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';

const formatDate = (date) => {
	const options = {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
	};
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
	return formattedDate;
};

const formatTime = (milliseconds) => {
	const totalSeconds = Math.floor(milliseconds / 1000);
	const hours = Math.floor(milliseconds / (1000 * 60 * 60));
	const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
	const seconds = totalSeconds % 60;
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const BroadcastRoom = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const pathname = location.pathname;

	const [showLinkQRCode, setShowLinkQRCode] = useState(false);
	const [startTime, setStartTime] = useState(0);
	const [timerRunning, setTimerRunning] = useState(false);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [messages, setMessages] = useState([]);
	const [messagesMerged, setMessagesMerged] = useState([]);
	const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
	const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
	const [searchBarInput, setSearchBarInput] = useState('');
	const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Original');

	const linkAndQRCodeRef = useRef(null);
	const messagesRef = useRef(messages);
	const messagesContainerRef = useRef(null);
	const shouldScroll = useRef(false);
	const usingInputLanguagePopupRef = useRef(false);
	const inputLanguage = useRef(null);
	const inputLanguageRef = useRef(null);
	const inputLanguageDropdownRef = useRef(null);

	const date = new Date();
	const formattedDate = formatDate(date);

	const startTimer = () => {
		setStartTime(new Date().getTime());
		setTimerRunning(true);
	};

	const pauseTimer = () => {
		setTimerRunning(false);
	};

	const updateMessagesMerged = (messagesUpdated) => {
		var messagesMergedUpdated = [];
		for (var i = 0; i < messagesUpdated.length; i++) {
			if (messagesUpdated[i]['transcript'] != '') {
				const currentTimestamp = messagesUpdated[i]['timestamp'];
				const lastMergedMessage =
					messagesMergedUpdated[messagesMergedUpdated.length - 1];

				if (
					i > 0 &&
					!messagesUpdated[i]['did_speaker_change'] &&
					!messagesUpdated[i]['is_ai_answer'] &&
					!messagesUpdated[i - 1]['is_ai_answer'] &&
					messagesMergedUpdated.length > 0 &&
					messagesUpdated[i - 1]['speaker'] === messagesUpdated[i]['speaker'] &&
					lastMergedMessage['messages_merged'] < 7 &&
					currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
				) {
					lastMergedMessage['transcript'] =
						lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

					lastMergedMessage['messages_merged']++;

					for (var key in messagesUpdated[i]['corrected_transcripts']) {
						if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
							lastMergedMessage['corrected_transcripts'][key] = '';
						}
						lastMergedMessage['corrected_transcripts'][key] =
							lastMergedMessage['corrected_transcripts'][key] +
							' ' +
							messagesUpdated[i]['corrected_transcripts'][key];
					}
				} else {
					messagesUpdated[i]['messages_merged'] = 1;
					const timestamp = new Date(currentTimestamp * 1000);
					messagesUpdated[i]['timestamp_display'] =
						timestamp.toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
						});
					messagesMergedUpdated.push(messagesUpdated[i]);
				}
			}
		}
		console.log(messagesMergedUpdated);
		setMessagesMerged(messagesMergedUpdated);
	};

	const joinRoomAsync = async () => {
		console.log('shared room id ref: ' + sharedRoomIdRef.current);
		var roomId = location.pathname.split('/')[2];
		console.log('joining room ' + location.pathname.split('/')[2]);
		socket.emit('join_room', roomId);
		socket.on('room', async (data) => {
			console.log('data: ' + data);
			sharedRoomIdRef.current = data;
			console.log('joining room ' + sharedRoomIdRef.current);
			startTimer();
		});
		socket.on('load_messages', async (data) => {
			console.log(JSON.stringify(data));
			if (data.length > 0 && data[0].corrected_transcripts != null) {
				console.log('loading messages ' + data[0].corrected_transcripts);
			}
			setMessages(data);
			updateMessagesMerged(data);
		});
	};

	useEffect(() => {
		joinRoomAsync();
	}, []);

	useEffect(() => {
		// click outside the language picker popup to close it
		const handleClickOutside = (event) => {
			if (usingInputLanguagePopupRef.current) {
				if (
					inputLanguageRef.current &&
					!inputLanguageRef.current.contains(event.target)
				) {
					if (
						inputLanguageDropdownRef.current &&
						!inputLanguageDropdownRef.current.contains(event.target)
					) {
						usingInputLanguagePopupRef.current = false;
						setLanguageDropdownVisible(false);
					}
				}
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		socket.removeAllListeners();
		console.log('adding listener for receive_transcriptions');
		socket.on('room', (data) => {
			sharedRoomIdRef.current = data;
		});

		// check if the query parameters in the url contain room_id=... If so, set the sharedRoomIdRef to the room_id
		const urlParams = new URLSearchParams(window.location.search);
		const roomId = urlParams.get('room_id');
		if (roomId) {
			console.log('joining room ' + roomId);
			sharedRoomIdRef.current = roomId;
		}

		socket.on('receive_transcriptions', async (data) => {
			console.log('received transcriptions ' + JSON.stringify(data));
			let messagesToAdd = [];
			let oldMessages = [...messagesRef.current];
			let message = data;

			var messageToAdd = {
				transcript_id: message['transcript_id'],
				transcript: message['transcript'],
				corrected_transcripts: message['corrected_transcripts'],
				is_final: message['is_final'],
				timestamp: message['timestamp'],
				diarization: message['diarization'],
				did_speaker_change: message['did_speaker_change'],
				is_ai_answer: message['is_ai_answer'],
				speaker: message['speaker'],
			};

			var replacedMessage = false;
			for (var i = 0; i < oldMessages.length; i++) {
				if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
					oldMessages[i] = messageToAdd;
					replacedMessage = true;
					messagesToAdd = [];
				}
			}

			if (!replacedMessage) {
				if (
					oldMessages.length > 0 &&
					!oldMessages[oldMessages.length - 1]['is_final']
				) {
					oldMessages.pop();
				}
				messagesToAdd = [message];
			}

			const correctedMessagesContainer = messagesContainerRef.current;
			if (correctedMessagesContainer) {
				const { scrollTop, clientHeight, scrollHeight } =
					correctedMessagesContainer;
				console.log(scrollTop, clientHeight, scrollHeight);
				console.log('dist: ', scrollHeight - clientHeight - scrollTop);
				const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
				if (atBottom) {
					shouldScroll.current = true;
				} else {
					setShouldShowScrollButton(true);
				}
			}

			setMessages([...oldMessages, ...messagesToAdd]);
			var messagesUpdated = [];
			for (var i = 0; i < oldMessages.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(oldMessages[i])),
				];
			}
			for (var i = 0; i < messagesToAdd.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(messagesToAdd[i])),
				];
			}
			console.log('messages updated: ' + JSON.stringify(messagesUpdated));

			updateMessagesMerged(messagesUpdated);

			messagesRef.current = [...oldMessages, ...messagesToAdd];
			localStorage.setItem('messages', JSON.stringify(messagesUpdated));
		});

		return () => {
			console.log('Removing socket listeners');
			socket.removeAllListeners();
			socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
		};
	}, [pathname]);

	useEffect(() => {
		const correctedMessagesContainer = messagesContainerRef.current;
		if (correctedMessagesContainer) {
			let lastScrollTop = correctedMessagesContainer.scrollTop;

			const handleScroll = () => {
				const currentScrollTop = correctedMessagesContainer.scrollTop;
				if (currentScrollTop < lastScrollTop) {
					shouldScroll.current = false;
				}
				lastScrollTop = currentScrollTop;
			};

			if (correctedMessagesContainer) {
				correctedMessagesContainer.addEventListener('scroll', handleScroll);
			}

			return () => {
				if (correctedMessagesContainer) {
					correctedMessagesContainer.removeEventListener(
						'scroll',
						handleScroll
					);
				}
			};
		}
	}, []);

	const correctedScrollToBottom = () => {
		setShouldShowScrollButton(false);
		messagesContainerRef.current?.scrollTo({
			top: Math.ceil(messagesContainerRef.current.scrollHeight),
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (shouldScroll.current) {
			correctedScrollToBottom();
		}
	}, [messagesMerged]);

	const handleInputLanguageClick = (language) => {
		setLanguageDropdownVisible(false);
		socket.emit('set_broadcast_language', language);
		if (language != inputLanguage.current) {
			var textsToTranslate = [];
			var messagesAlreadyTranslated = [];
			for (var i = 0; i < messagesRef.current.length; i++) {
				var message = messagesRef.current[i];
				if (message['corrected_transcripts'][language] != undefined) {
					messagesAlreadyTranslated.push(i);
					continue;
				}
				textsToTranslate.push(message.transcript);
			}
			console.log('texts to translate: ' + textsToTranslate);
			translateTexts(textsToTranslate, language).then((translations) => {
				console.log('translations: ' + translations);
				var updatedMessages = [];
				for (var i = 0; i < messagesRef.current.length; i++) {
					var message = messagesRef.current[i];
					if (messagesAlreadyTranslated.includes(i)) {
						updatedMessages.push(message);
						continue;
					} else {
						if (message['corrected_transcripts'][language] == undefined) {
							message['corrected_transcripts'][language] =
								translations[i] + ' ';
						}
						updatedMessages.push(message);
					}
				}
				setMessages(updatedMessages);
				updateMessagesMerged(updatedMessages);
				localStorage.setItem('messages', JSON.stringify(messagesMerged));
			});
		}
		inputLanguage.current = language;
		setInputLanguageDisplay(language);
	};

	useEffect(() => {
		const messagesContainer = messagesContainerRef.current;
		let lastScrollTop = messagesContainer.scrollTop;

		const handleScroll = () => {
			const currentScrollTop = messagesContainer.scrollTop;
			if (currentScrollTop < lastScrollTop) {
				// User is scrolling up
				shouldScroll.current = false;
			}
			lastScrollTop = currentScrollTop;
		};

		if (messagesContainer) {
			messagesContainer.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (messagesContainer) {
				messagesContainer.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				linkAndQRCodeRef.current &&
				!linkAndQRCodeRef.current.contains(event.target)
			) {
				setShowLinkQRCode(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		if (shouldScroll.current) {
			scrollToBottom();
		}
	}, [messagesMerged]);

	useEffect(() => {
		let interval;
		if (timerRunning) {
			interval = setInterval(() => {
				const now = new Date().getTime();
				const elapsed = now - startTime;
				// console.log('elapsed: ' + formatTime(elapsed))
				setElapsedTime(elapsed);
				// console.log('elapsedTime: ' + elapsedTime)
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timerRunning, startTime]);

	useEffect(() => {
		// click outside the language picker popup to close it
		const handleClickOutside = (event) => {
			if (usingInputLanguagePopupRef.current) {
				if (
					inputLanguageRef.current &&
					!inputLanguageRef.current.contains(event.target)
				) {
					if (
						inputLanguageDropdownRef.current &&
						!inputLanguageDropdownRef.current.contains(event.target)
					) {
						usingInputLanguagePopupRef.current = false;
						setLanguageDropdownVisible(false);
					}
				}
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="flex bg-whitesmoke w-full h-full">
			{showLinkQRCode && (
				<div className="fixed inset-0 z-50 flex items-center justify-center bg-black/20">
					<RoomLinkAndQRCode
						link={
							'https://interpretapp.ai/broadcast/' + sharedRoomIdRef.current
						}
						onOutsideClickFunc={() => setShowLinkQRCode(false)}
						divRef={linkAndQRCodeRef}
						isWeb={true}
					/>
				</div>
			)}
			<div
				className="h-screen w-28 shadow-md flex flex-col gap-6 items-end pt-12 pr-8"
				style={{
					background:
						'linear-gradient(103deg, rgba(0, 126, 243, 0.10) 0.03%, rgba(121, 119, 209, 0.10) 99.97%), var(--white, #FFF)',
				}}
			>
				<img src="/logo.svg" alt="logo" className="w-8 h-8 mb-16" />
				<button
					onClick={() => {
						pauseTimer();
						navigate('/signup');
					}}
				>
					<img
						src="/broadcastback.svg"
						alt="broadcastback"
						className="w-8 h-8"
					/>
				</button>
				<button onClick={() => setShowLinkQRCode(true)}>
					<img
						src="/broadcastshare.svg"
						alt="broadcastback"
						className="w-8 h-8"
					/>
				</button>
			</div>
			<div className="flex flex-col gap-6 justify-center w-full h-screen px-14">
				<div className="flex justify-between items-center relative">
					<div className="font-montserrat w-full mr-6">
						Room Created {formattedDate}
					</div>
					<div
						ref={inputLanguageRef}
						className="w-fit rounded-34xl h-fit flex py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer"
						onClick={() => {
							setLanguageDropdownVisible(!languageDropdownVisible);
							usingInputLanguagePopupRef.current =
								!usingInputLanguagePopupRef.current;
						}}
					>
						<div className="flex flex-col py-1.5 px-2.5">
							<div className="flex   gap-[10px] cursor-pointer">
								<b className="relative">{inputLanguageDisplay}</b>
								<img
									className="w-5 relative h-5"
									alt=""
									src="/select-more-language.svg"
								/>
							</div>
						</div>
					</div>
					{languageDropdownVisible && (
						<div
							ref={inputLanguageDropdownRef}
							className="grid grid-cols-4 gap-8 px-10 bg-white border absolute top-12 right-0 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-96 overflow-y-auto w-full p-4"
						>
							<div className="col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300">
								<input
									type="text"
									placeholder="Search..."
									className="w-full p-1 border-none outline-none text-lg font-montserrat"
									value={searchBarInput}
									onChange={(e) => setSearchBarInput(e.target.value)}
								/>
								<img
									className="w-5 h-5 ml-2"
									alt=""
									src="/general--search.svg"
								/>
							</div>
							{top100WorldLanguages
								.filter((language) =>
									language
										.toLowerCase()
										.includes(searchBarInput?.toLowerCase() || '')
								)
								.map((language) => (
									<div
										key={language}
										className=" hover:bg-gray-200 cursor-pointer w-full h-fit"
										onClick={() => handleInputLanguageClick(language)}
									>
										{language}
									</div>
								))}
						</div>
					)}
				</div>
				<div
					className="flex relative flex-col w-full text-xs text-updated-color-new-black font-montserrat h-2/3 bg-white rounded-lg border-solid border-px border-updated-color-grey1"
					ref={messagesContainerRef}
					onTransitionEnd={() => {
						if (shouldScroll.current) {
							// check if at the bottom
							const messagesContainer = messagesContainerRef.current;
							if (messagesContainer) {
								const { scrollTop, clientHeight, scrollHeight } =
									messagesContainer;
								const atBottom =
									Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
								if (atBottom) {
									shouldScroll.current = false;
								}
							}
						}
					}}
				>
					<div className="flex flex-col w-fit gap-2.5 text-left p-6 pb-10 overflow-y-auto">
						{messagesMerged.map((message, index) => (
							<div key={index}>
								<div className="text-[12px]">
									{(message.corrected_transcripts[inputLanguage.current] ||
										message.transcript) && (
										<div className="text-gray-500 mb-1">
											{message.timestamp_display}
										</div>
									)}
								</div>
								<div>
									{inputLanguage.current === 'Original' ||
									inputLanguage.current === 'Detect Language'
										? message.transcript
										: message.corrected_transcripts[inputLanguage.current]}
								</div>
							</div>
						))}
					</div>
					<div
						className={`w-full flex justify-end absolute z-10 bottom-28 ${messagesMerged.length && 'py-2'} ${shouldShowScrollButton && 'pb-0'} pr-1`}
					>
						<div className="flex w-[54.3%] justify-between">
							<div>
								{shouldShowScrollButton && (
									<img
										className="w-[34px] h-[34px] overflow-hidden"
										loading="lazy"
										alt=""
										src="/arrow--arrow-down-6-circle.svg"
										onClick={() => {
											scrollToBottom();
										}}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="absolute bg-white bottom-0 flex flex-col w-full pt-2 gap-[10px] pb-10 rounded-lg">
						<div className="flex justify-center mx-auto items-center w-11/12 gap-2">
							<hr className="border-dashed w-full border h-px border-b-0 border-x-0" />
							<div className="text-xs font-medium font-montserrat text-updated-color-new-black text-center">
								{formatTime(elapsedTime)}
							</div>
							<hr className="border-dashed w-full border h-px border-b-0 border-x-0" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BroadcastRoom;
