import { Link } from 'react-router-dom';

export const Onboarding1 = () => {
	return (
		<div className="h-full pb-24 pt-[90px] px-[0.625rem] gap-[0.8125rem] tracking-[normal] font-montserrat text-updated-color-new-black flex flex-col justify-center items-center">
			<img className="w-full h-[40%] object-cover" alt="" src="/onb.png" />
			<div className="py-6 gap-[2rem] flex flex-col">
				<div className="flex justify-center mb-4">
					<div className="flex gap-[0.375rem]">
						<div className="h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
						<div className="h-[0.3125rem] w-[1.375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
						<div className="h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
						<div className="h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]" />
					</div>
				</div>
				<div className="text-center">
					<div className="flex flex-col gap-1 mb-6">
						<p className="font-semibold text-lg">
							Effortless Worldwide Dialogue
						</p>
						<p className="text-[#7B7B7B]">
							Convert live speech into your language, connecting participants
							worldwide.
						</p>
					</div>
				</div>
				<div className="flex justify-between items-center pl-[1.25rem] pr-[0.6875rem]">
					<Link
						to="/signup"
						onClick={() => {
							localStorage.setItem('isOnboarded', 'true');
						}}
						className="leading-[1.5rem] text-[1rem] text-updated-color-new-black font-medium min-w-[2.25rem] tracking-[0.1px]"
					>
						Skip
					</Link>
					<Link to="/onboarding-2">
						<img
							className="h-[4.938rem] w-[4.938rem] relative overflow-hidden shrink-0 object-contain cursor-pointer"
							loading="lazy"
							alt=""
							src="/mobilecont.svg"
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};
