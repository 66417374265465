import { useState, useEffect, useRef } from 'react';
import RoomSelectLanguageOverlay from '../../components/mobile/RoomSelectLanguageOverlay';
import { SavePopup } from '../../components/mobile/SavePopup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { socket } from '../../socketContext';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';
import { DeleteOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import axios from 'axios';
import RecentsSidebar from '../../components/mobile/RecentsSidebar';

const formatDate = (date) => {
	const options = {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
	};
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
	return formattedDate;
};

const formatTime = (milliseconds) => {
	const totalSeconds = Math.floor(milliseconds / 1000);
	const hours = Math.floor(milliseconds / (1000 * 60 * 60));
	const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
	const seconds = totalSeconds % 60;
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const Transcribe = () => {
	const [startTime, setStartTime] = useState(0);
	const [timerRunning, setTimerRunning] = useState(false);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const [messages, setMessages] = useState([]);
	const [messagesMerged, setMessagesMerged] = useState([]);
	const messagesRef = useRef(messages);
	const messagesContainerRef = useRef(null);
	const shouldScroll = useRef(false);
	const [languageOne, setLanguageOne] = useState('Detect Language');
	const [languageTwo, setLanguageTwo] = useState('Original');
	const languageOneRef = useRef(languageOne);
	const languageTwoRef = useRef(languageTwo);
	const [languageToSet, setLanguageToSet] = useState('');
	const [isTranscribing, setTranscribing] = useState(false);
	const [showOriginal, setShowOriginal] = useState(false);
	const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
	const stoppedTranscribing = useRef(false);
	const audioRef = useRef(null);
	const [playingAudio, setPlayingAudio] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [isSidebarVisible, setIsSidebarVisible] = useState(false);
	const [copied, setCopied] = useState(false);
	const navigate = useNavigate();

	const location = useLocation();
	const date = new Date();
	const formattedDate = formatDate(date);

	const startTimer = () => {
		setStartTime(new Date().getTime());
		setTimerRunning(true);
	};

	const pauseTimer = () => {
		setTimerRunning(false);
	};

	const copyMessagesToClipboard = (language) => {
		let text = '';
		for (let i = 0; i < messagesMerged.length; i++) {
			if (language == '' || language == undefined) {
				text += messagesMerged[i]['transcript'] + '\n';
			} else {
				text +=
					messagesMerged[i]['corrected_transcripts'][language] ??
					messagesMerged[i]['transcript'] + '\n';
			}
		}
		navigator.clipboard.writeText(text);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	const updateMessagesMerged = (messagesUpdated) => {
		var messagesMergedUpdated = [];
		for (var i = 0; i < messagesUpdated.length; i++) {
			if (messagesUpdated[i]['transcript'] != '') {
				const currentTimestamp = messagesUpdated[i]['timestamp'];
				const lastMergedMessage =
					messagesMergedUpdated[messagesMergedUpdated.length - 1];

				if (
					i > 0 &&
					!messagesUpdated[i]['did_speaker_change'] &&
					!messagesUpdated[i]['is_ai_answer'] &&
					!messagesUpdated[i - 1]['is_ai_answer'] &&
					messagesMergedUpdated.length > 0 &&
					lastMergedMessage['messages_merged'] < 7 &&
					currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
				) {
					lastMergedMessage['transcript'] =
						lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

					lastMergedMessage['messages_merged']++;

					for (var key in messagesUpdated[i]['corrected_transcripts']) {
						if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
							lastMergedMessage['corrected_transcripts'][key] = '';
						}
						lastMergedMessage['corrected_transcripts'][key] =
							lastMergedMessage['corrected_transcripts'][key] +
							' ' +
							messagesUpdated[i]['corrected_transcripts'][key];
					}
				} else {
					messagesUpdated[i]['messages_merged'] = 1;
					const timestamp = new Date(currentTimestamp * 1000);
					messagesUpdated[i]['timestamp_display'] =
						timestamp.toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
						});
					messagesMergedUpdated.push(messagesUpdated[i]);
				}
			}
		}
		console.log(messagesMergedUpdated);
		setMessagesMerged(messagesMergedUpdated);
	};

	const joinRoomAsync = async () => {
		var roomId = location.pathname.split('/')[2];
		// check if roomId is undefined
		if (roomId !== undefined) {
			console.log('joining room ' + location.pathname.split('/')[2]);
			socket.emit('join_room', roomId);
		}
		socket.on('load_messages', async (data) => {
			console.log(JSON.stringify(data));
			var messagesUpdated = [];
			for (var i = 0; i < data.length; i++) {
				var message = data[i];
				var messageToAdd = {
					transcript_id: message['transcript_id'],
					transcript: message['transcript'],
					corrected_transcripts: message['corrected_transcripts'],
					is_final: message['is_final'],
					did_speaker_change: message['did_speaker_change'],
					timestamp: message['timestamp'],
				};
				messagesUpdated.push(messageToAdd);
			}
			setMessages(messagesUpdated);
			messagesRef.current = messagesUpdated;
			updateMessagesMerged(messagesUpdated);
			shouldScroll.current = true;
		});

		socket.on('receive_transcriptions', async (data) => {
			console.log('received transcriptions ' + JSON.stringify(data));
			let messagesToAdd = [];
			let oldMessages = [...messagesRef.current];
			let message = data;

			var messageToAdd = {
				transcript_id: message['transcript_id'],
				transcript: message['transcript'],
				corrected_transcripts: message['corrected_transcripts'],
				is_final: message['is_final'],
				did_speaker_change: message['did_speaker_change'],
				timestamp: message['timestamp'],
				is_ai_answer: message['is_ai_answer'],
			};

			var replacedMessage = false;
			for (var i = 0; i < oldMessages.length; i++) {
				if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
					oldMessages[i] = messageToAdd;
					replacedMessage = true;
					messagesToAdd = [];
				}
			}

			if (!replacedMessage) {
				if (
					oldMessages.length > 0 &&
					!oldMessages[oldMessages.length - 1]['is_final']
				) {
					oldMessages.pop();
				}
				messagesToAdd = [message];
			}

			const messagesContainer = messagesContainerRef.current;
			if (messagesContainer) {
				const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
				console.log(scrollTop, clientHeight, scrollHeight);
				console.log('dist: ', scrollHeight - clientHeight - scrollTop);
				const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
				if (atBottom) {
					shouldScroll.current = true;
					if (shouldShowScrollButton) {
						setShouldShowScrollButton(false);
					}
				} else {
					if (!shouldShowScrollButton) {
						setShouldShowScrollButton(true);
					}
				}
			}
			setMessages([...oldMessages, ...messagesToAdd]);
			var messagesUpdated = [];
			for (var i = 0; i < oldMessages.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(oldMessages[i])),
				];
			}
			for (var i = 0; i < messagesToAdd.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(messagesToAdd[i])),
				];
			}
			console.log('messages updated: ' + JSON.stringify(messagesUpdated));

			updateMessagesMerged(messagesUpdated);

			messagesRef.current = [...oldMessages, ...messagesToAdd];
			localStorage.setItem('messages', JSON.stringify(messagesUpdated));
		});
	};

	const scrollToBottom = () => {
		messagesContainerRef.current?.scrollTo({
			top: Math.ceil(messagesContainerRef.current.scrollHeight),
			behavior: 'smooth',
		});
		setShouldShowScrollButton(false);
	};

	const onArrowArrowLeft6CircleClick = (path) => {
		navigate(path);
	};

	useEffect(() => {
		languageOneRef.current = languageOne;
		languageTwoRef.current = languageTwo;
	}, [languageOne, languageTwo]);

	useEffect(() => {
		joinRoomAsync();
		return () => {
			socket.off('load_messages');
			socket.off('receive_transcriptions');
		};
	}, []);

	useEffect(() => {
		return () => {
			stoppedTranscribing.current = true;
			socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
			const expiryDateEpochSeconds =
				Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later in epoch seconds
			socket.emit('save_audio', {
				jwt_token: jwtTokenRef.current,
				clear_audio: true,
				expiry_date_epoch_seconds: expiryDateEpochSeconds,
			});
			pauseTimer();
			if (messagesRef.current.length > 0) {
				// var lastMessage = messagesRef.current[messagesRef.current.length - 1];
				// lastMessage['is_final'] = true;
				setMessages([...messagesRef.current]);
				updateMessagesMerged(messagesRef.current);
				localStorage.setItem('messages', JSON.stringify(messagesRef.current));
			}
		};
	}, [navigate]);

	useEffect(() => {
		const messagesContainer = messagesContainerRef.current;
		let lastScrollTop = messagesContainer.scrollTop;

		const handleScroll = () => {
			const currentScrollTop = messagesContainer.scrollTop;
			if (currentScrollTop < lastScrollTop) {
				// User is scrolling up
				shouldScroll.current = false;
			}
			lastScrollTop = currentScrollTop;
		};

		if (messagesContainer) {
			messagesContainer.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (messagesContainer) {
				messagesContainer.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	useEffect(() => {
		if (shouldScroll.current) {
			scrollToBottom();
		}
	}, [messagesMerged]);

	useEffect(() => {
		if (localStorage.getItem('messages')) {
			// Check if the messages have timestmaps. If not, add them
			var messages = JSON.parse(localStorage.getItem('messages'));
			for (var i = 0; i < messages.length; i++) {
				if (messages[i]['timestamp'] == undefined) {
					const timestamp = new Date(messages[i]['timestamp'] * 1000);
					messages[i]['timestamp'] = timestamp.getTime();
				}
			}
			setMessages(messages);
			updateMessagesMerged(messages);
		}
	}, []);

	useEffect(() => {
		let interval;
		if (timerRunning) {
			interval = setInterval(() => {
				const now = new Date().getTime();
				const elapsed = now - startTime;
				// console.log('elapsed: ' + formatTime(elapsed))
				setElapsedTime(elapsed);
				// console.log('elapsedTime: ' + elapsedTime)
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timerRunning, startTime]);

	const selectLanguage = (language) => {
		setIsOverlayOpen(false);
		if (languageToSet === 'one') {
			setLanguageOne(language);
		} else if (languageToSet === 'two') {
			if (language != languageTwoRef.current) {
				var textsToTranslate = [];
				var messagesAlreadyTranslated = [];
				for (var i = 0; i < messagesRef.current.length; i++) {
					var message = messagesRef.current[i];
					if (message['corrected_transcripts'][language] != undefined) {
						messagesAlreadyTranslated.push(i);
						continue;
					}
					textsToTranslate.push(message.transcript);
				}
				console.log('texts to translate: ' + textsToTranslate);
				translateTexts(textsToTranslate, language).then((translations) => {
					console.log('translations: ' + translations);
					var updatedMessages = [];
					for (var i = 0; i < messagesRef.current.length; i++) {
						var message = messagesRef.current[i];
						if (messagesAlreadyTranslated.includes(i)) {
							updatedMessages.push(message);
							continue;
						} else {
							if (message['corrected_transcripts'][language] == undefined) {
								message['corrected_transcripts'][language] =
									translations[i] + ' ';
							}
							updatedMessages.push(message);
						}
					}
					console.log('updated messages: ' + updatedMessages);
					setMessages(updatedMessages);
					updateMessagesMerged(updatedMessages);
					localStorage.setItem('messages', JSON.stringify(messagesMerged));
				});
			}
			setLanguageTwo(language);
		}
	};

	const onTranscribe = async (blob) => {
		if (stoppedTranscribing.current) {
			clearChunks();
			stoppedTranscribing.current = false;
			return {
				blob,
				text: '',
			};
		}

		const base64 = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});

		if (jwtTokenRef.current == undefined) {
			console.log('jwtToken is undefined');
			return;
		}

		console.log(
			'gonna send request. blob length: ' +
				blob.size +
				' base64 length: ' +
				base64.length
		);
		var languagesToTranslateTo = [];
		if (
			languageOneRef.current != 'Detect Language' &&
			languageOneRef.current != 'Original'
		) {
			languagesToTranslateTo.push(languageOneRef.current);
		}
		if (
			languageTwoRef.current != 'Detect Language' &&
			languageTwoRef.current != 'Original'
		) {
			languagesToTranslateTo.push(languageTwoRef.current);
		}

		socket.emit('transcribe', {
			audio: base64,
			jwt_token: jwtTokenRef.current,
			translate_to_languages: languagesToTranslateTo,
		});
		console.log(
			'sent request with languages to translate to: ' + languagesToTranslateTo
		);

		clearChunks();

		// you must return result from your server in Transcript format
		return {
			blob,
			text: '',
		};
	};

	const { transcript, recording, stopRecording, startRecording, clearChunks } =
		useWhisper({
			// callback to handle transcription with custom server
			onDataAvailable: onTranscribe,
			onTranscribe: onTranscribe,
			streaming: true,
			timeSlice: 250, // 5 seconds
			removeSilence: false,
			whisperConfig: {
				language: 'en',
			},
		});

	const handleTranscribeButton = async (saving = false) => {
		if (isTranscribing || saving) {
			if (isTranscribing) {
				stoppedTranscribing.current = true;
				await stopRecording();
				pauseTimer();
				socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
				if (messagesRef.current.length > 0) {
					// var lastMessage = messagesRef.current[messagesRef.current.length - 1];
					// lastMessage['is_final'] = true;
					setMessages([...messagesRef.current]);
					updateMessagesMerged(messagesRef.current);
					localStorage.setItem('messages', JSON.stringify(messagesRef.current));
				}
			}
		} else {
			stoppedTranscribing.current = false;
			await startRecording();
			startTimer();
		}
		if (!saving) {
			setTranscribing(!isTranscribing);
		}
	};

	const playAudio = async (text) => {
		try {
			if (playingAudio) {
				audioRef.current.pause();
				setPlayingAudio(false);
			}
			const response = await axios.post(
				`${serverURL}/text_to_speech`,
				{ text: text }, // Correctly sending the body as the second argument
				{
					headers: {
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
					responseType: 'blob', // Ensure the response is a blob
				}
			);

			if (response.status === 200) {
				setPlayingAudio(true);
				const audioBlob = response.data;

				// Create a URL for the audio blob and handle it as needed
				const audioUrl = URL.createObjectURL(audioBlob);
				const audio = new Audio(audioUrl);
				audio.play();
				audioRef.current = audio;
				audio.onended = () => {
					setPlayingAudio(false);
				};
			} else {
				throw new Error(`Received status code ${response.status}`);
			}
		} catch (error) {
			console.error('Error occurred:', error);
		}
	};

	const toggleSidebar = () => {
		setIsSidebarVisible(!isSidebarVisible);
	};

	return (
		<motion.div
			initial={{ x: '100%' }}
			animate={{ x: 0 }}
			exit={{ x: '100%' }}
			transition={{ type: 'tween', duration: 0.25 }}
			className="absolute z-20 w-full h-full flex items-center justify-center overflow-hidden"
		>
			<motion.div
				initial={{ x: '100%' }}
				animate={{ x: isSidebarVisible ? '0%' : '100%' }}
				transition={{ type: 'tween', duration: 0.5 }}
				className={`fixed top-0 right-0 w-80 h-full transition-colors shadow-md ${isSidebarVisible ? 'bg-black/30 duration-1000' : 'duration-0'} w-full`}
				style={{ zIndex: 100 }}
				onAnimationComplete={() => {}}
			>
				<RecentsSidebar
					onClose={() => {
						setIsSidebarVisible(false);
					}}
				/>
			</motion.div>

			<div
				className={`bg-whitesmoke w-full h-full flex flex-col pt-14 sm:pt-[70px] px-2.5 sm:px-5 pb-0 sm:pb-[100px] box-border text-center text-xs text-updated-color-new-black font-montserrat relative`}
			>
				<div className="flex items-center w-full">
					<img
						className="h-10 w-10 cursor-pointer"
						loading="lazy"
						alt=""
						src="/arrow--arrow-left-6-circle.svg"
						onClick={() => {
							stoppedTranscribing.current = true;
							pauseTimer();
							if (messagesRef.current.length > 0) {
								// var lastMessage =
								// 	messagesRef.current[messagesRef.current.length - 1];
								// lastMessage['is_final'] = true;
								setMessages([...messagesRef.current]);
								updateMessagesMerged(messagesRef.current);
								localStorage.setItem(
									'messages',
									JSON.stringify(messagesRef.current)
								);
							}
							onArrowArrowLeft6CircleClick('/');
						}}
					/>
					<div className="font-medium font-montserrat w-full mr-6">
						Transcriber {formattedDate}
					</div>
					<img
						src="/recents.svg"
						alt="Recents"
						className="h-9 w-9"
						onClick={toggleSidebar}
					/>
				</div>
				<div className="flex flex-row items-start justify-center w-full mt-4">
					<div className="flex rounded-xl bg-white shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex-row items-center justify-center py-1 px-2.5 box-border gap-[10px]">
						<div className="flex-1 flex flex-row items-center justify-start gap-[5px]">
							<div
								className="relative inline-block min-w-[45px]"
								onClick={() => {
									setIsOverlayOpen(true);
									setLanguageToSet('one');
								}}
							>
								{languageOne}
							</div>
						</div>
						<img
							className="h-6 w-6 relative overflow-hidden shrink-0"
							loading="lazy"
							alt=""
							src="/media--playlist---repeat-list.svg"
							onClick={() => {
								var temp = languageOneRef.current;
								setLanguageOne(languageTwoRef.current);
								setLanguageTwo(temp);
							}}
						/>
						<div className="flex-1 flex flex-row items-center justify-start gap-[5px]">
							<div
								className="relative inline-block min-w-[48px]"
								onClick={() => {
									setIsOverlayOpen(true);
									setLanguageToSet('two');
								}}
							>
								{languageTwo}
							</div>
						</div>
					</div>
				</div>
				<div
					className="flex-1 flex flex-col w-full overflow-y-auto items-start justify-start pt-0 px-0 pb-10 text-xs text-updated-color-new-black font-montserrat mt-4"
					ref={messagesContainerRef}
					onTransitionEnd={() => {
						if (shouldScroll.current) {
							// check if at the bottom
							const messagesContainer = messagesContainerRef.current;
							if (messagesContainer) {
								const { scrollTop, clientHeight, scrollHeight } =
									messagesContainer;
								const atBottom =
									Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
								if (atBottom) {
									shouldScroll.current = false;
								}
							}
						}
					}}
				>
					<div className="flex flex-col w-full gap-2.5 text-left">
						{messagesMerged.map((message, index) => (
							<div key={index}>
								<div className="flex flex-col items-start justify-start text-[12px]">
									{(showOriginal ||
										message.corrected_transcripts[languageOne] ||
										message.transcript) && (
										<div className="text-gray-500 mb-1">
											{message.timestamp_display}
										</div>
									)}
									{showOriginal && (
										<>
											<div className="relative rounded-3xs [background:linear-gradient(rgba(0,126,243,0.3),rgba(0,126,243,0.3)),rgba(255,255,255,0.2)] flex py-2 px-2 box-border w-full">
												<p style={{ fontSize: '13px', color: 'black' }}>
													{message.corrected_transcripts[languageOne] ||
														message.transcript}
												</p>
												<img
													src="/speaker_icon.svg"
													alt="Speaker Icon"
													className="absolute bottom-2 right-2 w-6 h-6 transition-transform transform hover:scale-105 active:scale-95"
													onClick={async () => {
														await playAudio(
															message.corrected_transcripts[languageOne] ||
																message.transcript
														);
													}}
												/>
											</div>
										</>
									)}
									{(message.corrected_transcripts[languageTwo] ||
										message.transcript) && (
										<div className="relative rounded-3xs bg-white flex py-2 px-2 box-border w-full mt-4">
											<p style={{ fontSize: '13px', color: 'black' }}>
												{message.corrected_transcripts[languageTwo] ||
													message.transcript}
											</p>
											<img
												src="/speaker_icon.svg"
												alt="Speaker Icon"
												className="absolute bottom-2 right-2 w-6 h-6 cursor-pointer transition-transform transform hover:scale-105 active:scale-95"
												onClick={async () => {
													await playAudio(
														message.corrected_transcripts[languageTwo] ||
															message.transcript
													);
												}}
											/>
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="self-stretch relative flex flex-col items-start justify-start text-left text-xs text-updated-color-new-black font-montserrat">
					<div
						className={`w-full flex justify-end absolute -top-12 ${messagesMerged.length && 'py-2'} ${shouldShowScrollButton && 'pb-0'} pr-1 backdrop-blur-sm bg-white/30`}
					>
						<div className="flex w-[54.3%] justify-between">
							<div>
								{shouldShowScrollButton && (
									<img
										className="w-[34px] h-[34px] overflow-hidden"
										loading="lazy"
										alt=""
										src="/arrow--arrow-down-6-circle.svg"
										onClick={() => {
											scrollToBottom();
										}}
									/>
								)}
							</div>
							{messagesMerged.length > 0 && (
								<div className="flex gap-6">
									{!copied ? (
										<CopyOutlined
											className="text-2xl"
											alt="Copy button"
											onClick={() => {
												copyMessagesToClipboard(languageTwo);
											}}
										/>
									) : (
										<CheckOutlined className="text-2xl" />
									)}
									<DeleteOutlined
										className="text-2xl"
										alt="Delete button"
										onClick={() => {
											localStorage.removeItem('messages');
											messagesRef.current = [];
											if (messagesMerged.length > 0) {
												setMessagesMerged(() => []);
											}
											if (messages.length > 0) {
												setMessages(() => []);
											}
											console.log(messages);
											console.log(messagesMerged);
											console.log(messagesRef.current);
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="self-stretch flex flex-col items-start justify-start pt-2 px-0 pb-10 gap-[10px]">
						<div className="flex justify-center items-center w-full gap-2">
							<hr className="border-dashed w-full border h-px border-b-0 border-x-0" />
							<div className="text-xs font-medium font-montserrat text-updated-color-new-black text-center inline-block">
								{formatTime(elapsedTime)}
							</div>
							<hr className="border-dashed w-full border h-px border-b-0 border-x-0" />
						</div>
						<div className="self-stretch flex flex-row items-center justify-center py-0">
							<div className="flex-1 flex flex-row items-center justify-center gap-[20px]">
								<div className="relative h-[70px] w-[250px] flex items-center justify-center">
									<div className="absolute left-1/2 transform -translate-x-1/2 w-[50px] h-[50px] flex items-center justify-center">
										{!isTranscribing ? (
											<div
												className={`self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex flex-row items-center justify-center py-[10px] max-h-[40px] px-[32px] my-auto border-[0.5px] border-solid border-updated-color-grey1`}
												onClick={() => handleTranscribeButton()}
											>
												<img
													className="h-7 w-7 relative overflow-hidden shrink-0"
													loading="lazy"
													alt=""
													src="/media--player---play.svg"
												/>
											</div>
										) : (
											<div
												className={`self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-updated-color-red flex flex-row items-center justify-center py-[10px] max-h-[40px] px-[32px] my-auto border-[0.5px] border-solid border-updated-color-grey1`}
												onClick={() => handleTranscribeButton()}
											>
												<img
													className="h-7 w-7 relative overflow-hidden shrink-0 mx-auto"
													loading="lazy"
													alt=""
													src="/media--player---pause.svg"
												/>
											</div>
										)}
									</div>
									<div
										className={`self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex flex-row items-center justify-center max-h-[40px] py-[20px] px-[20px] ml-[-200px] my-auto border-[0.5px] border-solid border-updated-color-grey1`}
										onClick={() => {
											if (messagesMerged.length > 0) {
												setShowPopup(true);
											}
										}}
									>
										Save
									</div>
									<div className="absolute right-0 top-[15px] w-[50px] h-[70px] flex flex-col items-center justify-center text-center text-[10px]">
										<div
											className="h-[33px] w-[50px] relative cursor-pointer"
											onClick={() => {
												setShowOriginal(!showOriginal);
											}}
										>
											<div
												className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[31px] ${
													showOriginal
														? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]'
														: 'bg-gray-300'
												}`}
											/>
											<div
												className={`absolute h-[75.76%] w-6/12 top-[12.12%] bottom-[12.12%] rounded-[50%] bg-white ${
													showOriginal
														? 'right-[12%] left-[38%]'
														: 'right-[38%] left-[12%]'
												}`}
											/>
										</div>
										<div className="mt-2 text-xs font-medium text-center">
											Show Original
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isOverlayOpen && (
				<div className="absolute top-0 left-0 w-full h-full">
					<RoomSelectLanguageOverlay
						onClose={() => setIsOverlayOpen(false)}
						onSelect={selectLanguage}
					/>
				</div>
			)}
			{showPopup && (
				<SavePopup
					stoppedTranscribing={stoppedTranscribing}
					socket={socket}
					handleTranscribeButton={handleTranscribeButton}
					jwtTokenRef={jwtTokenRef}
					setShowPopup={setShowPopup}
					isTranscribing={isTranscribing}
				/>
			)}
		</motion.div>
	);
};
