import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';
import { SavePopup } from '../../components/SavePopup';
import { languageToCode, top100WorldLanguages } from '../../languages';
import { socket } from '../../socketContext';
import { jwtTokenRef, translateTexts } from '../../httpContext';
import { useWhisper } from '@chengsokdara/use-whisper';

const formatDate = (date) => {
	const options = {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
	};
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
	return formattedDate;
};

const formatTime = (milliseconds) => {
	const totalSeconds = Math.floor(milliseconds / 1000);
	const hours = Math.floor(milliseconds / (1000 * 60 * 60));
	const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
	const seconds = totalSeconds % 60;
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const SpeakerRoom = () => {
	const [showLinkQRCode, setShowLinkQRCode] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [startTime, setStartTime] = useState(0);
	const [timerRunning, setTimerRunning] = useState(false);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [messages, setMessages] = useState([]);
	const [messagesMerged, setMessagesMerged] = useState([]);
	const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
	const [isTranscribing, setTranscribing] = useState(false);
	const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
	const [searchBarInput, setSearchBarInput] = useState('');
	const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Original');

	const stoppedTranscribing = useRef(false);
	const linkAndQRCodeRef = useRef(null);
	const messagesRef = useRef(messages);
	const messagesContainerRef = useRef(null);
	const shouldScroll = useRef(false);
	const usingInputLanguagePopupRef = useRef(false);
	const inputLanguage = useRef(null);
	const inputLanguageRef = useRef(null);
	const inputLanguageDropdownRef = useRef(null);

	const navigate = useNavigate();
	const location = useLocation();
	const roomId = location.pathname.split('/')[2];

	const date = new Date();
	const formattedDate = formatDate(date);

	const startTimer = () => {
		setStartTime(new Date().getTime());
		setTimerRunning(true);
	};

	const pauseTimer = () => {
		setTimerRunning(false);
	};

	const updateMessagesMerged = (messagesUpdated) => {
		var messagesMergedUpdated = [];
		for (var i = 0; i < messagesUpdated.length; i++) {
			if (messagesUpdated[i]['transcript'] != '') {
				const currentTimestamp = messagesUpdated[i]['timestamp'];
				const lastMergedMessage =
					messagesMergedUpdated[messagesMergedUpdated.length - 1];

				if (
					i > 0 &&
					!messagesUpdated[i]['did_speaker_change'] &&
					!messagesUpdated[i]['is_ai_answer'] &&
					!messagesUpdated[i - 1]['is_ai_answer'] &&
					messagesMergedUpdated.length > 0 &&
					lastMergedMessage['messages_merged'] < 7 &&
					currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
				) {
					lastMergedMessage['transcript'] =
						lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

					lastMergedMessage['messages_merged']++;

					for (var key in messagesUpdated[i]['corrected_transcripts']) {
						if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
							lastMergedMessage['corrected_transcripts'][key] = '';
						}
						lastMergedMessage['corrected_transcripts'][key] =
							lastMergedMessage['corrected_transcripts'][key] +
							' ' +
							messagesUpdated[i]['corrected_transcripts'][key];
					}
				} else {
					messagesUpdated[i]['messages_merged'] = 1;
					const timestamp = new Date(currentTimestamp * 1000);
					messagesUpdated[i]['timestamp_display'] =
						timestamp.toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
						});
					messagesMergedUpdated.push(messagesUpdated[i]);
				}
			}
		}
		console.log(messagesMergedUpdated);
		setMessagesMerged(messagesMergedUpdated);
	};

	const joinRoomAsync = async () => {
		// check if roomId is undefined
		if (roomId !== undefined) {
			console.log('joining room ' + location.pathname.split('/')[2]);
			socket.emit('join_room', roomId);
		}
		socket.on('load_messages', async (data) => {
			console.log(JSON.stringify(data));
			let messagesUpdated = [];
			for (let i = 0; i < data.length; i++) {
				const message = data[i];
				const messageToAdd = {
					transcript_id: message['transcript_id'],
					transcript: message['transcript'],
					corrected_transcripts: message['corrected_transcripts'],
					is_final: message['is_final'],
					did_speaker_change: message['did_speaker_change'],
					timestamp: message['timestamp'],
				};
				messagesUpdated.push(messageToAdd);
			}
			setMessages(messagesUpdated);
			messagesRef.current = messagesUpdated;
			updateMessagesMerged(messagesUpdated);
			shouldScroll.current = true;
		});

		socket.on('receive_transcriptions', async (data) => {
			console.log('received transcriptions ' + JSON.stringify(data));
			let messagesToAdd = [];
			let oldMessages = [...messagesRef.current];
			let message = data;

			const messageToAdd = {
				transcript_id: message['transcript_id'],
				transcript: message['transcript'],
				corrected_transcripts: message['corrected_transcripts'],
				is_final: message['is_final'],
				did_speaker_change: message['did_speaker_change'],
				timestamp: message['timestamp'],
				is_ai_answer: message['is_ai_answer'],
			};

			let replacedMessage = false;
			for (let i = 0; i < oldMessages.length; i++) {
				if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
					oldMessages[i] = messageToAdd;
					replacedMessage = true;
					messagesToAdd = [];
				}
			}

			if (!replacedMessage) {
				if (
					oldMessages.length > 0 &&
					!oldMessages[oldMessages.length - 1]['is_final']
				) {
					oldMessages.pop();
				}
				messagesToAdd = [message];
			}

			const messagesContainer = messagesContainerRef.current;
			if (messagesContainer) {
				const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
				console.log(scrollTop, clientHeight, scrollHeight);
				console.log('dist: ', scrollHeight - clientHeight - scrollTop);
				const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
				if (atBottom) {
					shouldScroll.current = true;
					if (shouldShowScrollButton) {
						setShouldShowScrollButton(false);
					}
				} else {
					if (!shouldShowScrollButton) {
						setShouldShowScrollButton(true);
					}
				}
			}
			setMessages([...oldMessages, ...messagesToAdd]);
			let messagesUpdated = [];
			for (let i = 0; i < oldMessages.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(oldMessages[i])),
				];
			}
			for (let i = 0; i < messagesToAdd.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(messagesToAdd[i])),
				];
			}
			console.log('messages updated: ' + JSON.stringify(messagesUpdated));

			updateMessagesMerged(messagesUpdated);

			messagesRef.current = [...oldMessages, ...messagesToAdd];
		});
	};

	const scrollToBottom = () => {
		messagesContainerRef.current?.scrollTo({
			top: Math.ceil(messagesContainerRef.current.scrollHeight),
			behavior: 'smooth',
		});
		setShouldShowScrollButton(false);
	};

	const selectLanguage = (language) => {
		if (language != inputLanguage.current) {
			let textsToTranslate = [];
			let messagesAlreadyTranslated = [];
			for (let i = 0; i < messagesRef.current.length; i++) {
				const message = messagesRef.current[i];
				if (message['corrected_transcripts'][language] != undefined) {
					messagesAlreadyTranslated.push(i);
					continue;
				}
				textsToTranslate.push(message.transcript);
			}
			console.log('texts to translate: ' + textsToTranslate);
			translateTexts(textsToTranslate, language).then((translations) => {
				console.log('translations: ' + translations);
				let updatedMessages = [];
				for (let i = 0; i < messagesRef.current.length; i++) {
					let message = messagesRef.current[i];
					if (messagesAlreadyTranslated.includes(i)) {
						updatedMessages.push(message);
						continue;
					} else {
						if (message['corrected_transcripts'][language] == undefined) {
							message['corrected_transcripts'][language] =
								translations[i] + ' ';
						}
						updatedMessages.push(message);
					}
				}
				console.log('updated messages: ' + updatedMessages);
				setMessages(updatedMessages);
				updateMessagesMerged(updatedMessages);
			});
		}
	};

	const onTranscribe = async (blob) => {
		if (stoppedTranscribing.current) {
			clearChunks();
			stoppedTranscribing.current = false;
			return {
				blob,
				text: '',
			};
		}

		const base64 = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});

		if (jwtTokenRef.current == undefined) {
			return;
		}

		var translateToLanguages = [];
		var possibleLanguages = [];

		if (
			inputLanguage.current != 'Detect Language' &&
			inputLanguage.current != 'Original'
		) {
			translateToLanguages.push(inputLanguage.current);
			if (languageToCode[inputLanguage.current] != undefined) {
				possibleLanguages.push(languageToCode[inputLanguage.current]);
			}
		}

		if (possibleLanguages.length > 0) {
			socket.emit('possible_languages', [
				languageToCode[inputLanguage.current],
			]);
		}

		socket.emit('transcribe', {
			audio: base64,
			jwt_token: jwtTokenRef.current,
			translate_to_languages: translateToLanguages,
		});
		clearChunks();

		return {
			blob,
			text: '',
		};
	};

	const { transcript, recording, stopRecording, startRecording, clearChunks } =
		useWhisper({
			// callback to handle transcription with custom server
			onDataAvailable: onTranscribe,
			onTranscribe: onTranscribe,
			streaming: true,
			timeSlice: 250, // 5 seconds
			removeSilence: false,
			whisperConfig: {
				language: 'en',
			},
		});

	const handleTranscribeButton = async () => {
		console.log('isTranscribing', isTranscribing);
		if (isTranscribing) {
			stoppedTranscribing.current = true;
			setTranscribing(false);
			pauseTimer();
			await stopRecording();
			socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
		} else {
			stoppedTranscribing.current = false;
			setTranscribing(true);
			await startRecording();
			startTimer();
		}
		setTranscribing(!isTranscribing);
	};

	const handleInputLanguageClick = (language) => {
		selectLanguage(language);
		inputLanguage.current = language;
		setInputLanguageDisplay(language);
		setLanguageDropdownVisible(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				linkAndQRCodeRef.current &&
				!linkAndQRCodeRef.current.contains(event.target)
			) {
				setShowLinkQRCode(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		joinRoomAsync();
		return () => {
			socket.off('load_messages');
			socket.off('receive_transcriptions');
		};
	}, []);

	useEffect(() => {
		return () => {
			stoppedTranscribing.current = true;
			socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
			const expiryDateEpochSeconds =
				Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later in epoch seconds
			socket.emit('save_audio', {
				jwt_token: jwtTokenRef.current,
				clear_audio: true,
				expiry_date_epoch_seconds: expiryDateEpochSeconds,
			});
			pauseTimer();
			if (messagesRef.current.length > 0) {
				var lastMessage = messagesRef.current[messagesRef.current.length - 1];
				lastMessage['is_final'] = true;
				setMessages([...messagesRef.current]);
				updateMessagesMerged(messagesRef.current);
			}
		};
	}, [navigate]);

	useEffect(() => {
		const messagesContainer = messagesContainerRef.current;
		let lastScrollTop = messagesContainer.scrollTop;

		const handleScroll = () => {
			const currentScrollTop = messagesContainer.scrollTop;
			if (currentScrollTop < lastScrollTop) {
				// User is scrolling up
				shouldScroll.current = false;
			}
			lastScrollTop = currentScrollTop;
		};

		if (messagesContainer) {
			messagesContainer.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (messagesContainer) {
				messagesContainer.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	useEffect(() => {
		if (shouldScroll.current) {
			scrollToBottom();
		}
	}, [messagesMerged]);

	useEffect(() => {
		let interval;
		if (timerRunning) {
			interval = setInterval(() => {
				const now = new Date().getTime();
				const elapsed = now - startTime;
				// console.log('elapsed: ' + formatTime(elapsed))
				setElapsedTime(elapsed);
				// console.log('elapsedTime: ' + elapsedTime)
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timerRunning, startTime]);

	useEffect(() => {
		// click outside the language picker popup to close it
		const handleClickOutside = (event) => {
			if (usingInputLanguagePopupRef.current) {
				if (
					inputLanguageRef.current &&
					!inputLanguageRef.current.contains(event.target)
				) {
					if (
						inputLanguageDropdownRef.current &&
						!inputLanguageDropdownRef.current.contains(event.target)
					) {
						usingInputLanguagePopupRef.current = false;
						setLanguageDropdownVisible(false);
					}
				}
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="flex bg-whitesmoke w-full h-full">
			{showLinkQRCode && (
				<div className="fixed inset-0 z-50 flex items-center justify-center bg-black/20">
					<RoomLinkAndQRCode
						link={'https://interpretapp.ai/broadcast/' + roomId}
						onOutsideClickFunc={() => setShowLinkQRCode(false)}
						divRef={linkAndQRCodeRef}
						isWeb={true}
					/>
				</div>
			)}
			<div
				className="h-screen w-28 shadow-md flex flex-col gap-6 items-end pt-12 pr-8"
				style={{
					background:
						'linear-gradient(103deg, rgba(0, 126, 243, 0.10) 0.03%, rgba(121, 119, 209, 0.10) 99.97%), var(--white, #FFF)',
				}}
			>
				<img src="/logo.svg" alt="logo" className="w-8 h-8 mb-16" />
				<button
					onClick={() => {
						stoppedTranscribing.current = true;
						pauseTimer();
						navigate(-1);
					}}
				>
					<img
						src="/broadcastback.svg"
						alt="broadcastback"
						className="w-8 h-8"
					/>
				</button>
				<button onClick={() => setShowLinkQRCode(true)}>
					<img
						src="/broadcastshare.svg"
						alt="broadcastback"
						className="w-8 h-8"
					/>
				</button>
			</div>
			<div className="flex flex-col gap-6 justify-center w-full h-screen px-14">
				<div className="flex justify-between items-center relative">
					<div className="font-montserrat w-full mr-6">
						Room Created {formattedDate}
					</div>
					<div
						ref={inputLanguageRef}
						className="w-fit rounded-34xl h-fit flex py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer"
						onClick={() => {
							setLanguageDropdownVisible(!languageDropdownVisible);
							usingInputLanguagePopupRef.current =
								!usingInputLanguagePopupRef.current;
						}}
					>
						<div className="flex flex-col py-1.5 px-2.5">
							<div className="flex   gap-[10px] cursor-pointer">
								<b className="relative">{inputLanguageDisplay}</b>
								<img
									className="w-5 relative h-5"
									alt=""
									src="/select-more-language.svg"
								/>
							</div>
						</div>
					</div>
					{languageDropdownVisible && (
						<div
							ref={inputLanguageDropdownRef}
							className="grid grid-cols-4 gap-8 px-10 bg-white border absolute top-12 right-0 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-96 overflow-y-auto w-full p-4"
						>
							<div className="col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300">
								<input
									type="text"
									placeholder="Search..."
									className="w-full p-1 border-none outline-none text-lg font-montserrat"
									value={searchBarInput}
									onChange={(e) => setSearchBarInput(e.target.value)}
								/>
								<img
									className="w-5 h-5 ml-2"
									alt=""
									src="/general--search.svg"
								/>
							</div>
							{top100WorldLanguages
								.filter((language) =>
									language
										.toLowerCase()
										.includes(searchBarInput?.toLowerCase() || '')
								)
								.map((language) => (
									<div
										key={language}
										className=" hover:bg-gray-200 cursor-pointer w-full h-fit"
										onClick={() => handleInputLanguageClick(language)}
									>
										{language}
									</div>
								))}
						</div>
					)}
				</div>
				<div
					className="flex relative flex-col w-full text-xs text-updated-color-new-black font-montserrat h-2/3 bg-white rounded-lg border-solid border-px border-updated-color-grey1"
					ref={messagesContainerRef}
					onTransitionEnd={() => {
						if (shouldScroll.current) {
							// check if at the bottom
							const messagesContainer = messagesContainerRef.current;
							if (messagesContainer) {
								const { scrollTop, clientHeight, scrollHeight } =
									messagesContainer;
								const atBottom =
									Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
								if (atBottom) {
									shouldScroll.current = false;
								}
							}
						}
					}}
				>
					<div className="flex flex-col w-fit gap-2.5 text-left p-6 pb-10 overflow-y-auto">
						{messagesMerged.map((message, index) => (
							<div key={index}>
								<div className="text-[12px]">
									{(message.corrected_transcripts[inputLanguage.current] ||
										message.transcript) && (
										<div className="text-gray-500 mb-1">
											{message.timestamp_display}
										</div>
									)}
								</div>
								<div>
									{inputLanguage.current === 'Original' ||
									inputLanguage.current === 'Detect Language'
										? message.transcript
										: message.corrected_transcripts[inputLanguage.current]}
								</div>
							</div>
						))}
					</div>
					<div
						className={`w-full flex justify-end absolute z-10 bottom-28 ${messagesMerged.length && 'py-2'} ${shouldShowScrollButton && 'pb-0'} pr-1`}
					>
						<div className="flex w-[54.3%] justify-between">
							<div>
								{shouldShowScrollButton && (
									<img
										className="w-[34px] h-[34px] overflow-hidden"
										loading="lazy"
										alt=""
										src="/arrow--arrow-down-6-circle.svg"
										onClick={() => {
											scrollToBottom();
										}}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="absolute bg-white bottom-0 flex flex-col w-full pt-2 gap-[10px] rounded-lg">
						<div className="flex justify-center mx-auto items-center w-11/12 gap-2">
							<hr className="border-dashed w-full border h-px border-b-0 border-x-0" />
							<div className="text-xs font-medium font-montserrat text-updated-color-new-black text-center">
								{formatTime(elapsedTime)}
							</div>
							<hr className="border-dashed w-full border h-px border-b-0 border-x-0" />
						</div>
						<div className="flex gap-4 items-center justify-center pb-4">
							{messagesMerged.length > 0 && (
								<div
									className={`cursor-pointer shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-full bg-white flex items-center justify-center h-fit py-3 px-[20px] border-[0.5px] border-solid border-updated-color-grey1`}
									onClick={() => {
										setShowPopup(true);
									}}
								>
									Save
								</div>
							)}
							{!isTranscribing ? (
								<div
									className={`cursor-pointer shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex  items-center justify-center py-1 px-[32px] border-[0.5px] border-solid border-updated-color-grey1`}
									onClick={() => handleTranscribeButton()}
								>
									<img
										className="h-7 w-7 relative overflow-hidden shrink-0"
										loading="lazy"
										alt=""
										src="/media--player---play.svg"
									/>
								</div>
							) : (
								<div
									className={`shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-updated-color-red flex  items-center justify-center py-1 px-[32px] border-[0.5px] border-solid border-updated-color-grey1`}
									onClick={() => handleTranscribeButton()}
								>
									<img
										className="h-7 w-7 relative overflow-hidden shrink-0 mx-auto"
										loading="lazy"
										alt=""
										src="/media--player---pause.svg"
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{showPopup && (
				<SavePopup
					stoppedTranscribing={stoppedTranscribing}
					socket={socket}
					handleTranscribeButton={handleTranscribeButton}
					jwtTokenRef={jwtTokenRef}
					setShowPopup={setShowPopup}
					isTranscribing={isTranscribing}
				/>
			)}
		</div>
	);
};
