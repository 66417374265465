import React, {
	useCallback,
	useState,
	useEffect,
	useRef,
	useContext,
} from 'react';
import RoomSelectLanguageOverlay from '../../components/mobile/RoomSelectLanguageOverlay';
import { useLocation } from 'react-router-dom';
import { socket } from '../../socketContext';
import { useNavigate } from 'react-router-dom';

const GuestView = () => {
	const navigate = useNavigate();
	const onArrowArrowLeft6CircleClick = useCallback(() => {
		// Placeholder for functionality
	}, []);

	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const [messages, setMessages] = useState([]);
	const [messagesMerged, setMessagesMerged] = useState([]);
	const messagesRef = useRef(messages);
	const messagesContainerRef = useRef(null);
	const shouldScroll = useRef(false);
	const [language, setLanguage] = useState('');

	const location = useLocation();

	const updateMessagesMerged = (messagesUpdated) => {
		var messagesMergedUpdated = [];
		for (var i = 0; i < messagesUpdated.length; i++) {
			if (messagesUpdated[i]['transcript'] != '') {
				const currentTimestamp = messagesUpdated[i]['timestamp'];
				const lastMergedMessage =
					messagesMergedUpdated[messagesMergedUpdated.length - 1];

				if (
					i > 0 &&
					!messagesUpdated[i]['did_speaker_change'] &&
					!messagesUpdated[i]['is_ai_answer'] &&
					!messagesUpdated[i - 1]['is_ai_answer'] &&
					messagesUpdated[i - 1]['speaker'] === messagesUpdated[i]['speaker'] &&
					messagesMergedUpdated.length > 0 &&
					lastMergedMessage['messages_merged'] < 7 &&
					currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
				) {

					lastMergedMessage['transcript'] =
						lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

					lastMergedMessage['messages_merged']++;

					for (var key in messagesUpdated[i]['corrected_transcripts']) {
						if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
							lastMergedMessage['corrected_transcripts'][key] = '';
						}
						lastMergedMessage['corrected_transcripts'][key] =
							lastMergedMessage['corrected_transcripts'][key] +
							' ' +
							messagesUpdated[i]['corrected_transcripts'][key];
					}
				} else {
					messagesUpdated[i]['messages_merged'] = 1;
					const timestamp = new Date(currentTimestamp * 1000);
					messagesUpdated[i]['timestamp_display'] =
						timestamp.toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
						});
					messagesMergedUpdated.push(messagesUpdated[i]);
				}
			}
		}
		console.log(messagesMergedUpdated);
		setMessagesMerged(messagesMergedUpdated);
	};

	const joinRoomAsync = async () => {
		var roomId = location.pathname.split('/')[2];
		console.log('joining room ' + location.pathname.split('/')[2]);
		socket.emit('join_room', roomId);
		socket.on('load_messages', async (data) => {
			console.log(JSON.stringify(data));
			var messagesUpdated = [];
			for (var i = 0; i < data.length; i++) {
				var message = data[i];
				var messageToAdd = {
					transcript_id: message['transcript_id'],
					transcript: message['transcript'],
					corrected_transcripts: message['corrected_transcripts'],
					is_final: message['is_final'],
					diarization: message['diarization'],
					timestamp: message['timestamp'],
				};
				messagesUpdated.push(messageToAdd);
			}
			setMessages(messagesUpdated);
			messagesRef.current = messagesUpdated;
			updateMessagesMerged(messagesUpdated);
			shouldScroll.current = true;
		});

		socket.on('receive_transcriptions', async (data) => {
			console.log('received transcriptions ' + JSON.stringify(data));
			let messagesToAdd = [];
			let oldMessages = [...messagesRef.current];
			let message = data;

			var messageToAdd = {
				transcript_id: message['transcript_id'],
				transcript: message['transcript'],
				corrected_transcripts: message['corrected_transcripts'],
				is_final: message['is_final'],
				did_speaker_change: message['did_speaker_change'],
				timestamp: message['timestamp'],
				is_ai_answer: message['is_ai_answer'],
				speaker: message['speaker'],
			};

			var replacedMessage = false;
			for (var i = 0; i < oldMessages.length; i++) {
				if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
					oldMessages[i] = messageToAdd;
					replacedMessage = true;
					messagesToAdd = [];
				}
			}

			if (!replacedMessage) {
				if (
					oldMessages.length > 0 &&
					!oldMessages[oldMessages.length - 1]['is_final']
				) {
					oldMessages.pop();
				}
				messagesToAdd = [message];
			}

			const messagesContainer = messagesContainerRef.current;
			if (messagesContainer) {
				const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
				console.log(scrollTop, clientHeight, scrollHeight);
				console.log('dist: ', scrollHeight - clientHeight - scrollTop);
				const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
				if (atBottom) {
					shouldScroll.current = true;
				}
			}
			setMessages([...oldMessages, ...messagesToAdd]);
			var messagesUpdated = [];
			for (var i = 0; i < oldMessages.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(oldMessages[i])),
				];
			}
			for (var i = 0; i < messagesToAdd.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(messagesToAdd[i])),
				];
			}
			console.log('messages updated: ' + JSON.stringify(messagesUpdated));

			updateMessagesMerged(messagesUpdated);

			messagesRef.current = [...oldMessages, ...messagesToAdd];
		});
	};

	useEffect(() => {
		joinRoomAsync();
		return () => {
			socket.off('load_messages');
			socket.off('receive_transcriptions');
		};
	}, []);

	useEffect(() => {
		const messagesContainer = messagesContainerRef.current;
		let lastScrollTop = messagesContainer.scrollTop;

		const handleScroll = () => {
			const currentScrollTop = messagesContainer.scrollTop;
			if (currentScrollTop < lastScrollTop) {
				// User is scrolling up
				shouldScroll.current = false;
			}
			lastScrollTop = currentScrollTop;
		};

		if (messagesContainer) {
			messagesContainer.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (messagesContainer) {
				messagesContainer.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	const scrollToBottom = () => {
		messagesContainerRef.current?.scrollTo({
			top: Math.ceil(messagesContainerRef.current.scrollHeight),
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (shouldScroll.current) {
			scrollToBottom();
		}
	}, [messagesMerged]);

	const selectLanguage = (language) => {
		setIsOverlayOpen(false);
		setLanguage(language);
		socket.emit('set_broadcast_language', language);
	};

	return (
		<div className="w-full h-full relative">
			<div className="w-full h-full overflow-hidden flex flex-col items-start justify-start pt-14 sm:pt-[70px] px-2.5 sm:px-5 pb-20 sm:pb-[100px] box-border text-center text-xs text-updated-color-new-black font-montserrat">
				<div className="flex flex-row items-center w-full">
					<img
						className="h-10 w-10 cursor-pointer"
						loading="lazy"
						alt=""
						src="/arrow--arrow-left-6-circle.svg"
						onClick={() => {
							navigate('/');
						}}
					/>
					{/* <div className="font-medium font-montserrat w-full mr-6">
						Room Created 03.23.2024, 13:38 PM
					</div> */}
				</div>
				<div className="flex flex-row items-start justify-center w-full mt-4">
					<button
						className="cursor-pointer border-none py-1 px-2 bg-white rounded-xl shadow flex items-start justify-start gap-2"
						onClick={() => {
							setIsOverlayOpen(true);
							console.log('Select Language');
						}}
					>
						{/* <img className="w-6 h-6 object-cover" alt="" src="/group-1707478106@2x.png" /> */}
						<div className="flex flex-col items-start justify-start pt-[1.3px]">
							<div className="text-xs font-montserrat text-updated-color-new-black">
								{language != '' ? language : 'Select Language'}
							</div>
						</div>
					</button>
				</div>
				<img
					className="w-full h-px absolute bottom-[111px] left-0 max-w-full"
					loading="lazy"
					alt=""
					src="/vector-266.svg"
				/>
				<section
					className="flex flex-col overflow-y-auto items-start justify-start w-full pt-0 px-0 pb-2 box-border text-xs text-updated-color-new-black font-montserrat mt-4"
					ref={messagesContainerRef}
					onTransitionEnd={() => {
						if (shouldScroll.current) {
							// check if at the bottom
							const messagesContainer = messagesContainerRef.current;
							if (messagesContainer) {
								const { scrollTop, clientHeight, scrollHeight } =
									messagesContainer;
								const atBottom =
									Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
								if (atBottom) {
									shouldScroll.current = false;
								}
							}
						}
					}}
				>
					<div className="flex flex-col items-start justify-start gap-2.5">
						{messagesMerged.map((message, index) => (
							<div
								key={index}
								className="flex flex-col items-start justify-start text-[13px]"
							>
								<div className="text-gray-500 mb-1">
									{message.timestamp_display}
								</div>
								{message.speaker && (
									<strong style={{ fontSize: '18px' }}>
										{message.speaker}:
									</strong>
								)}{' '}
								{/* Check if speaker exists and display it */}
								{language != '' && message.corrected_transcripts[language] ? (
									<div className="rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_rgba(255,_255,_255,_0.2)] flex flex-row items-center justify-center py-4 px-[22px] box-border max-w-full mt-4">
										<p style={{ fontSize: '2em' }}>
											{message.corrected_transcripts[language]}
										</p>
									</div>
								) : (
									<div className="rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_rgba(255,_255,_255,_0.2)] flex flex-row items-center justify-center py-4 px-[22px] box-border max-w-full">
										<p style={{ fontSize: '2em' }}>
											{message.transcript}
										</p>
									</div>
								)}
							</div>
						))}
					</div>
				</section>
			</div>
			{isOverlayOpen && (
				<div className="absolute top-0 left-0 w-full h-full">
					<RoomSelectLanguageOverlay
						onClose={() => setIsOverlayOpen(false)}
						onSelect={selectLanguage}
					/>
				</div>
			)}
		</div>
	);
};

export default GuestView;
