import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtTokenRef, serverURL } from '../../httpContext';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { fileTranslateLanguages } from '../../languages';
import { LoadingOutlined } from '@ant-design/icons';

const LanguageSelectDropdown = ({
	isLanguageSelectOpen,
	setIsLanguageSelectOpen,
	fileTranslateLanguages,
	setOutputLanguage,
}) => {
	const [searchBarInput, setSearchBarInput] = useState('');
	const [newOutputLanguage, setNewOutputLanguage] = useState('');

	return (
		isLanguageSelectOpen && (
			<div className="fixed inset-0 bg-black/20 flex items-center justify-center z-20 font-montserrat">
				<div className="flex flex-col items-center gap-10 bg-white rounded-xl p-10 w-1/2">
					<div className="self-start text-lg">Select Language</div>
					<div className="grid grid-cols-4 bg-white border-solid border-gray-300 rounded-md shadow-lg max-h-64 overflow-y-auto min-w-full p-4">
						<div className="col-span-4 flex items-center p-2 border-b border-solid border-gray-300">
							<input
								type="text"
								placeholder="Search..."
								className="w-full p-1 border-none outline-none text-lg"
								value={searchBarInput}
								onChange={(e) => setSearchBarInput(e.target.value)}
							/>
							<img className="w-5 h-5 ml-2" alt="" src="/general--search.svg" />
						</div>
						{fileTranslateLanguages
							.filter((language) =>
								language.toLowerCase().includes(searchBarInput.toLowerCase())
							)
							.map((language) => (
								<div
									key={language}
									className={`p-2 hover:bg-black/20 rounded-full cursor-pointer h-fit w-fit ${newOutputLanguage === language ? 'bg-dodgerblue-200 text-white' : ''}`}
									onClick={() => {
										console.log('setting new output language to', language);
										setNewOutputLanguage(language);
									}}
								>
									{language}
								</div>
							))}
					</div>
					<div className="flex self-end gap-5 text-base">
						<div
							className="rounded-3xs flex items-center justify-center py-1 px-4 border border-solid border-gray-300 cursor-pointer"
							onClick={() => setIsLanguageSelectOpen(false)}
						>
							<div className="relative leading-5 font-medium">Cancel</div>
						</div>
						<div
							className="rounded-3xs bg-gradient-to-r from-blue-500 to-blue-300 flex items-center justify-center py-1 px-4 border border-solid border-gray-300 cursor-pointer"
							onClick={() => {
								console.log('setting output language to', newOutputLanguage);
								setOutputLanguage(newOutputLanguage);
								setIsLanguageSelectOpen(false);
							}}
						>
							<div className="relative leading-5 font-medium">Done</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

const FileTranslate = () => {
	const navigate = useNavigate();

	const [error, setError] = useState('');
	const [isTranscribing, setIsTranscribing] = useState(false);
	const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false);
	const [uploadedFileName, setUploadedFileName] = useState('');
	const [searchBarInput, setSearchBarInput] = useState('');
	const [outputLanguage, setOutputLanguage] = useState('');
	const [newOutputLanguage, setNewOutputLanguage] = useState('');

	const fileInputRef = useRef(null);

	const uploadFile = async (file) => {
		let endpoint = `/translate_file_async?output_language=${outputLanguage}&regenerate_pdf=false`;
		// const compressedUrl = URL.createObjectURL(file);
		const formData = new FormData();
		formData.append('file', file, file.name);
		try {
			setUploadedFileName('');
			setIsTranscribing(true);
			const response = await axios.post(serverURL + endpoint, formData, {
				headers: {
					Authorization: 'Bearer ' + jwtTokenRef.current,
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response.status !== 200) {
				console.error('Error occurred during file upload.');
				setError('Error occurred during file upload.');
				setIsTranscribing(false);
				return;
			} else {
				const data = response.data;
				console.log(data);
				let transcriptId = data.transcript_id;
				setError('');
				navigate('/file_transcript?id=' + transcriptId);
			}
		} catch (error) {
			setIsTranscribing(false);
			setUploadedFileName('');
			console.error('Error occurred during file upload.', error);
			setError('Error occurred during file upload.');
		}
	};

	const changeUploadedFileEventLister = () => {
		if (fileInputRef.current.files.length > 0) {
			const fileName = fileInputRef.current.files[0].name;
			const shortenedFileName =
				fileName.length > 20
					? fileName.substring(0, 20) + '...' + fileName.split('.').pop()
					: fileName;
			setUploadedFileName(shortenedFileName);
		}
	};

	const addEventListenerToFile = () => {
		const fileInput = fileInputRef.current;
		if (fileInput) {
			fileInput.addEventListener('change', changeUploadedFileEventLister);
		}

		return () => {
			if (fileInput) {
				fileInput.removeEventListener('change', changeUploadedFileEventLister);
			}
		};
	};

	const addBotToMeeting = async (meetingUrl) => {
		if (jwtTokenRef.current == undefined) {
			return;
		}

		const response = await axios.post(
			`${serverURL}/add_bot_to_zoom`,
			{ meeting_url: meetingUrl },
			{
				headers: {
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			}
		);
		if (response.status === 200) {
			console.log('Bot added to meeting');
		} else {
			console.error('Failed to add bot to meeting');
		}
		meetingUrl = extractMeetingId(meetingUrl);
		if (meetingUrl) {
			navigate(`/broadcast/${meetingUrl}`);
		}
	};

	useEffect(() => {
		addEventListenerToFile();
	}, []);

	return (
		<div className="relative">
			<LanguageSelectDropdown
				isLanguageSelectOpen={isLanguageSelectOpen}
				setIsLanguageSelectOpen={setIsLanguageSelectOpen}
				fileTranslateLanguages={fileTranslateLanguages}
				setOutputLanguage={setOutputLanguage}
			/>
			<div className="bg-white h-full text-left text-xl text-updated-color-new-black font-montserrat flex flex-col">
				<ProfileDropdown />
				{isTranscribing && (
					<div className="flex flex-col items-center justify-center gap-[20px] text-center text-[28px] h-full py-36">
						<LoadingOutlined
							className="text-[#007EF3] mb-6"
							style={{ fontSize: '60px' }}
						/>
						<b className="relative leading-[22px]">Translating...</b>
						<div className="relative text-lg leading-[22px] font-semibold">
							Translating your file
						</div>
					</div>
				)}
				{!isTranscribing && (
					<div className="flex items-center justify-between h-full px-20 py-10">
						<div className="gap-4 [filter:drop-shadow(0px_3px_5.5px_rgba(140,_140,_140,_0.25))] rounded-xl flex flex-col items-center justify-center py-[60px] px-40 text-[24px] border-[1px] border-solid border-updated-color-grey1">
							<div className="flex flex-col items-center justify-start gap-[25px] mb-4">
								<img className="w-fit h-fit" alt="" src="/file-translate.svg" />
								<div className="flex flex-col items-center justify-start gap-[10px]">
									<div className="relative leading-[22px] font-semibold">
										Translate Document
									</div>
									<div className="w-[420px] relative text-base leading-[22px] text-center inline-block">
										All document types are supported.
										<br />
										<br /> .PDF .DOCX .XLSX .PPTX .EPUB .PO .SRT .TXT .HTML .ZIP
										.XML .XLF .GO .JPG .JPEG .PNG .MD .ODT .ODS .ODP .CSV .TSV
										.YAML .PHP .AI
									</div>
								</div>
							</div>
							<div className="flex text-center text-sm cursor-pointer relative">
								<input
									type="file"
									accept=".pdf, .docx, .xlsx, .pptx, .epub, .po, .srt, .txt, .html, .zip, .xml, .xlf, .go, .jpg, .jpeg, .png, .md, .odt, .ods, .odp, .csv, .tsv, .yaml, .php, .ai"
									ref={fileInputRef}
									className="absolute opacity-0 w-full h-full z-10 cursor-pointer"
								/>
								<div className="flex flex-row items-center justify-center">
									<div className="rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1">
										<div className="relative leading-[22px]">
											{uploadedFileName ? uploadedFileName : 'Select a file'}
										</div>
									</div>
									<div className="rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1">
										<img
											className="w-[18px] relative h-[18px] overflow-hidden shrink-0"
											alt=""
											src="/arrow--arrow-down-2.svg"
										/>
									</div>
								</div>
							</div>
							<div
								className="flex text-center text-sm cursor-pointer relative"
								onClick={() => {
									setIsLanguageSelectOpen(true);
								}}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1">
										<div className="relative leading-[22px]">
											{outputLanguage || 'Select a language'}
										</div>
									</div>
									<div className="rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1">
										<img
											className="w-[18px] relative h-[18px] overflow-hidden shrink-0"
											alt=""
											src="/arrow--arrow-down-2.svg"
										/>
									</div>
								</div>
							</div>
							<div
								className="rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_#fff] flex items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer"
								onClick={() => {
									uploadFile(fileInputRef.current.files[0]);
								}}
							>
								<div className="relative font-medium text-base">Translate</div>
							</div>
						</div>
						<div className="flex flex-col gap-10 w-1/4 self-start">
							<div className="flex items-center w-full rounded-full">
								<svg
									width="16"
									height="17"
									viewBox="0 0 16 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="-mr-6"
								>
									<path
										d="M2 3.83337H11.3333V11.8334C11.3333 12.5698 10.7364 13.1667 10 13.1667H3.33333C2.59695 13.1667 2 12.5698 2 11.8334V3.83337Z"
										stroke="#007EF3"
										stroke-width="1.67"
										stroke-linejoin="round"
									/>
									<path
										d="M11.3335 6.50004L13.1718 6.04046C13.5926 5.93527 14.0002 6.25351 14.0002 6.68722V10.3129C14.0002 10.7466 13.5926 11.0648 13.1718 10.9596L11.3335 10.5V6.50004Z"
										stroke="#007EF3"
										stroke-width="1.67"
										stroke-linejoin="round"
									/>
								</svg>
								<input
									placeholder="Paste meeting URL to get caption"
									className="px-4 pl-6 py-[5px] w-full border-solid rounded-full border-[.5px] focus:outline-none  bg-transparent"
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											addBotToMeeting(e.target.value);
										}
									}}
								/>
							</div>
							<RecentsSidebar />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FileTranslate;
