import { useCallback, useState, useEffect } from 'react';
import { FrameComponent2 } from '../../components/mobile/FrameComponent2';
import { useNavigate } from 'react-router-dom';
import {
	GoogleAuthProvider,
	onAuthStateChanged,
	signInWithPopup,
	getAuth,
} from 'firebase/auth';
// Import the functions you need from the SDKs you need
import axios from 'axios';
import {
	jwtTokenRef,
	serverURL,
	updateAccountInfo,
	userInfoRef,
} from '../../httpContext';

const RegisterMobile = ({ setIsLoggedIn, setShowMobile }) => {
	const navigate = useNavigate();
	const [error, setError] = useState('');

	const onAlreadyHaveAnClick = useCallback(() => {
		navigate('/login');
	}, [navigate]);

	const auth = getAuth();

	const provider = new GoogleAuthProvider();
	provider.setCustomParameters({ prompt: 'select_account' });

	async function signInWithGoogle() {
		const result = await signInWithPopup(auth, provider);
		console.log('result: ', result);
		checkUserAndLogin(result.user);
	}

	useEffect(() => {
		// TODO: Add SDKs for Firebase products that you want to use

		setShowMobile(true);

		const checkUserAndLogin = async (user) => {
			if (user) {
				try {
					const token = await user.getIdToken();
					const response = await axios.post(`${serverURL}/login-with-google`, {
						token: token,
					});
					jwtTokenRef.current = response.data.access_token;
					localStorage.setItem('jwtToken', jwtTokenRef.current);
					await updateAccountInfo(jwtTokenRef, userInfoRef);

					setIsLoggedIn(true);
					navigate('/');
				} catch (error) {
					console.error('Error handling authentication: ' + error);
					setError('Authentication failed. Please try again.');
				}
			}
		};

		onAuthStateChanged(auth, (user) => {
			if (user) {
				checkUserAndLogin(user);
			}
		});
	}, []);

	return (
		<div className="h-full flex flex-col items-center pt-16 pb-4 px-4 sm:px-6 lg:px-8 tracking-[normal] text-center text-[0.75rem] text-dodgerblue-200 font-montserrat overflow-y-scroll">
			<div className="w-full max-w-md flex flex-col items-center gap-6 sm:gap-8 text-left text-[0.688rem] text-gray-100 font-poppins">
				<div className="w-full flex flex-row items-center">
					<img
						className="h-8 w-8 sm:h-10 sm:w-10 relative overflow-hidden shrink-0 cursor-pointer"
						loading="lazy"
						alt=""
						src="/mobileback.svg"
						onClick={() => {
							navigate('/login');
						}}
					/>
				</div>
				<FrameComponent2 error={error} setError={setError} />
				<div className="w-full flex flex-col items-center justify-start gap-6 sm:gap-8">
					<div className="w-full flex flex-row items-center justify-center">
						<div className="w-full flex flex-row items-center justify-center gap-2 sm:gap-4">
							<div className="flex w-full justify-end">
								<div className="w-1/2 h-[1px] bg-updated-color-grey2" />
							</div>
							<div className="flex items-center justify-center">
								<span className="text-updated-color-grey2 font-medium text-lg">
									OR
								</span>
							</div>
							<div className="w-full flex">
								<div className="w-1/2 h-[1px] bg-updated-color-grey2" />
							</div>
						</div>
					</div>
					<form className=" flex flex-col items-center justify-start gap-5">
						<div
							className="w-full rounded-lg bg-updated-color-grey1 flex items-center py-3 px-5 gap-12"
							onClick={() => {
								signInWithGoogle();
							}}
						>
							<img
								className="h-8 w-8"
								alt="Sign up with Google"
								src="/google@2x.png"
							/>
							<span className="text-lg font-medium font-montserrat text-updated-color-grey2">
								Sign Up with Google
							</span>
						</div>
						{/* <div className='w-full rounded bg-gainsboro flex flex-row items-center justify-center py-3 px-5 gap-3'>
			<img className='h-8 w-8' alt='Sign up with Facebook' src='/facebook.svg' />
			<span className='text-lg font-medium font-montserrat text-gray-100'>
				Sign Up with Facebook
			</span>
			</div> */}
					</form>
				</div>
			</div>
			<div className="mt-8 flex flex-row items-center justify-center w-full">
				<div
					className="text-sm sm:text-base font-medium cursor-pointer"
					onClick={onAlreadyHaveAnClick}
				>
					<span className="text-updated-color-grey2">
						Already have an account?
					</span>{' '}
					<span className="text-dodgerblue-200 underline">Log in here</span>
				</div>
			</div>
		</div>
	);
};

export default RegisterMobile;
