import { useState, useEffect, useRef } from 'react';
import { MobileAISummary } from './MobileAISummary';
import { serverURL, jwtTokenRef } from '../../httpContext';
import { socket } from '../../socketContext';
import ReactMarkdown from 'react-markdown';
import SelectSummaryType from './SelectSummaryType';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { useWhisper } from '@chengsokdara/use-whisper';

const CustomizeSummaryWtEdit = ({
	handleTranscribeButton,
	setMessagesMerged,
	messagesMerged,
	setShowCustomSummaryInput,
	setShowSelectSummaryType,
	setSummary,
	setShowSummary,
	isTranscribing,
	fileInUse,
}) => {
	const inputTextRef = useRef(messagesMerged);

	useEffect(() => {
		return () => {
			setMessagesMerged('');

			if (isTranscribing) {
				socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
				handleTranscribeButton();
			}
		};
	}, [isTranscribing]);

	const handleInputChange = (e) => {
		const newValue = e.target.value;
		setMessagesMerged(newValue);
	};

	return (
		<div
			className={`flex justify-center bg-black/30 h-full text-base text-updated-color-grey1 font-montserrat`}
			onClick={(e) => e.stopPropagation()}
		>
			<div className="flex relative flex-col rounded-t-xl bg-white w-full overflow-y-auto mt-24">
				<button
					className="absolute bottom-20 right-5"
					onClick={() => {
						handleTranscribeButton();
					}}
				>
					<img src="/mic.svg" alt="microphone" />
				</button>
				<div className="flex justify-between items-center px-6 py-4">
					<div
						className="text-updated-color-blue cursor-pointer"
						onClick={() => {
							if (isTranscribing) {
								handleTranscribeButton();
							}
							setShowCustomSummaryInput(false);
							setShowSelectSummaryType(true);
						}}
					>
						Cancel
					</div>
					<div
						className={`${messagesMerged ? 'text-updated-color-blue' : 'text-unselected-text'} cursor-pointer`}
						onClick={(e) => {
							e.stopPropagation();

							if (isTranscribing) {
								handleTranscribeButton();
							}

							if (messagesMerged === '') {
								return;
							}
							setSummary('Generating response...');
							setShowSummary(true);
							axios
								.post(serverURL + '/summarize_transcript_custom_prompt', null, {
									params: {
										transcript_id: fileInUse.id,
										custom_prompt: messagesMerged,
									},
									headers: {
										'Content-Type': 'application/json',
										Authorization: `Bearer ${jwtTokenRef.current}`,
									},
								})
								.then((response) => {
									if (response.status === 200) {
										return response.data;
									}
									throw new Error('Network response was not ok.');
								})
								.then((data) => {
									setSummary(data.output);
								})
								.catch((error) => {
									console.error('Error generating summary:', error);
								});
							setShowCustomSummaryInput(false);
						}}
					>
						Generate
					</div>
				</div>
				<div className="flex items-start px-6 py-4">
					<div className="relative w-full">
						<textarea
							className="w-full text-sm leading-relaxed font-light resize-none outline-none text-black border-none"
							rows={20}
							placeholder={
								'Example: summarize the features of the product mentioned in bullet point format.'
							}
							value={messagesMerged}
							onChange={handleInputChange}
							style={{
								color: 'black',
								'::placeholder': { color: 'lightgray' },
							}}
							onClick={(e) => {
								e.stopPropagation();
							}}
						></textarea>
					</div>
				</div>
			</div>
		</div>
	);
};

const fetchTranscript = async (transcript_id) => {
	try {
		const response = await axios.get(
			`${serverURL}/get_transcript?transcript_id=${transcript_id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error fetching transcript:', error);
		return null;
	}
};

export const MobileFileRender = () => {
	const location = useLocation();
	const transcript_id = new URLSearchParams(location.search).get('id');

	const raw_transcript = useRef(null);
	const audioRef = useRef(null);
	const [summary, setSummary] = useState('');
	const [showSummary, setShowSummary] = useState(false);
	const [newContent, setNewContent] = useState('');
	const [newText, setNewText] = useState('');
	const [showSelectSummaryType, setShowSelectSummaryType] = useState(false);
	const [showCustomSummaryInput, setShowCustomSummaryInput] = useState(false);
	const [isLoadingTTS, setIsLoadingTTS] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [fileInUse, setFileInUse] = useState(null);
	const [canEdit, setCanEdit] = useState(false);
	const [keyboardVisible, setKeyboardVisible] = useState(false);
	const [copiedText, setCopiedText] = useState(false);
	const [isTranscribing, setTranscribing] = useState(false);
	const [messagesMerged, setMessagesMerged] = useState();
	const [messages, setMessages] = useState([]);
	const stoppedTranscribing = useRef(false);
	const messagesRef = useRef(messages);

	const navigate = useNavigate();

	useEffect(() => {
		let isMounted = true;

		const fetchAndSetFile = async () => {
			let data;
			do {
				data = await fetchTranscript(transcript_id);
				if (data && isMounted) {
					setFileInUse(data);
					raw_transcript.current = JSON.parse(data.raw_transcript);
					if (data.transcript_type === 'image') {
						setNewContent(raw_transcript.current.transcription);
					} else {
						setNewContent(data.transcript);
					}
				}
				await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
			} while (
				isMounted &&
				(!data || (data && raw_transcript.current.status === 'transcribing'))
			);
		};

		fetchAndSetFile();

		return () => {
			isMounted = false;
		};
	}, [transcript_id]);

	useEffect(() => {
		return () => {
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current = null;
			}
		};
	}, []);

	useEffect(() => {
		const handleResize = () => {
			const keyboardHeight =
				window.innerHeight -
				document.activeElement.getBoundingClientRect().bottom;
			setKeyboardVisible(keyboardHeight > 0);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		socket.removeAllListeners();
		console.log('adding listener for receive_transcriptions');

		socket.on('receive_transcriptions', async (data) => {
			console.log('received transcriptions ' + JSON.stringify(data));
			let messagesToAdd = [];
			let oldMessages = [...messagesRef.current];
			let message = data;

			let messageToAdd = {
				transcript_id: message['transcript_id'],
				transcript: message['transcript'],
				corrected_transcripts: message['corrected_transcripts'],
				is_final: message['is_final'],
				timestamp: message['timestamp'],
				diarization: message['diarization'],
				did_speaker_change: message['did_speaker_change'],
				is_ai_answer: message['is_ai_answer'],
			};

			let replacedMessage = false;
			for (let i = 0; i < oldMessages.length; i++) {
				if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
					oldMessages[i] = messageToAdd;
					replacedMessage = true;
					messagesToAdd = [];
				}
			}

			if (!replacedMessage) {
				messagesToAdd = [message];
			}

			setMessages([...oldMessages, ...messagesToAdd]);
			let messagesUpdated = [];
			for (let i = 0; i < oldMessages.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(oldMessages[i])),
				];
			}
			for (let i = 0; i < messagesToAdd.length; i++) {
				messagesUpdated = [
					...messagesUpdated,
					JSON.parse(JSON.stringify(messagesToAdd[i])),
				];
			}
			console.log('messages updated: ' + JSON.stringify(messagesUpdated));

			updateMessagesMerged(messagesUpdated);

			messagesRef.current = [...oldMessages, ...messagesToAdd];
		});

		return () => {
			console.log('Removing socket listeners');
			socket.removeAllListeners();
		};
	}, []);

	useEffect(() => {
		return () => {
			stoppedTranscribing.current = true;
			socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
			socket.emit('clear_audio_sent', { jwt_token: jwtTokenRef.current });
		};
	}, [navigate]);

	const updateMessagesMerged = (messagesUpdated) => {
		let messagesMergedUpdated = [];
		for (let i = 0; i < messagesUpdated.length; i++) {
			if (messagesUpdated[i]['transcript'] != '') {
				const currentTimestamp = messagesUpdated[i]['timestamp'];
				const lastMergedMessage =
					messagesMergedUpdated[messagesMergedUpdated.length - 1];

				if (
					i > 0 &&
					!messagesUpdated[i]['did_speaker_change'] &&
					!messagesUpdated[i]['is_ai_answer'] &&
					!messagesUpdated[i - 1]['is_ai_answer'] &&
					messagesMergedUpdated.length > 0 &&
					lastMergedMessage['messages_merged'] < 7 &&
					currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
				) {
					lastMergedMessage['transcript'] =
						lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

					lastMergedMessage['messages_merged']++;

					for (let key in messagesUpdated[i]['corrected_transcripts']) {
						if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
							lastMergedMessage['corrected_transcripts'][key] = '';
						}
						lastMergedMessage['corrected_transcripts'][key] =
							lastMergedMessage['corrected_transcripts'][key] +
							' ' +
							messagesUpdated[i]['corrected_transcripts'][key];
					}
				} else {
					messagesUpdated[i]['messages_merged'] = 1;
					messagesMergedUpdated.push(messagesUpdated[i]);
				}
			}
		}
		console.log(messagesMergedUpdated);
		const text = messagesMergedUpdated
			.map((message) => message.transcript)
			.join(' ');
		setMessagesMerged(text);
	};

	const onTranscribe = async (blob) => {
		if (stoppedTranscribing.current) {
			clearChunks();
			stoppedTranscribing.current = false;
			return {
				blob,
				text: '',
			};
		}

		const base64 = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});

		if (jwtTokenRef.current == undefined) {
			console.log('jwtToken is undefined');
			return;
		}

		console.log(
			'gonna send request. blob length: ' +
				blob.size +
				' base64 length: ' +
				base64.length
		);

		socket.emit('transcribe', {
			audio: base64,
			jwt_token: jwtTokenRef.current,
		});
		console.log('sent request with languages to translate to');

		clearChunks();

		// you must return result from your server in Transcript format
		return {
			blob,
			text: '',
		};
	};

	const { stopRecording, startRecording, clearChunks } = useWhisper({
		// callback to handle transcription with custom server
		onDataAvailable: onTranscribe,
		onTranscribe: onTranscribe,
		streaming: true,
		timeSlice: 250, // 5 seconds
		removeSilence: false,
		whisperConfig: {
			language: 'en',
		},
	});

	const handleTranscribeButton = async () => {
		if (isTranscribing) {
			stoppedTranscribing.current = true;
			await stopRecording();
			socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
		} else {
			stoppedTranscribing.current = false;
			await startRecording();
		}

		setTranscribing(!isTranscribing);
	};

	const updateTranscript = async (newTranscript) => {
		if (newTranscript === '') {
			return;
		}
		const payload = {
			transcript_id: fileInUse.id,
			transcript: newTranscript,
		};

		try {
			const response = await fetch(serverURL + '/update_transcript', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				const data = await response.json();
				console.log('Transcript updated:', data);
			} else {
				console.error('Error updating transcript:', response);
			}
		} catch (error) {
			console.error('Error updating transcript:', error);
		}
	};

	const debouncedUpdateTranscript = (newText) => {
		const formattedText = newText
			.replace(/<br>/g, '\n')
			.replace(/<\/div><div>/g, '\n')
			.replace(/<\/?div>/g, ''); // This removes any remaining <div> tags
		updateTranscript(formattedText);
	};

	const handleSpeakerSelect = (e) => {
		e.stopPropagation();
		if (showSummary) {
			playAudioText(summary);
		} else {
			playAudio(newContent);
		}
	};

	const playAudio = async (content) => {
		playAudioText(content);
	};

	const audioRequest = async (text) => {
		try {
			setIsLoadingTTS(true);
			const response = await axios.post(
				`${serverURL}/text_to_speech`,
				{ text: text },
				{
					headers: {
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
					responseType: 'blob',
				}
			);

			if (response.status === 200) {
				setIsLoadingTTS(false);
				setIsPlaying(true);
				const audioBlob = response.data;

				const audioUrl = URL.createObjectURL(audioBlob);
				const audio = new Audio(audioUrl);
				audioRef.current = audio;

				await new Promise((resolve) => {
					audio.onended = () => {
						setIsPlaying(false);
						audioRef.current = null;
						resolve();
					};
					audio.play();
				});
			} else {
				setIsLoadingTTS(false);
				setIsPlaying(false);
				throw new Error(`Received status code ${response.status}`);
			}
		} catch (error) {
			setIsLoadingTTS(false);
			setIsPlaying(false);
			console.error('Error occurred:', error);
		}
	};

	const playAudioText = async (text) => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current = null;
			setIsLoadingTTS(false);
			setIsPlaying(false);
			return;
		}

		audioRequest(text);
	};

	const handleGenerateClick = () => {
		console.log('handleGenerateClick');
		if (getSummaryFromFile() !== '') {
			setSummary(getSummaryFromFile());
		} else {
			setSummary('Summarizing...');

			fetch(
				`${serverURL}/summarize_transcript?transcript_id=${fileInUse.id}&target_language=en`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
				}
			)
				.then((response) => {
					if (response.ok) {
						return response.blob();
					}
					throw new Error('Network response was not ok.');
				})
				.catch((error) => {
					console.error('Error summarizing:', error);
				});

			const getSummary = (retryCount = 0, maxRetries = 200) => {
				const fetchData = () => {
					fetch(
						`${serverURL}/get_raw_transcript?transcript_id=${fileInUse.id}`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${jwtTokenRef.current}`,
							},
						}
					)
						.then((response) => {
							if (response.ok) {
								return response.json();
							}
							throw new Error('Network response was not ok.');
						})
						.then((data) => {
							raw_transcript.current = data;
							if (
								data.summaries &&
								data.summaries.en &&
								data.summaries.en.summary &&
								data.summaries.en.summary.summary
							) {
								setSummary(data.summaries.en.summary.summary);
							} else if (retryCount < maxRetries) {
								setTimeout(() => getSummary(retryCount + 1, maxRetries), 2000);
							} else {
								console.error('Maximum retries reached, no summary available.');
							}
						})
						.catch((error) => {
							console.error('Error generating summary:', error);
						});
				};

				fetchData();
			};

			getSummary();
		}
	};

	const getSummaryFromFile = () => {
		const description = raw_transcript.current.description;
		if (description) {
			return description;
		}
		const summaries = raw_transcript.current.summaries;
		if (summaries) {
			const firstSummary = Object.values(summaries)[0];
			if (firstSummary && firstSummary.summary) {
				if (firstSummary.summary.summary) {
					return firstSummary.summary.summary;
				}
				return firstSummary.summary;
			}
		}
		return '';
	};

	const onSummaryContainerClick = () => {
		if (showSummary) {
			setShowSummary(false);
		} else {
			setShowSelectSummaryType(!showSelectSummaryType);
		}
	};

	const onIconFrameClick = () => {
		navigate(-1);
	};

	const convertNewLinesToHTML = (text) => {
		return text.replace(/\n/g, '<br>');
	};

	return (
		<AnimatePresence>
			<motion.div
				initial={{ x: '100%' }}
				animate={{ x: 0 }}
				exit={{ x: '100%' }}
				transition={{ type: 'tween', duration: 0.25 }}
				className="absolute z-20 w-screen h-full pt-16 flex items-center justify-center bg-whitesmoke font-montserrat"
			>
				<div
					className={`fixed bottom-20 z-10 right-10 w-fit ${keyboardVisible ? 'pb-[env(safe-area-inset-bottom)]' : ''}`}
				>
					<button
						className="bg-black/60 text-white p-3 py-2 flex items-center justify-center rounded-full font-montserrat"
						onClick={() => {
							if (!canEdit) {
								setCanEdit(true);
							} else {
								navigator.clipboard.writeText(newContent);
								setCopiedText(true);
								setTimeout(() => setCopiedText(false), 2000);
							}
						}}
					>
						{!canEdit ? (
							<EditOutlined className="text-2xl" />
						) : (
							<div className="flex justify-center items-center gap-2">
								<CopyOutlined className="text-2xl" />
								<div className="text-white mb-1">
									{copiedText ? 'Copied!' : 'Copy All'}
								</div>
							</div>
						)}
					</button>
				</div>
				<div className="w-full h-full relative mb-[150px] flex flex-col px-[1.25rem] gap-[0.943rem] mt-[100px] ml-[20px]">
					<div className="flex w-full justify-between items-center">
						<img
							className="w-[2.25rem] h-[2.25rem] relative shrink-0"
							loading="lazy"
							alt=""
							src="/arrow-left.svg"
							onClick={onIconFrameClick}
						/>
						{canEdit && (
							<div
								className="text-dodgerblue-300 text-md"
								onClick={() => {
									setCanEdit(false);
									debouncedUpdateTranscript(newText);
								}}
							>
								Done
							</div>
						)}
					</div>
					{showCustomSummaryInput && (
						<div className="fixed inset-0 z-50 -mt-16">
							<CustomizeSummaryWtEdit
								setMessagesMerged={setMessagesMerged}
								messagesMerged={messagesMerged}
								setShowCustomSummaryInput={setShowCustomSummaryInput}
								setShowSelectSummaryType={setShowSelectSummaryType}
								setShowSummary={setShowSummary}
								setSummary={setSummary}
								handleTranscribeButton={handleTranscribeButton}
								isTranscribing={isTranscribing}
								fileInUse={fileInUse}
							/>
						</div>
					)}
					{showSelectSummaryType && (
						<div className="fixed inset-0 z-50 -mt-16 -mx-[1.25rem]">
							<SelectSummaryType
								onClose={() => setShowSelectSummaryType(false)}
								showCustomSummaryInput={() => {
									setShowSelectSummaryType(false);
									setShowCustomSummaryInput(true);
								}}
								callGeneralSummary={() => {
									setShowSelectSummaryType(false);
									handleGenerateClick();
									setShowSummary(true);
								}}
							/>
						</div>
					)}
					<MobileAISummary
						onSummaryContainerClick={onSummaryContainerClick}
						summarySvg={`${showSummary ? '/whitesummary.svg' : '/bluesummary.svg'}`}
						isShowingSummary={showSummary}
						backgroundColor={`${
							showSummary
								? 'bg-gradient-to-br from-[#26CBFF] to-[#6980FD]'
								: 'bg-white'
						}`}
						handleSpeakerSelect={handleSpeakerSelect}
						isLoadingTTS={isLoadingTTS}
						isPlaying={isPlaying}
					/>
					<div className="flex flex-col px-1 max-w-full text-left text-[0.813rem] text-updated-color-new-black font-montserrat gap-6 max-h-full overflow-y-scroll">
						{fileInUse?.transcript_type === 'gpu' && !showSummary ? (
							<div
								contentEditable={canEdit}
								suppressContentEditableWarning
								className="bg-transparent border-none outline-none flex-grow"
								onInput={(e) => {
									const text = e.currentTarget.innerHTML.replace(/<br>/g, '\n');
									setNewText(text);
								}}
								dangerouslySetInnerHTML={{
									__html: convertNewLinesToHTML(newContent),
								}}
							></div>
						) : (
							<ReactMarkdown className="pt-[0.625rem] px-[0.625rem] pb-[3.812rem] border-none w-full bg-transparent flex-grow">
								{showSummary ? summary : newContent}
							</ReactMarkdown>
						)}
					</div>
				</div>
			</motion.div>
		</AnimatePresence>
	);
};
