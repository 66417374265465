import { useState } from 'react';

export const SavePopup = ({
	isTranscribing,
	stoppedTranscribing,
	socket,
	handleTranscribeButton,
	jwtTokenRef,
	setShowPopup,
}) => {
	const [saved, setSaved] = useState(false);

	return (
		<div
			className='fixed inset-0 flex items-center justify-center bg-overlay-new-background font-montserrat'
			onClick={() => setShowPopup(false)}
		>
			<div
				className={`bg-white ${!saved ? 'pt-10 pb-6' : 'py-6'} ${!saved ? 'w-10/12' : 'w-1/2'} rounded-lg relative flex items-center justify-center`}
				onClick={(e) => e.stopPropagation()}
			>
				<img
					className='w-6 h-6 absolute top-4 right-4'
					loading='lazy'
					alt=''
					src='/interface--close-circle-1.svg'
					onClick={() => setShowPopup(false)}
				/>
				{!saved && (
					<div className='w-full flex flex-col items-center gap-8'>
						<div className='text-center text-updated-color-new-black px-12'>
							<div className='text-lg font-semibold mb-2'>Ended</div>
							<div className='text-center text-xs font-light'>
								Save the transcribed conversation to My Documents
							</div>
						</div>
						<div className='text-center w-3/4'>
							<button
								className='bg-gradient-to-br py-3 from-[#007EF3]/60 to-[#7977D1]/60 rounded-3xs text-base font-medium text-white block w-full mb-4'
								onClick={() => {
									socket.emit('save_audio', {
										jwt_token: jwtTokenRef.current,

									});
									if (isTranscribing) {
										stoppedTranscribing.current = true;
										handleTranscribeButton(false);
									}
									setSaved(true);
								}}
							>
								Save the Script
							</button>
							<button
								className='block w-full text-base font-medium'
								onClick={() => setShowPopup(false)}
							>
								{'<'} Back to Conversation
							</button>
						</div>
					</div>
				)}
				{saved && (
					<div className='w-full flex flex-col items-center gap-2'>
						<img className='w-2/4' loading='lazy' alt='' src='/saved.svg' />

						<div className='text-center text-updated-color-new-black'>
							<div className='text-lg font-semibold'>Saved</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
