import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Welcome = () => {
	const navigate = useNavigate();
	const [fadeOut, setFadeOut] = useState(true);

	useEffect(() => {
		const isOnboarded = localStorage.getItem('isOnboarded');
		setFadeOut(false); // Update fadeOut to false after one second

		const timer = setTimeout(() => {
			if (isOnboarded === 'true') navigate('/login');
			else {
				navigate('/onboarding-0');
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [navigate]);

	return (
		<div
			className={`${
				fadeOut ? 'opacity-100' : 'opacity-0'
			} transition-opacity ease-in-out duration-[1500ms] min-h-screen relative bg-white overflow-hidden flex flex-col items-end justify-center pt-[22.094rem] pb-[22.031rem] pr-[3.625rem] pl-[3.563rem] box-border gap-[1.25rem] tracking-[normal] text-center text-[1rem] text-app-close-to-white-grey-bkg font-montserrat`}
		>
			<section className="w-full h-full absolute !m-[0] top-[0rem] right-[0rem] bottom-[0rem] left-[0rem] [background:linear-gradient(110.97deg,_rgba(0,_126,_243,_0.44),_rgba(121,_119,_209,_0.44)),_rgba(255,_255,_255,_0.08)] [filter:blur(4px)]" />
			<div className="self-stretch h-[5.375rem] flex flex-row items-start justify-center my-auto">
				<img
					className="h-[5.375rem] w-[6.031rem] relative z-[1]"
					loading="lazy"
					alt=""
					src="/logo.svg"
				/>
			</div>
			<div className="self-stretch relative font-semibold z-[1] text-white">
				<p className="m-0">{`interpret, uniting the world `}</p>
				<p className="m-0">in one language conversation</p>
			</div>
		</div>
	);
};
