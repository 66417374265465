import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordMessage = ({ handleForgotPassword }) => {
  const navigate = useNavigate();

  const onArrowArrowLeft6CircleClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <div className='w-full relative  overflow-hidden flex flex-col items-start justify-start pt-[4.375rem] px-[1.25rem] pb-[24.25rem] box-border gap-[17.312rem] leading-[normal] tracking-[normal] cursor-pointer text-center text-[1rem] text-updated-color-new-black font-montserrat'>
      <img
        className='w-[2.5rem] h-[2.5rem] relative overflow-hidden shrink-0 cursor-pointer'
        loading='lazy'
        alt=''
        src='/mobileback.svg'
        onClick={onArrowArrowLeft6CircleClick}
      />
      <div className='self-stretch flex flex-row items-start justify-start py-[0rem] pr-[3.25rem] pl-[3.312rem]'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[1.25rem]'>
          <div className='self-stretch relative font-medium'>
            We just sent an email to your inbox with a link.
          </div>
          <div className='self-stretch relative text-[0.875rem] text-unselected-text'>
            <span>{`Didn’t receive email? `}</span>
            <span
              onClick={handleForgotPassword}
              className='[text-decoration:underline] text-dodgerblue-100'
            >
              Resend
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
