import { useState, useCallback, useEffect } from 'react';
import StartjoinARoomOverlay from '../../components/mobile/StartjoinARoomOverlay';
import PortalPopup from '../../components/mobile/PortalPopup';
import Profile from '../../components/mobile/Profile';
import { userInfoRef, updateAccountInfo, jwtTokenRef } from '../../httpContext';
import { useNavigate } from 'react-router-dom';

export const Home = ({ setIsLoggedIn }) => {
	const navigate = useNavigate();

	const [isStartjoinARoomOverlayOpen, setStartjoinARoomOverlayOpen] =
		useState(false);
	const [isProfileOpen, setProfileOpen] = useState(false);

	const onFrameContainerClick = useCallback(() => {
		navigate('/transcribe');
		// Please sync "transcribe start" to the project
	}, []);

	const openStartjoinARoomOverlay = useCallback(() => {
		setStartjoinARoomOverlayOpen(true);
	}, []);

	const closeStartjoinARoomOverlay = useCallback(() => {
		setStartjoinARoomOverlayOpen(false);
	}, []);

	const openProfile = useCallback(() => {
		setProfileOpen(true);
	}, []);

	const closeProfile = useCallback(() => {
		setProfileOpen(false);
	}, []);

	const [email, setEmail] = useState('');

	const refreshEmail = async () => {
		await updateAccountInfo(jwtTokenRef, userInfoRef);
		while (userInfoRef.current == undefined) {
			await new Promise((r) => setTimeout(r, 500));
		}
		setEmail(userInfoRef.current?.email);
	};

	useEffect(() => {
		refreshEmail();
	}, []);

	return (
		<div className="h-full overflow-y-scroll pb-20">
			<div className="flex min-h-full flex-col gap-4 px-5 pt-16 text-left sm:text-base text-xs text-updated-color-new-black font-montserrat">
				<div className="flex items-center justify-between">
					<div className="flex flex-row items-start justify-start">
						<img
							className="w-[52px] relative h-[52px] overflow-hidden shrink-0"
							alt=""
							src="/1024px-x-1024px-round-w-text.svg"
						/>
					</div>
					<div className="flex flex-row items-center justify-start gap-[6px]">
						<div className="relative leading-[22px] font-semibold text-base">
							Hello,{' '}
						</div>
						<img
							className="w-[52px] relative h-[52px] object-cover cursor-pointer rounded-full"
							alt=""
							src={userInfoRef?.current?.profile_picture || '/no-picture.svg'}
							onClick={openProfile}
						/>
					</div>
				</div>
				<h1 className="text-base sm:text-lg font-semibold">
					Welcome to Interpret!
				</h1>
				<div className="flex flex-col sm:gap-[15px] gap-[8px]">
					<div
						className="rounded-xl bg-[linear-gradient(110.83deg,_rgba(0,_126,_243,_0.09),_rgba(121,_119,_209,_0.09))] shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex flex-col items-start justify-start py-4 sm:py-5 px-4 sm:px-2.5 cursor-pointer"
						onClick={onFrameContainerClick}
					>
						<div className="flex flex-col items-start justify-start gap-[10px] w-full">
							<div className="w-full flex flex-row items-center py-0 box-border">
								<div className="relative leading-[22px] font-medium text-nowrap text-base w-full">
									AI-powered Transcriber
								</div>
							</div>
							<div className="w-fit flex items-center justify-center py-0 px-[70px] box-border">
								<dotlottie-player
									src="https://lottie.host/e54ef364-4fbd-4d27-af7c-dbce99bf43a5/AyZ2WNA90E.json"
									background="transparent"
									speed="1"
									loop
									autoplay
								></dotlottie-player>
							</div>
						</div>
						<div className="w-full flex flex-row items-center justify-between py-0 box-border relative gap-[10px] mt-[-17px] text-2xl">
							<div className="relative font-semibold z-[0]">
								Start a<br /> Conversation Now!
							</div>

							<img
								className="w-6 absolute !m-[0] bottom-0 right-0 h-6 z-[1]"
								alt=""
								src="/arrow--arrow-top-right-1.svg"
							/>
						</div>
					</div>
					<div
						className="rounded-xl [background:linear-gradient(93.12deg,_rgba(121,_119,_209,_0.56),_rgba(24,_125,_236,_0.24)_80%,_rgba(0,_126,_243,_0.72))] shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex flex-col items-start justify-start py-2.5 px-4 gap-[15px] cursor-pointer"
						onClick={openStartjoinARoomOverlay}
					>
						<div className="flex flex-row items-start justify-start py-0">
							<img
								className="w-10 relative h-10 object-cover opacity-[0.62]"
								alt=""
								src="/broadcast-headphones.png"
							/>
						</div>
						<div className="flex flex-row items-center justify-between w-full py-0">
							<div className="relative">
								<p className="m-0 font-medium text-base">Broadcast</p>
								<p className="m-0 text-xs text-nowrap">share or join a room</p>
							</div>
							<img
								className="w-6 h-6"
								alt=""
								src="/arrow--arrow-top-right-1.svg"
							/>
						</div>
					</div>
				</div>
			</div>
			{isStartjoinARoomOverlayOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeStartjoinARoomOverlay}
				>
					<StartjoinARoomOverlay
						onOutsideClickFunc={closeStartjoinARoomOverlay}
					/>
				</PortalPopup>
			)}
			{isProfileOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeProfile}
				>
					<Profile
						setIsLoggedIn={setIsLoggedIn}
						email={email}
						closeFunc={closeProfile}
					/>
				</PortalPopup>
			)}
		</div>
	);
};
