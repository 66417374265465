import { useRef } from 'react';
import PropTypes from 'prop-types';

const SelectSummaryType = ({
	onClose,
	showCustomSummaryInput,
	callGeneralSummary,
}) => {
	const sectionRef = useRef(null);

	const handleOutsideClick = (event) => {
		if (sectionRef.current && !sectionRef.current.contains(event.target)) {
			onClose();
		}
	};

	return (
		<div
			className={`bg-black/30 flex items-center w-full h-full justify-center`}
			onMouseDown={handleOutsideClick}
		>
			<div
				ref={sectionRef}
				className="rounded-xl bg-white flex flex-col items-center pt-11 px-14 relative pb-9 text-center text-sm text-updated-color-new-black font-montserrat"
			>
				<div className="w-full flex flex-col gap-2">
					<div
						className="w-full flex gap-2 items-center justify-between"
						onClick={() => {
							callGeneralSummary();
						}}
					>
						<div>Automatic summary</div>
						<img
							className="h-6 w-6"
							loading="lazy"
							alt=""
							src="/group-1707478202-1.svg"
						/>
					</div>
					<hr className="w-full text-updated-color-grey2 border-x-0 border-b-0 border-[.5px]" />
					<div
						className="w-full flex items-center justify-between"
						onClick={() => {
							showCustomSummaryInput();
						}}
					>
						<div>Customize summary</div>
						<img
							className="h-6 w-6"
							loading="lazy"
							alt=""
							src="/group-1707478202-1.svg"
						/>
					</div>
				</div>
				<img
					className="w-6 h-6 absolute top-4 right-4 cursor-pointer"
					loading="lazy"
					alt=""
					src="/interface--close-circle-1.svg"
					onClick={onClose}
				/>
			</div>
		</div>
	);
};

SelectSummaryType.propTypes = {
	className: PropTypes.string,
};

export default SelectSummaryType;
