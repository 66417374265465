import { useCallback, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SelectTypesOfRoomToJoin = ({ onOutsideClickFunc }) => {
	const [isLinkQRCodeInput, setIsLinkQRCodeInput] = useState(false);
	const isLinkQRCodeInputRef = useRef(isLinkQRCodeInput);

	const [isPINInput, setIsPINInput] = useState(false);
	const isPINInputRef = useRef(isPINInput);

	const pinInputContainerRef = useRef(null); // Create a ref for the div

	const selectRoomRef = useRef(null); // Create a ref for the div

	const linkQRCodeInputContainerRef = useRef(null); // Create a ref for the div

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				selectRoomRef.current &&
				!selectRoomRef.current.contains(event.target) &&
				!isPINInputRef.current &&
				!isLinkQRCodeInputRef.current
			) {
				onOutsideClickFunc();
			}
			if (
				pinInputContainerRef.current &&
				!pinInputContainerRef.current.contains(event.target) &&
				isPINInputRef.current
			) {
				setIsPINInput(false);
				isPINInputRef.current = false;
				onOutsideClickFunc();
			}
			if (
				linkQRCodeInputContainerRef.current &&
				!linkQRCodeInputContainerRef.current.contains(event.target) &&
				isLinkQRCodeInputRef.current
			) {
				setIsLinkQRCodeInput(false);
				isLinkQRCodeInputRef.current = false;
				onOutsideClickFunc();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [onOutsideClickFunc]);

	const PINInput = () => {
		const navigate = useNavigate();
		const pinInputRef = useRef(null);
		const [pin, setPin] = useState(['', '', '', '']);
		const inputRefs = useRef([]);

		const isPinComplete = pin.every((val) => val.length > 0); // Check if all PIN inputs are filled

		// keep the pinRef and pin state in sync
		useEffect(() => {
			pinInputRef.current = pin;
		}, [pin]);
		useEffect(() => {
			// Focus the first input field after the component mounts
			if (inputRefs.current[0]) {
				inputRefs.current[0].focus();
			}
		}, []);
		return (
			<div
				ref={pinInputContainerRef}
				className="relative flex flex-col justify-center items-center bg-gray-100 px-[35px] py-[45px] pb-[20px] rounded-xl"
			>
				<img
					className="w-6 h-6 absolute top-4 right-4"
					loading="lazy"
					alt=""
					src="/interface--close-circle-1.svg"
					onClick={() => {
						setIsPINInput(false);
						isPINInputRef.current = false;
					}}
				/>
				<div className="font-semibold inline-block min-w-[125px] mb-[20px] text-center">
					Enter the PIN
				</div>
				<div className="inline-flex flex-row items-start justify-center gap-[10px] mx-auto">
					{[...Array(4)].map((_, index) => (
						<input
							key={index}
							ref={(el) => (inputRefs.current[index] = el)}
							maxLength="1"
							className="rounded-3xs box-border flex flex-col items-center justify-center p-[19px] min-w-[46px] max-w-[59px] border-[1px] border-solid border-updated-color-new-black text-center"
							type="text"
							pattern="[0-9]*"
							inputMode="numeric"
							onChange={(e) => {
								if (e.target.value) {
									const newPin = [...pin];
									newPin[index] = e.target.value;
									setPin(newPin);
									// Automatically focus next input if value is entered
									if (e.target.value && index < 3) {
										inputRefs.current[index + 1].focus();
									}
								}
							}}
							onKeyDown={(e) => {
								if (e.key === 'Backspace') {
									if (pin[index] === '' && index > 0) {
										// Move focus to the previous input and clear its value if the current input is already empty
										const newPin = [...pin];
										newPin[index - 1] = ''; // Clear the character in the previous input
										setPin(newPin);
										inputRefs.current[index - 1].focus();
									} else {
										// Just clear the current input's value if it's not empty
										const newPin = [...pin];
										newPin[index] = ''; // Clear the current input
										setPin(newPin);
									}
								}
							}}
							name={`pin-${index}`}
							value={pin[index]}
						/>
					))}
				</div>
				{!isPinComplete ? (
					<button className="mt-4 cursor-pointer [border:none] py-2.5 px-[75.5px] bg-updated-color-grey2 rounded-[10px] flex items-center justify-center whitespace-nowrap">
						<div className="relative text-base font-medium font-montserrat text-white text-center inline-block min-w-[119px]">
							Join the Room
						</div>
					</button>
				) : (
					<button
						onClick={() => {
							navigate('/broadcast/' + pinInputRef.current.join(''));
						}}
						className="mt-4 cursor-pointer [border:none] py-2.5 px-[75.5px] rounded-[10px] [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)] flex items-center justify-center whitespace-nowrap"
					>
						<div className="relative text-base font-medium font-montserrat text-white text-center inline-block min-w-[119px]">
							Join the Room
						</div>
					</button>
				)}
			</div>
		);
	};

	const LinkQRCodeInput = () => {
		const navigate = useNavigate();
		const [link, setLink] = useState('');
		const [isValidLink, setIsValidLink] = useState(false);

		const handleJoinClick = () => {
			if (isValidLink) {
				const broadcastPath = link.split('/broadcast/')[1];
				navigate(`/broadcast/${broadcastPath}`);
			} else {
				alert(
					'Invalid link. Please enter a valid link starting with localhost:3000/broadcast/ or interpretapp.ai/broadcast/'
				);
			}
		};

		const handleLinkChange = (e) => {
			const enteredLink = e.target.value;
			setLink(enteredLink);
			setIsValidLink(
				enteredLink.startsWith('localhost:3000/broadcast/') ||
					enteredLink.startsWith('interpretapp.ai/broadcast/')
			);
		};

		const handleScanQRCode = () => {
			// Open the phone camera for QR code scanning
			// You can use a library like `react-qr-reader` to implement the scanning functionality
			// Once the QR code is scanned, extract the link and navigate to it
			// Example:
			// const result = await scanQRCode();
			// if (result.startsWith('http://localhost:3000/broadcast/') || result.startsWith('https://interpretapp.ai/broadcast/')) {
			//   window.location.href = result;
			// } else {
			//   alert('Invalid QR code. Please scan a valid QR code.');
			// }
		};

		return (
			<div
				ref={linkQRCodeInputContainerRef}
				className="absolute w-1/2 flex flex-col justify-center items-center bg-gray-100 px-10 py-[45px] pb-[20px] rounded-xl"
			>
				<div className="w-full h-fit flex flex-col items-start justify-start gap-[0px] mb-[20px]">
					<div className="flex flex-col items-start justify-start">
						<div className="relative font-semibold">Type the link below</div>
					</div>
					<div className="self-stretch flex-1 flex flex-row items-center justify-start gap-[0px] text-center text-sm">
						<input
							type="text"
							className="self-stretch flex-1 py-2.5 px-0 bg-transparent focus:outline-none border-0 border-b border-solid border-updated-color-grey2"
							placeholder="Enter the link"
							style={{ backgroundColor: 'transparent' }}
							value={link}
							onChange={handleLinkChange}
						/>
					</div>
				</div>
				<img
					className="w-6 h-6 absolute !m-[0] top-[10px] right-[10px] overflow-hidden shrink-0 cursor-pointer"
					loading="lazy"
					alt=""
					src="/interface--close-circle-1.svg"
					onClick={() => {
						setIsLinkQRCodeInput(false);
						isLinkQRCodeInputRef.current = false;
					}}
				/>
				<div className="self-stretch flex flex-col items-center justify-center gap-[10px]">
					<button
						className={`cursor-pointer [border:none] py-2.5 px-5 self-stretch rounded-3xs flex flex-row items-center justify-center ${
							isValidLink
								? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]'
								: 'bg-updated-color-grey2'
						}`}
						onClick={handleJoinClick}
					>
						<div className="relative text-base font-medium font-montserrat text-white text-left inline-block min-w-[34px]">
							Join
						</div>
					</button>
					{/* <div className="relative font-semibold inline-block min-w-[29px]">
                OR
                </div>
                <button
                className="cursor-pointer [border:none] py-2.5 px-[61.5px] bg-[transparent] rounded-3xs [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))] flex flex-row items-center justify-center whitespace-nowrap"
                onClick={handleScanQRCode}
                >
                <div className="relative text-base font-medium font-montserrat text-white text-left">
                    Scan the QR Code
                </div>
                </button> */}
				</div>
			</div>
		);
	};

	return (
		<div className="w-full h-full flex justify-center items-center relative overflow-hidden cursor-pointer max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat">
			{isPINInput && <PINInput />}
			{isLinkQRCodeInput && <LinkQRCodeInput />}
			{!isPINInput && !isLinkQRCodeInput && (
				<div
					ref={selectRoomRef}
					className="absolute top-[calc(50%_-_87px)] left-[calc(50%_-_170.5px)] rounded-xl bg-gray-100 flex flex-col items-start justify-start"
				>
					<div
						className="w-[270px] mx-[35px] mt-[40px] mb-[20px] box-border flex flex-col items-start justify-start pt-0 px-0 z-[0]"
						onClick={() => {
							setIsPINInput(true);
							isPINInputRef.current = true;
						}}
					>
						<div className="w-[270px] flex flex-col items-start justify-start">
							<div className="relative font-medium">Join with 4-Digits PIN</div>
						</div>
					</div>
					{/* Separator Line with increased visibility */}
					<div className="w-full h-[.5px] bg-black my-2"></div>
					<div
						className="w-full px-[35px] mt-[25px] mb-[40px] flex flex-col items-start justify-start z-[1]"
						onClick={() => {
							setIsLinkQRCodeInput(true);
							isLinkQRCodeInputRef.current = true;
						}}
					>
						<div className="relative font-medium">Join with the Link</div>
					</div>
					<img
						className="w-6 absolute !m-[0] top-[10px] right-[10px] h-6 overflow-hidden shrink-0 cursor-pointer z-[2]"
						alt=""
						src="/interface--close-circle-1.svg"
						onClick={onOutsideClickFunc}
					/>
				</div>
			)}
		</div>
	);
};

export default SelectTypesOfRoomToJoin;
