import { useEffect, useRef } from 'react';
import { MobileDoc } from './MobileDoc';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useNavigate } from 'react-router-dom';

export const Files = ({
	title,
	recentFiles,
	setRecentFiles,
	refreshFiles,
	queryRef,
}) => {
	const navigate = useNavigate();
	const scrollingContainerRef = useRef(null);
	const scrollEndRef = useRef(null);
	const observer = useRef(null);
	const pageRef = useRef(2);
	const isFetchingRef = useRef(false);

	useEffect(() => {
		const options = {
			root: scrollingContainerRef.current,
			rootMargin: '0px',
			threshold: 1.0,
		};

		observer.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting && !isFetchingRef.current) {
				loadMoreFiles();
			}
		}, options);

		const target = scrollEndRef.current;
		if (target) {
			observer.current.observe(target);
		}

		return () => {
			if (observer.current && target) {
				observer.current.unobserve(target);
			}
		};
	}, []);

	const loadMoreFiles = async () => {
		isFetchingRef.current = true;
		try {
			console.log('fetching page', pageRef.current);
			const response = await fetch(
				`${serverURL}/list_transcripts?page_size=10&page_number=${pageRef?.current}&text_search=${queryRef?.current}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtTokenRef?.current}`,
					},
				}
			);

			const data = await response.json();
			if (data && data.transcripts.length > 0) {
				setRecentFiles((prevFiles) => [...prevFiles, ...data.transcripts]);
				pageRef.current += 1;
			}
		} catch (error) {
			console.error('Error loading more files:', error);
		}
		isFetchingRef.current = false;
	};

	const handleFileRename = async (file, name) => {
		try {
			await fetch(
				`${serverURL}/rename_transcript?transcript_id=${file.id}&new_name=${name}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
				}
			);
		} catch (error) {
			console.error('Error renaming file:', error);
		}
		setIsRenamingFile(false);
	};

	const handleFileRemove = async (fileToRemove) => {
		try {
			await fetch(
				`${serverURL}/delete_transcript?transcript_id=${fileToRemove.id}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
				}
			);
			setRecentFiles(recentFiles.filter((file) => file.id !== fileToRemove.id));
		} catch (error) {
			console.error('Error deleting file:', error);
		}
	};

	const handleFileOpen = (file) => {
		navigate('/file_transcript?id=' + file.id);
	};

	return (
		<div
			className="max-h-screen flex flex-col gap-[0.312rem] text-left text-[1.5rem] text-updated-color-new-black font-montserrat mb-24"
			ref={scrollingContainerRef}
		>
			<h1 className="text-inherit font-semibold shrink-0">{title}</h1>
			<div className="overflow-y-auto flex flex-col gap-4 px-2">
				{recentFiles.map((file) => (
					<MobileDoc
						key={file.id}
						file={file}
						handleFileOpen={handleFileOpen}
						deleteFileHandler={handleFileRemove}
						handleFileRename={handleFileRename}
						handleFileRemove={handleFileRemove}
						refreshFiles={refreshFiles}
					/>
				))}
				<div ref={scrollEndRef}></div>
			</div>
		</div>
	);
};
