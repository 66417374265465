import { useNavigate } from 'react-router-dom';
import ProfileDropdown from '../../components/ProfileDropdown';

const ProfileBuyPlan = () => {
	const navigate = useNavigate();

	return (
		<div className="w-full relative bg-white h-[982px] overflow-hidden text-left text-sm text-white font-montserrat">
			<ProfileDropdown />
			{/* <div className="absolute top-[350px] left-[calc(50%_-_99px)] flex flex-row items-center justify-start gap-[10px] text-not-black-black">
        <div className="relative font-semibold">Monthly</div>
        <div className="w-[74px] relative h-9">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-lg bg-updated-color-grey1" />
          <div className="absolute h-[77.78%] w-[37.84%] top-[11.11%] right-[55.41%] bottom-[11.11%] left-[6.76%] rounded-[50%] bg-white" />
        </div>
        <div className="relative font-semibold">Yearly</div>
      </div> */}
			<div className="absolute top-[198px] left-[calc(50%_-_326px)] flex flex-col items-center justify-start gap-[20px] text-[40px] text-not-black-black">
				<div className="relative leading-[123%] font-semibold">{`Plans & Pricing`}</div>
				<div className="w-[652px] relative text-sm font-medium text-center inline-block">
					Everything's within reach, no matter your plan. Enjoy full access to
					all features and content—the only difference is how long you can keep
					the fun going 🥳
				</div>
			</div>
			<div className="absolute top-[457px] left-[609px] rounded-t-xl rounded-b-none bg-updated-color-blue w-[296px] flex flex-row items-center justify-center pt-5 px-2.5 pb-10 box-border">
				<div className="relative font-semibold">MOST POPULAR</div>
			</div>
			<div className="absolute top-[512px] left-[243px] flex flex-row items-start justify-start gap-[70px] text-xl">
				<div className="rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start py-10 px-[30px] gap-[74px]">
					<div className="flex flex-col items-start justify-start gap-[20px]">
						<div className="rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[5px] px-[30px]">
							<div className="relative font-medium">Lite</div>
						</div>
						<div className="flex flex-col items-start justify-start gap-[40px] text-13xl text-not-black-black">
							<b className="relative">$8.99/mo</b>
							<div className="flex flex-col items-start justify-start text-xs">
								<div className="flex flex-row items-start justify-start gap-[10px]">
									<div className="flex flex-row items-start justify-start py-[3px] px-0">
										<div className="w-2 relative rounded-[50%] bg-updated-color-blue h-2" />
									</div>
									<div className="w-[218px] relative inline-block shrink-0">
										Use up to 10 hours of our services
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-[200.1px] relative h-[43.5px] overflow-hidden shrink-0 text-center text-mini-5 text-not-black-black">
						<div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-4xl-2 bg-white opacity-[0.5] mix-blend-normal" />
						<div className="absolute top-[calc(50%_-_9.15px)] left-[32.03%] font-medium">
							Start now
						</div>
					</div>
				</div>
				<div className="rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start py-10 px-[30px] gap-[74px]">
					<div className="flex flex-col items-start justify-start gap-[20px]">
						<div className="rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[5px] px-[30px]">
							<div className="relative font-medium">Pro</div>
						</div>
						<div className="flex flex-col items-start justify-start gap-[40px] text-13xl text-not-black-black">
							<b className="relative">$24.99/mo</b>
							<div className="flex flex-col items-start justify-start text-xs">
								<div className="flex flex-row items-start justify-start gap-[10px]">
									<div className="flex flex-row items-start justify-start py-[3px] px-0">
										<div className="w-2 relative rounded-[50%] bg-updated-color-blue h-2" />
									</div>
									<div className="w-[218px] relative inline-block shrink-0">
										Unlimited time use of our services
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-[200.1px] relative h-[43.5px] overflow-hidden shrink-0 text-center text-mini-5 text-not-black-black">
						<div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-4xl-2 bg-white opacity-[0.5] mix-blend-normal" />
						<div className="absolute top-[calc(50%_-_9.15px)] left-[32.03%] font-medium">
							Start now
						</div>
					</div>
				</div>
				<div className="rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start py-10 px-[31px] gap-[59px]">
					<div className="flex flex-col items-start justify-start gap-[20px]">
						<div className="rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[5px] px-[30px]">
							<div className="relative font-medium">Enterprise</div>
						</div>
						<div className="flex flex-col items-start justify-start gap-[40px] text-13xl text-not-black-black">
							<b className="relative">Let’s Talk!</b>
							<div className="flex flex-col items-start justify-start text-xs">
								<div className="flex flex-row items-start justify-start gap-[7px]">
									<div className="flex flex-row items-start justify-start py-[3px] px-0">
										<div className="w-2 relative rounded-[50%] bg-updated-color-blue h-2" />
									</div>
									<div className="w-[218px] relative inline-block shrink-0">
										Please contact us to start the service.
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-[200.1px] relative h-[43.5px] overflow-hidden shrink-0 text-center text-mini-5 text-not-black-black">
						<div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-4xl-2 bg-white opacity-[0.5] mix-blend-normal" />
						<div className="absolute top-[calc(50%_-_9.15px)] left-[30.03%] font-medium">
							Contact us
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileBuyPlan;
