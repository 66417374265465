import { useRef, useEffect, useState } from 'react';

const Subscription = ({ closeFunc, email }) => {
	const subscriptionRef = useRef(null);
	const [selectedPlan, setSelectedPlan] = useState(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				subscriptionRef.current &&
				!subscriptionRef.current.contains(event.target)
			) {
				closeFunc();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [closeFunc]);

	const handlePlanSelect = (plan) => {
		setSelectedPlan(plan);
	};

	const handleSubscribe = () => {
		if (selectedPlan === 'lite') {
			window.open(
				'https://buy.stripe.com/3cs6pw55jcuZ5Og4go' +
					'?prefilled_email=' +
					email,
				'_blank'
			);
		} else if (selectedPlan === 'pro') {
			window.open(
				'https://buy.stripe.com/28og06btH66BdgIdQW' +
					'?prefilled_email=' +
					email,
				'_blank'
			);
		}
	};

	return (
		<div
			className={`w-full relative bg-overlay-new-background h-full flex flex-col items-center justify-end px-0 pb-0 box-border leading-[normal] tracking-[normal] text-left text-base text-dodgerblue font-montserrat`}
		>
			<div
				ref={subscriptionRef}
				className="w-full rounded-t-xl rounded-b-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col justify-between pt-5 px-5 pb-[31px] box-border gap-[50px] max-h-[80vh] overflow-y-auto"
			>
				<div
					className="relative font-medium cursor-pointer self-end"
					onClick={closeFunc}
				>
					Cancel
				</div>
				<div className="w-full flex flex-col items-start justify-start">
					<div className="w-full flex flex-col justify-start gap-[50px] text-lg text-updated-color-new-black">
						<div className="flex flex-row items-end self-center justify-start gap-[10px]">
							<img
								className="h-[31px] w-[36.6px] relative"
								loading="lazy"
								alt=""
								src="/text-logo.svg"
							/>
							<div className="relative font-semibold">Unlock a plan</div>
						</div>
						<div className="w-[94%] flex flex-col justify-start text-5xl text-updated-color-blue">
							<div className="w-full flex flex-col items-start justify-start relative gap-[15px]">
								<div
									className={`w-full rounded-3xs flex flex-col items-start justify-center py-[15px] pl-5 gap-[15px] ${
										selectedPlan === 'lite'
											? 'bg-updated-color-blue text-white border-[0.8px] border-solid border-updated-color-blue'
											: 'bg-white'
									}`}
									onClick={() => handlePlanSelect('lite')}
								>
									<div className="flex flex-col items-start justify-center gap-[5px]">
										<div className="rounded-3xs flex flex-row items-center justify-center py-[5px] px-0">
											<h2 className="m-0 relative text-inherit font-bold font-inherit">
												Lite
											</h2>
										</div>
										<div className="flex flex-row items-start justify-start gap-[5px] text-lg">
											<div className="relative font-medium whitespace-nowrap">
												$8.99
											</div>
											<div className="relative text-sm font-medium">/month</div>
										</div>
									</div>
									<div
										className={`relative text-sm text-updated-color-new-black ${selectedPlan === 'lite' && 'text-white'}`}
									>
										Use up to 10 hours of our services
									</div>
								</div>
								<div
									className={`w-full rounded-3xs flex flex-col items-start justify-center py-3.5 pl-5 gap-[15px] ${
										selectedPlan === 'pro'
											? 'bg-updated-color-blue text-white border-[0.8px] border-solid border-updated-color-blue'
											: 'bg-white'
									} relative`}
									onClick={() => handlePlanSelect('pro')}
								>
									<div className="flex flex-col items-start justify-center gap-[5px]">
										<div className="rounded-3xs flex flex-row items-center justify-center py-[5px] px-0">
											<h2 className="m-0 relative text-inherit font-bold font-inherit">
												Standard
											</h2>
										</div>
										<div className="flex flex-row items-start justify-start gap-[5px] text-lg">
											<div className="relative font-medium whitespace-nowrap">
												$24.99
											</div>
											<div className="relative text-sm font-medium">/month</div>
										</div>
									</div>
									<div
										className={`relative text-sm text-updated-color-new-black ${selectedPlan === 'pro' && 'text-white'}`}
									>
										Unlimited use of our services
									</div>
									<button
										className={`cursor-pointer [border:none] p-[5px] !m-[0] absolute top-4 right-4 rounded-[21px] flex flex-row items-start justify-start whitespace-nowrap z-[1] ${
											selectedPlan === 'pro'
												? 'bg-white'
												: 'bg-updated-color-blue'
										}`}
									>
										<div
											className={`relative text-xs font-medium font-montserrat text-left ${
												selectedPlan === 'pro'
													? 'text-updated-color-blue'
													: 'text-white'
											}`}
										>
											MOST POPULAR
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					className={`cursor-pointer [border:none] py-2.5 rounded-3xs flex flex-row items-center justify-center ${
						selectedPlan
							? 'bg-[transparent] [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]'
							: 'bg-updated-color-grey2'
					}`}
					onClick={handleSubscribe}
				>
					<div className="relative text-base font-medium font-montserrat text-white">
						Subscribe and Try
					</div>
				</button>
			</div>
		</div>
	);
};

export default Subscription;
