import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, userInfoRef, serverURL } from '../../httpContext';
import { ForgotPasswordMessage } from '../../components/mobile/ForgotPasswordMessage';

const ForgotPassword = ({ setShowMobile }) => {
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState('');
	const [sentEmail, setSentEmail] = useState(false);
	const [email, setEmail] = useState('');
	const [sentEmailMessage, setSentEmailMessage] = useState(null);
	const emailRef = useRef(null);

	const onArrowArrowLeft6CircleClick = useCallback(() => {
		navigate('/login');
	}, [navigate]);

	const handleForgotPassword = useCallback(async () => {
		const response = await fetch(`${serverURL}/forgot-password`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: emailRef.current,
			}),
		});

		if (response.status === 200) {
			setErrorMessage(null);
			setSentEmail(true);
		} else {
			setErrorMessage('Invalid email');
			setSentEmailMessage(null);
		}
	}, [navigate]);

	useEffect(() => {
		setShowMobile(true);
		if (jwtTokenRef.current != undefined && userInfoRef.current != undefined) {
			console.log('jwtToken: ' + jwtTokenRef.current);
			console.log('userInfo: ' + userInfoRef.current);
			if (userInfoRef.current.verified_email) {
				console.log('Verified email');
				navigate('/');
			} else {
				navigate('/email-verification');
			}
		}
	}, []);

	return !sentEmail ? (
		<div className='h-full w-full overflow-hidden flex flex-col pt-[4.375rem] px-[1.25rem] pb-[22.812rem] box-border gap-[1.875rem] leading-[normal] tracking-[normal]'>
			<img
				className='w-[2.5rem] h-[2.5rem] overflow-hidden shrink-0'
				loading='lazy'
				src='/mobileback.svg'
				onClick={onArrowArrowLeft6CircleClick}
			/>
			<section className='self-stretch flex py-[0rem] px-[0.312rem] box-border max-w-full text-center text-[1.125rem] text-updated-color-new-black font-montserrat'>
				<div className='flex-1 flex flex-col gap-[9.687rem] max-w-full'>
					<div className='self-stretch flex flex-col gap-[0.875rem]'>
						<div className='font-semibold text-left'>Forgot Password</div>
						<div className='self-stretch text-[0.875rem] text-unselected-text text-left'>
							Enter your email address, and we’ll send you a link to reset your
							password.
						</div>
					</div>
					<div className='self-stretch flex flex-col gap-[2.812rem]'>
						<div className='self-stretch flex flex-wrap items-center gap-[0.625rem]'>
							<img
								className='h-[1.25rem] w-[1.25rem] overflow-hidden shrink-0'
								src='/email.svg'
							/>
							<div className='flex-1 border-0 flex items-center pt-[0.625rem] px-[0rem] pb-[0.562rem] min-w-[7.25rem] border-b-[0.5px] border-solid border-updated-color-grey2'>
								<input
									className='w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0'
									placeholder='Enter your email'
									type='text'
									value={email}
									onChange={(e) => {
										emailRef.current = e.target.value;
										setEmail(e.target.value);
									}}
								/>
							</div>
						</div>
						<button
							className='[border:none] py-[0.625rem] px-[1.25rem] bg-[transparent] self-stretch rounded-3xs [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] flex items-center justify-center whitespace-nowrap'
							onClick={handleForgotPassword}
						>
							<div className='text-[1rem] font-semibold font-montserrat text-white text-center inline-block min-w-[5.125rem]'>
								Send Link
							</div>
						</button>
						{errorMessage && (
							<div className='self-stretch text-[0.875rem] text-red text-left'>
								{errorMessage}
							</div>
						)}
						<p style={{ marginTop: 20, fontSize: 24 }}>
							{sentEmailMessage ? sentEmailMessage : ''}
						</p>
					</div>
				</div>
			</section>
		</div>
	) : (
		<ForgotPasswordMessage handleForgotPassword={handleForgotPassword} />
	);
};

export default ForgotPassword;
