import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtTokenRef, serverURL } from '../../httpContext';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { top100WorldLanguages } from '../../languages';
import {
	CloseOutlined,
	LoadingOutlined,
	DownOutlined,
} from '@ant-design/icons';

const AudioTranslate = () => {
	const navigate = useNavigate();

	const [transcript, setTranscript] = useState('');
	const [fileSize, setFileSize] = useState(0);
	const [error, setError] = useState('');
	const [isTranscribing, setIsTranscribing] = useState(false);
	const [isUploadFileWindowOpen, setUploadFileWindowOpen] = useState(false);
	const [uploadedFileName, setUploadedFileName] = useState('');
	const [isDownloadDropdownOpen, setDownloadDropdownOpen] = useState(false);
	const [searchBarInput, setSearchBarInput] = useState('');
	const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
	const [outputLanguage, setOutputLanguage] = useState('Original');

	const transcriptIdRef = useRef(null);
	const fileInputRef = useRef(null);
	const languageDropdownRef = useRef(null);

	const convertRawTranscriptToTranscript = (rawTranscript) => {
		let transcriptMarkdown = '';
		for (let i = 0; i < rawTranscript.transcription.segments.length; i++) {
			transcriptMarkdown +=
				rawTranscript.transcription.segments[i].speaker +
				': ' +
				rawTranscript.transcription.segments[i].text +
				'\n';
		}
		return transcriptMarkdown;
	};

	const uploadFile = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		try {
			setUploadedFileName('');
			setIsTranscribing(true);
			setUploadFileWindowOpen(false);
			const response = await axios.post(
				serverURL + '/transcribe_file',
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + jwtTokenRef.current,
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress: (event) => {
						const { loaded, total } = event;
						const percentCompleted = Math.round((loaded / total) * 100);
						console.log(percentCompleted);
					},
				}
			);

			if (response.status !== 200) {
				console.error('Error occurred during file upload.');
				setError('Error occurred during file upload.');
				setIsTranscribing(false);
				return;
			} else {
				const data = response.data;
				console.log(data);
				transcriptIdRef.current = data['transcript_id'];
				console.log('transcript id: ', transcriptIdRef.current);
				getTranscript();
				setError('');
				return data; // Return the response after upload is complete
			}
		} catch (error) {
			setIsTranscribing(false);
			setUploadedFileName('');
			console.error('Error occurred during file upload.', error);
			setError('Error occurred during file upload.');
		}
	};

	const getTranscript = async (retryCount = 0, maxRetries = 200) => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${serverURL}/get_transcript`, {
					params: {
						transcript_id: transcriptIdRef.current,
					},
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtTokenRef.current}`,
					},
				});

				if (response.status === 200 && response.data.transcript != null) {
					setIsTranscribing(false);
					setTranscript(
						convertRawTranscriptToTranscript(
							JSON.parse(response.data.raw_transcript)
						)
					);
				} else {
					if (retryCount < maxRetries) {
						setTimeout(fetchData, 1000); // Retry after 1 second
					} else {
						setIsTranscribing(false);
						throw new Error('Max retries exceeded.');
					}
				}
			} catch (error) {
				console.error('Error fetching transcript:', error);
				if (retryCount < maxRetries) {
					setTimeout(fetchData, 1000); // Retry after 1 second
				} else {
					setIsTranscribing(false);
					throw new Error('Max retries exceeded.');
				}
			}
		};

		fetchData();
	};

	const changeUploadedFileEventLister = () => {
		if (fileInputRef.current.files.length > 0) {
			setFileSize(
				(fileInputRef.current.files[0].size / (1024 * 1024)).toFixed(2) + ' MB'
			);
			const fileName = fileInputRef.current.files[0].name;
			const shortenedFileName =
				fileName.length > 20
					? fileName.substring(0, 20) + '...' + fileName.split('.').pop()
					: fileName;
			setUploadedFileName(shortenedFileName);
			setUploadFileWindowOpen(true);
		}
	};

	const addEventListenerToFile = () => {
		const fileInput = fileInputRef.current;
		if (fileInput) {
			fileInput.addEventListener('change', changeUploadedFileEventLister);
		}
		return () => {
			if (fileInput) {
				fileInput.removeEventListener('change', changeUploadedFileEventLister);
			}
		};
	};

	const addBotToMeeting = async (meetingUrl) => {
		if (jwtTokenRef.current == undefined) {
			return;
		}

		const response = await axios.post(
			`${serverURL}/add_bot_to_zoom`,
			{ meeting_url: meetingUrl },
			{
				headers: {
					Authorization: `Bearer ${jwtTokenRef.current}`,
				},
			}
		);
		if (response.status === 200) {
			console.log('Bot added to meeting');
		} else {
			console.error('Failed to add bot to meeting');
		}
		meetingUrl = extractMeetingId(meetingUrl);
		if (meetingUrl) {
			navigate(`/broadcast/${meetingUrl}`);
		}
	};

	useEffect(() => {
		addEventListenerToFile();
	}, []);

	// Close the language dropdown when clicking outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				languageDropdownRef.current &&
				!languageDropdownRef.current.contains(event.target)
			) {
				setIsLanguageDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="relative">
			{isUploadFileWindowOpen && (
				<div className="fixed inset-0 rounded-xl bg-white shadow-lg flex flex-col items-center justify-center p-[50px] gap-[40px] w-fit m-auto h-fit z-20 font-montserrat">
					<div className="flex flex-col items-center justify-start gap-[64px]">
						<div className="flex flex-col items-start justify-start gap-[20px]">
							<div className="flex flex-col items-start justify-start">
								<div className="relative leading-[22px] text-lg">
									Audio/Video
								</div>
							</div>
							<div className="[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] overflow-hidden flex flex-col items-center justify-start pt-2.5 px-0 pb-[102px] gap-[64px] text-base">
								<div className="w-[621px] flex flex-row items-start justify-end py-2.5 pr-2.5 pl-[242px] box-border">
									<CloseOutlined
										onClick={() => setUploadFileWindowOpen(false)}
									/>
								</div>
								<div className="w-[621px] flex flex-col items-center justify-start py-0 px-[72px] box-border gap-[5px]">
									<div className="relative leading-[22px] font-medium">
										{uploadedFileName}
									</div>
									<div className="relative text-sm leading-[22px] text-updated-color-grey2">
										mov・{fileSize}
									</div>
								</div>
							</div>
						</div>
						<div className="flex self-end gap-[20px] text-base">
							<div
								className="rounded-3xs flex flex-row items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey2 cursor-pointer"
								onClick={() => {
									setUploadFileWindowOpen(false);
									setUploadedFileName('');
									addEventListenerToFile();
									setTimeout(() => {
										if (fileInputRef.current) {
											fileInputRef.current.value = '';
										}
									}, 0);
								}}
							>
								<div className="relative leading-[22px] font-medium">
									Cancel
								</div>
							</div>
							<div
								className="rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_#fff] flex flex-row items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer"
								onClick={() => {
									uploadFile(fileInputRef.current.files[0]);
								}}
							>
								<div className="relative leading-[22px] font-medium">
									Translate
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="bg-white h-full text-left text-xl text-updated-color-new-black font-montserrat flex flex-col">
				<ProfileDropdown />
				{isTranscribing && (
					<div className="flex flex-col items-center justify-center gap-[20px] text-center text-[28px] h-full py-36">
						<LoadingOutlined
							className="text-[#007EF3] mb-6"
							style={{ fontSize: '60px' }}
						/>
						<b className="relative leading-[22px]">Transcribing...</b>
						<div className="relative text-lg leading-[22px] font-semibold">
							Extracting the audio
						</div>
					</div>
				)}
				{!isTranscribing && !transcript && (
					<div className="flex items-center justify-between h-full px-20 py-10">
						<div className="gap-10 [filter:drop-shadow(0px_3px_5.5px_rgba(140,_140,_140,_0.25))] rounded-xl flex flex-col items-center justify-center py-[60px] px-40 text-[24px] border-[1px] border-solid border-updated-color-grey1">
							<div className="flex flex-col items-center justify-start gap-[25px]">
								<img
									className="w-[72.3px] relative h-[72.3px] overflow-hidden shrink-0"
									alt=""
									src="/files--folder-music.svg"
								/>
								<div className="flex flex-col items-center justify-start gap-[10px]">
									<div className="relative leading-[22px] font-semibold">
										Transcribe Audio
									</div>
									<div className="w-[420px] relative text-base leading-[22px] text-center inline-block">
										Please drag and drop an audio file here to have it
										transcribed into your chosen language.
									</div>
								</div>
							</div>
							<div className="flex flex-row items-start justify-start text-center text-sm cursor-pointer relative">
								<input
									type="file"
									accept="audio/*"
									ref={fileInputRef}
									className="absolute opacity-0 w-full h-full z-10 cursor-pointer"
								/>
								<div className="flex flex-row items-center justify-center">
									<div className="rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1">
										<div className="relative leading-[22px]">
											{uploadedFileName ? uploadedFileName : 'Select a file'}
										</div>
									</div>
									<div className="rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1">
										<img
											className="w-[18px] relative h-[18px] overflow-hidden shrink-0"
											alt=""
											src="/arrow--arrow-down-2.svg"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-10 w-1/4 h-full self-start">
							<div className="flex items-center w-full rounded-full">
								<svg
									width="16"
									height="17"
									viewBox="0 0 16 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="-mr-6"
								>
									<path
										d="M2 3.83337H11.3333V11.8334C11.3333 12.5698 10.7364 13.1667 10 13.1667H3.33333C2.59695 13.1667 2 12.5698 2 11.8334V3.83337Z"
										stroke="#007EF3"
										stroke-width="1.67"
										stroke-linejoin="round"
									/>
									<path
										d="M11.3335 6.50004L13.1718 6.04046C13.5926 5.93527 14.0002 6.25351 14.0002 6.68722V10.3129C14.0002 10.7466 13.5926 11.0648 13.1718 10.9596L11.3335 10.5V6.50004Z"
										stroke="#007EF3"
										stroke-width="1.67"
										stroke-linejoin="round"
									/>
								</svg>
								<input
									placeholder="Paste meeting URL to get caption"
									className="px-4 pl-6 py-[5px] w-full border-solid rounded-full border-[.5px] focus:outline-none  bg-transparent"
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											addBotToMeeting(e.target.value);
										}
									}}
								/>
							</div>
							<RecentsSidebar />
						</div>
					</div>
				)}
				{!isTranscribing && transcript && (
					<div className="px-36 py-10">
						<div className="flex flex-col gap-[30px]">
							<div className="flex items-center gap-[20px] relative justify-between">
								<div
									className="relative rounded-full flex flex-row items-center justify-center p-2.5 px-8 border-solid border-[#007EF3] cursor-pointer"
									onClick={() =>
										setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
									}
								>
									<div className="relative leading-[112.4%] font-medium">
										{outputLanguage}
									</div>
									<img
										className="w-[18px] relative h-[18px] overflow-hidden shrink-0 ml-2"
										alt=""
										src="/arrow--arrow-down-2.svg"
									/>
								</div>
								{isLanguageDropdownOpen && (
									<div
										ref={languageDropdownRef}
										className="absolute grid grid-cols-4 top-12 z-10 bg-white border-solid border-gray-300 rounded-md shadow-lg max-h-96 overflow-y-auto w-full px-4"
									>
										<div className="col-span-4 flex items-center p-2 border-b border-solid border-x-0 border-t-0 border-gray-300">
											<input
												type="text"
												placeholder="Search..."
												className="w-full p-1 border-none outline-none text-lg"
												value={searchBarInput}
												onChange={(e) => setSearchBarInput(e.target.value)}
											/>
											<img
												className="w-5 h-5 ml-2"
												alt=""
												src="/general--search.svg"
											/>
										</div>
										<div
											key={'Original'}
											className="p-2 hover:bg-gray-200 cursor-pointer"
											onClick={() => {
												setOutputLanguage('Original');
												setIsLanguageDropdownOpen(false);
											}}
										>
											{'Original'}
										</div>
										{top100WorldLanguages
											.filter((language) =>
												language
													.toLowerCase()
													.includes(searchBarInput?.toLowerCase() || '')
											)
											.map((language) => (
												<div
													key={language}
													className="p-2 hover:bg-gray-200 cursor-pointer h-fit w-full"
													onClick={() => {
														setOutputLanguage(language);
														setIsLanguageDropdownOpen(false);
													}}
												>
													{language}
												</div>
											))}
									</div>
								)}
								<div
									className="rounded-8xs bg-updated-color-blue flex items-center gap-2 justify-center p-2.5 text-white cursor-pointer z-20 relative"
									onClick={() => {
										setDownloadDropdownOpen(!isDownloadDropdownOpen);
									}}
								>
									<div className="relative leading-[112.4%] font-medium">
										Download
									</div>
									<DownOutlined className="" />
								</div>
								{isDownloadDropdownOpen && (
									<div className="absolute z-10 top-14 right-0 w-fit flex flex-col gap-4 rounded-8xs bg-white shadow-lg p-2">
										<div
											className="rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
											onClick={() => {
												const element = document.createElement('a');
												const file = new Blob([transcript], {
													type: 'text/plain',
												});
												element.href = URL.createObjectURL(file);
												element.download = 'transcript.txt';
												document.body.appendChild(element);
												element.click();
												document.body.removeChild(element);
											}}
										>
											<div className="relative leading-[112.4%] font-medium">
												Download as TXT
											</div>
										</div>
										<div
											className="rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
											onClick={() => {
												const element = document.createElement('a');
												const file = new Blob([transcript], {
													type: 'application/pdf',
												});
												element.href = URL.createObjectURL(file);
												element.download = 'transcript.pdf';
												document.body.appendChild(element);
												element.click();
												document.body.removeChild(element);
											}}
										>
											<div className="relative leading-[112.4%] font-medium">
												Download as PDF
											</div>
										</div>
										<div
											className="rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
											onClick={() => {
												const element = document.createElement('a');
												const file = new Blob([transcript], {
													type: 'application/x-subrip',
												});
												element.href = URL.createObjectURL(file);
												element.download = 'transcript.srt';
												document.body.appendChild(element);
												element.click();
												document.body.removeChild(element);
											}}
										>
											<div className="relative leading-[112.4%] font-medium">
												Download as SRT
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="shadow-[1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset] rounded-xl bg-updated-color-translate-box-bkg box-border h-96 max-h-96 overflow-y-auto flex flex-col items-center justify-start py-[30px] px-[50px] text-sm border-[0.5px] border-solid border-updated-color-grey1">
								<div className="w-full relative leading-[25px] font-medium">
									<p className="m-0">{transcript}</p>
								</div>
							</div>
						</div>
					</div>
				)}
				{/* {(isTranscribing || transcript) && (
					<div
						className="absolute bottom-5 left-0 rounded-tl-none rounded-tr-11xl rounded-br-11xl rounded-bl-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] h-11 flex flex-row items-center justify-center py-2.5 pr-5 pl-[49px] box-border gap-[5px] cursor-pointer"
						onClick={() => {
							console.log('clicked enter broadcast');
							console.log(
								'isBroadcastingRef.current',
								isBroadcastingRef.current
							);
							if (isBroadcastingRef.current) {
								setQRRoomWindowVisible(true);
							} else {
								if (sharedRoomIdRef.current === undefined) {
									socket.emit('create_room');
								} else {
									socket.emit('join_room', sharedRoomIdRef.current);
									console.log('joining room ' + sharedRoomIdRef.current);
								}
							}
						}}
					>
						<div className="relative font-semibold">Enter Broadcast</div>
						<img
							className="w-6 relative h-6 overflow-hidden shrink-0"
							alt=""
							src="/arrow--arrow-right-1.svg"
						/>
					</div>
				)} */}
			</div>
		</div>
	);
};

export default AudioTranslate;
