import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { serverURL, updateAccountInfo } from "../../httpContext";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";

const Login = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const emailField = useRef(null);
  const passwordField = useRef(null);
  const jwtTokenRef = useRef(null);
  const userInfoRef = useRef(null);

  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });

  const auth = getAuth();

  const onDontHaveAnClick = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${serverURL}/login`, {
        email: email || emailField.current.value,
        password: password || passwordField.current.value,
      });

      const response_json = response.data;

      if (response.status === 200) {
        jwtTokenRef.current = response_json.access_token;
        try {
          localStorage.setItem("jwtToken", jwtTokenRef.current);
          await updateAccountInfo(jwtTokenRef, userInfoRef);
          console.log("Saved jwtToken");
        } catch (e) {
          console.log(e);
          setError("Internal server error");
          return;
        }

        console.log(response_json);
        setError("");
        navigate("/transcribe");
      } else if (response.status < 500) {
        console.log(response_json);
        if (typeof response_json.detail === "string") {
          setError(response_json.detail);
        } else {
          setError("Invalid email or password");
        }
      } else {
        console.log(response_json);
        setError(response_json.detail);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        setError(error.response.data.detail);
      } else {
        setError("Internal server error");
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();

      const response = await axios.post(`${serverURL}/login-with-google`, {
        token,
      });

      jwtTokenRef.current = response.data.access_token;
      localStorage.setItem("jwtToken", jwtTokenRef.current);
      await updateAccountInfo(jwtTokenRef, userInfoRef);

      navigate("/transcribe");
    } catch (error) {
      setError("Authentication failed. Please try again.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="w-full relative bg-white h-[982px] overflow-hidden text-left text-21xl text-not-black-black font-montserrat">
      <div className="absolute top-[207px] left-[216px] flex flex-col items-start justify-start gap-[20px]">
        <div className="relative leading-[123%] font-semibold">Login</div>
        <div className="flex flex-col items-start justify-start gap-[20px] text-xl text-updated-color-new-black">
          <div className="flex flex-col items-start justify-start gap-[20px]">
            <div className="flex flex-col items-start justify-start gap-[20px]">
              <div className="flex flex-col items-start justify-start gap-[20px]">
                <div className="flex flex-col items-start justify-start gap-[10px]">
                  <div className="relative leading-[123%] font-medium">
                    Email
                  </div>
                  <input
                    type="email"
                    className="w-[460px] relative rounded-3xs bg-white box-border h-[53px] border-[1px] border-solid border-updated-color-grey2"
                    ref={emailField}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div className="flex flex-col items-start justify-start relative gap-[10px]">
                  <div className="relative leading-[123%] font-medium z-[0]">
                    Password
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="w-[460px] relative rounded-3xs bg-white box-border h-[53px] z-[1] border-[1px] border-solid border-updated-color-grey2"
                    ref={passwordField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  {showPassword ? (
                    <EyeInvisibleOutlined
                      className="w-6 absolute !m-[0] top-[50px] left-[416px] h-6 overflow-hidden shrink-0 z-[2]"
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <EyeOutlined
                      className="w-6 absolute !m-[0] top-[50px] left-[416px] h-6 overflow-hidden shrink-0 z-[2]"
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </div>
              </div>
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}
              <div className="flex flex-row items-start justify-start gap-[101px]">
                <div className="flex flex-row items-center justify-start gap-[5px]">
                  <input type="checkbox" className="w-6 relative h-6" />
                  <div className="relative leading-[123%] font-medium">
                    Keep me logged in
                  </div>
                </div>
                <div className="relative leading-[123%] font-medium cursor-pointer"
                onClick={() => {
                  navigate("/forgot-password");
                }}>
                  Forgot password?
                </div>
              </div>
            </div>
            <div
              className="w-[460px] rounded-3xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 box-border text-9xl text-white cursor-pointer"
              onClick={handleLogin}
            >
              <div className="relative leading-[123%] font-semibold">
                Log In
              </div>
            </div>
          </div>
          <div className="relative font-medium text-center cursor-pointer text-updated-color-grey2"
            onClick={onDontHaveAnClick}
          >
            <span>{`Don’t have an account? `}</span>
            <span className="[text-decoration:underline] text-updated-color-blue">
              Create an Account
            </span>
          </div>
          <div className="flex flex-col items-center justify-start gap-[20px]">
            <button
              onClick={handleGoogleLogin}
              className="w-[460px] rounded-3xs bg-white border-[1px] border-solid border-updated-color-grey2 flex flex-row items-center justify-center p-2.5 box-border text-9xl text-black"
            >
              <img
                className="h-8 w-8 mr-3"
                alt="Login with Google"
                src="/google@2x.png"
              />
              <div className="relative leading-[123%] font-semibold">
                Log In with Google
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[756px] bg-updated-color-grey1 w-[756px] h-[982px]" />
      <div className="absolute top-[443px] left-[977px] text-13xl leading-[123%] font-semibold text-black text-center">
        {/* <p className="m-0">placeholder</p>
        <p className="m-0">use scenario photo</p> */}
      </div>
      <div className="absolute top-[0px] left-[50px] w-[1362px] h-24 flex flex-row items-center justify-start pt-[55px] px-0 pb-[30px] box-border gap-[796px] text-5xl text-updated-color-blue">
        <div className="w-[183px] h-[41px] flex flex-row items-center justify-start">
          <div className="w-44 relative h-[41px] cursor-pointer" onClick={() => {
            navigate("/");
          }}>
            <div className="absolute w-[73.86%] top-[14.63%] left-[26.14%] tracking-[-0.02em] font-medium inline-block">
              interpret
            </div>
            <img
              className="absolute h-full w-[23.3%] top-[0%] right-[76.7%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/1024px-x-1024px-round-w-text1.svg"
            />
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[50px] text-center text-xl text-not-black-black">
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px]">
            <div className="relative font-medium opacity-[0]">About</div>
          </div>
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px]">
            <div className="relative font-medium opacity-[0]">Pricing</div>
          </div>
          {/* <div className="flex flex-row items-center justify-center py-[5px] px-[15px]">
            <div className="relative font-medium opacity-[0]">Login</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
