import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtTokenRef, userInfoRef } from '../httpContext';
import { getAuth, signOut } from 'firebase/auth';

const ApplicationTabs = () => {
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<div className="flex shadow gap-10 text-lg border-x-0 border-t-0 border-solid border-[.5px] border-black pl-20 pt-9">
			<div
				className={`flex items-center cursor-pointer pb-3 -mb-[.5px] ${
					location.pathname === '/transcribe'
						? 'text-profile-dropdown-background-90 border-b-4 border-x-0 border-t-0 border-solid border-blue-500'
						: 'text-not-black'
				}`}
				onClick={() => navigate('/transcribe')}
			>
				<div className="relative">Translate & Transcribe</div>
			</div>
			<div
				className={`flex items-center cursor-pointer pb-3 -mb-[.5px] ${
					location.pathname === '/audio-translate'
						? 'text-profile-dropdown-background-90 border-b-4 border-x-0 border-t-0 border-solid border-blue-500'
						: 'text-not-black'
				}`}
				onClick={() => navigate('/audio-translate')}
			>
				<div className="relative">Video & Audio Translator</div>
			</div>
			<div
				className={`flex items-center cursor-pointer pb-3 -mb-[.5px] ${
					location.pathname === '/file-translate'
						? 'text-profile-dropdown-background-90 border-b-4 border-x-0 border-t-0 border-solid border-blue-500'
						: 'text-not-black'
				}`}
				onClick={() => navigate('/file-translate')}
			>
				<div className="relative">Document & Image Translator</div>
			</div>
			<div
				className={`flex items-center cursor-pointer pb-3 -mb-[.5px] ${
					location.pathname === '/docs' ||
					location.pathname.includes('file_transcript')
						? 'text-profile-dropdown-background-90 border-b-4 border-x-0 border-t-0 border-solid border-blue-500'
						: 'text-not-black'
				}`}
				onClick={() => navigate('/docs')}
			>
				<div className="relative">My Documents</div>
			</div>
		</div>
	);
};

const ProfileTabs = () => {
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<div className="flex shadow gap-10 text-lg border-x-0 border-t-0 border-solid border-[.5px] border-black pl-20 pt-9">
			<div
				className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
					location.pathname === '/transcribe'
						? 'text-profile-dropdown-background-90 border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
						: 'text-updated-color-new-black'
				}`}
				onClick={() => navigate('/transcribe')}
			>
				<div className="relative">Dashboard</div>
			</div>
			<div
				className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
					location.pathname === '/profile'
						? 'text-profile-dropdown-background-90 border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
						: 'text-updated-color-new-black'
				}`}
				onClick={() => navigate('/profile')}
			>
				<div className="relative">Account Info</div>
			</div>
			<div
				className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
					location.pathname === '/profile_payment_info' ||
					location.pathname === '/buy_plan'
						? 'text-profile-dropdown-background-90 border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
						: 'text-updated-color-new-black'
				}`}
				onClick={() => navigate('/profile_payment_info')}
			>
				<div className="relative">Payment Info</div>
			</div>
		</div>
	);
};

const ProfileDropdown = () => {
	const auth = getAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [plan, setPlan] = useState('');
	const [email, setEmail] = useState('');
	const [menuOpen, setMenuOpen] = useState(false);

	const handleLogout = async () => {
		localStorage.removeItem('jwtToken');
		if (jwtTokenRef.current != undefined) {
			jwtTokenRef.current = undefined;
			userInfoRef.current = undefined;
		}

		await signOut(auth);

		navigate('/login');
	};

	useEffect(() => {
		const loadUser = async () => {
			while (
				userInfoRef.current === undefined ||
				userInfoRef.current === null ||
				userInfoRef.current.email === null
			) {
				await new Promise((resolve) => setTimeout(resolve, 100));
			}
			console.log('userInfoRef.current: ', userInfoRef.current);
			const email = userInfoRef.current.email;
			const truncatedEmail =
				email.length > 15 ? email.substring(0, 15) + '...' : email;
			var plan = userInfoRef.current?.plan;
			if (plan === null || plan.length === 0) {
				plan = 'Free Trial';
			}

			setEmail(truncatedEmail);
			setPlan(plan);
		};
		loadUser();
	}, []);

	return (
		<div className="text-white flex flex-col">
			<div className="h-[128px] flex justify-between items-center px-20 bg-gradient-to-r from-[#007ef3] to-[#7977d1]">
				<img
					className=" cursor-pointer"
					alt="Logo"
					src="/group-1707478120.svg"
					onClick={() => {
						navigate('/');
					}}
				/>
				<div className="flex flex-col items-end gap-[16px] text-base text-updated-color-new-black z-20">
					<div className="flex justify-center items-center gap-2">
						<div className="font-bold text-white">Hello,</div>
						<div
							className="w-[43px] h-[43px] rounded-full p-px bg-white relative cursor-pointer"
							onClick={() => {
								setMenuOpen(!menuOpen);
							}}
						>
							<img
								className="w-full h-full object-cover rounded-full"
								alt="Profile"
								src={userInfoRef?.current?.profile_picture || '/no-picture.svg'}
							/>
							{menuOpen && (
								<div className="absolute top-10 z-10 right-5 shadow rounded-lg bg-white flex flex-col overflow-hidden">
									<div className="relative leading-[22px] font-medium px-[15px] pt-2">
										{email}
									</div>
									<div className="bg-gradient-to-r from-[#007ef3] to-[#7977d1] py-[5px] px-[15px] pr-28 w-full text-sm text-white">
										<div className="flex items-center gap-[5px]">
											<div className="relative leading-[22px] font-medium">
												{plan}
											</div>
											<div className="relative text-xs leading-[22px]">
												current plan
											</div>
										</div>
									</div>
									<div
										className="w-full flex items-center py-5 pr-0 gap-[10px] px-[15px] cursor-pointer"
										onClick={() => {
											navigate('/profile');
											setMenuOpen(false);
										}}
									>
										<div className="flex items-center">
											{/* <img className="w-[19.6px] h-[19.6px]" alt="Icon" /> */}
											<div className="relative font-medium">Profile</div>
										</div>
									</div>
									<div
										className="w-full flex items-center py-5 pr-0 gap-[10px] px-[15px] cursor-pointer"
										onClick={() => {
											navigate('/profile_payment_info');
											setMenuOpen(false);
										}}
									>
										{/* <img className="w-[19.6px] h-[19.6px]" alt="Icon" /> */}
										<div className="flex items-center">
											<div className="relative font-medium">Subscription</div>
										</div>
									</div>
									<div
										className="w-full flex items-center py-5 pr-0 gap-[10px] px-[15px] cursor-pointer"
										onClick={handleLogout}
									>
										{/* <img className="w-[19.6px] h-[19.6px]" alt="Icon" /> */}
										<div className="flex items-center">
											<div className="relative font-medium">Log Out</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{location.pathname.includes('profile') ||
			location.pathname.includes('buy_plan') ? (
				<ProfileTabs />
			) : (
				<ApplicationTabs />
			)}
		</div>
	);
};

export default ProfileDropdown;
