import { useEffect, useRef, useState } from 'react';

export const MobileNewFolder = ({ close, handleFolderNameSubmit }) => {
	const [folderName, setFolderName] = useState('');
	const [isVisible, setIsVisible] = useState(true);
	const popupRef = useRef(null);

	const onDoneTextClick = () => {
		if (folderName === '') {
			setFolderName('');
		}
		handleFolderNameSubmit(folderName);
		setIsVisible(false);
		setTimeout(() => {
			close();
		}, 250);
	};

	// Detect clicks outside the ref'ed div
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				close();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className='w-full bg-overlay-new-background overflow-hidden flex px-0 pb-0 box-border leading-[normal] tracking-[normal]'>
			<div
				ref={popupRef}
				className={`w-full absolute h-[150px] left-0 bottom-0 flex-1 rounded-t-xl rounded-b-none bg-white flex flex-col items-start justify-start pt-[27px] px-5 box-border gap-[28px] max-w-full text-left text-sm text-white font-montserrat transition-transform ${
					isVisible ? 'animate-slideUp' : 'animate-slideDown'
				}`}
			>
				<header className='self-stretch flex flex-row items-start justify-between top-[0] z-[99] sticky gap-[20px] text-left text-base text-updated-color-blue font-montserrat'>
					<div
						className='relative inline-block min-w-[55px] cursor-pointer'
						onClick={() => {
							setIsVisible(false);
							setTimeout(() => {
								close();
							}, 250);
						}}
					>
						Cancel
					</div>
					<div className='flex flex-col items-start justify-start py-0 pr-[13px] pl-0 text-updated-color-new-black font-semibold'>
						New Folder
					</div>
					<div
						className='w-11 relative text-right inline-block min-w-[44px] cursor-pointer'
						onClick={onDoneTextClick}
					>
						Done
					</div>
				</header>
				<div className='self-stretch flex-1 flex flex-col items-start justify-start'>
					<input
						value={folderName}
						onChange={(e) => setFolderName(e.target.value)}
						placeholder='New folder'
						className='w-full border-none placeholder:text-[#8C8C8C] text-not-black py-1 pl-2 font-montserrat rounded-xl text-xs bg-updated-color-grey1 h-8 relative text-left flex items-center'
						type='text'
					/>
				</div>
			</div>
		</div>
	);
};
