import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userInfoRef } from "../../httpContext";

const HomepageMobile = () => {
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const onLearnMoreAboutUsClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='enablingIndividualsFacing']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        setIsUserLoggedIn(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full relative bg-white h-auto overflow-hidden text-left text-5xl text-not-black-black font-montserrat">
      {/* <div className="w-full h-24 flex flex-row items-center justify-between pt-[55px] px-4 pb-[30px] box-border text-updated-color-blue">
        <div className="flex flex-row items-center justify-start">
          <div className="w-44 relative h-[41px]">
            <div className="absolute w-[73.86%] top-[14.63%] left-[26.14%] tracking-[-0.02em] font-medium inline-block">
              interpret
            </div>
            <img
              className="absolute h-full w-[23.3%] top-[0%] right-[76.7%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/1024px-x-1024px-round-w-text1.svg"
            />
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[20px] text-center text-xl text-not-black-black">
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
            onClick={() => {
              navigate('/plan');
            }}>
            <div className="relative font-medium">Pricing</div>
          </div>
          <div className="flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer"
            onClick={() => {
              if (userInfoRef.current?.verified_email) navigate('/transcribe')
              else
                navigate('/login');
            }}>
            <div className="relative font-medium">{isUserLoggedIn ? 'Dashboard' : 'Login'}</div>
          </div>
        </div>
      </div> */}
      <div className="w-full px-4 flex flex-col items-start justify-start gap-[25px] text-[36px] text-gray-400 mt-8 md:mt-16">
        <div className="flex flex-col items-start justify-start gap-[15px]">
          <div className="flex flex-col items-start justify-start">
            <div className="w-full max-w-[745px] relative leading-[137%] font-semibold text-transparent !bg-clip-text [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block">
              Every Conversation, Perfectly Captured.
            </div>
          </div>
          <div className="flex flex-col items-start justify-start text-xl">
            <div className="w-full max-w-[691px] relative leading-[137%] font-medium inline-block">
              Elevate your communication with real-time transcription, enhance
              your conversations with precision and clarity
            </div>
          </div>
        </div>
        <div className="w-full max-w-[222px] rounded-3xs bg-updated-color-blue flex flex-row items-center justify-center p-5 box-border text-5xl text-white cursor-pointer"
          onClick={() => {
            if (userInfoRef.current?.verified_email) navigate('/transcribe')
            else
              navigate('/signup');
          }}>
          <div className="relative tracking-[-0.41px] font-medium">
            {isUserLoggedIn ? 'Dashboard' : 'Start for Free'}
          </div>
        </div>
      </div>
      <div
          className="w-full flex flex-row items-center justify-center py-2.5 px-0 box-border gap-[17px] cursor-pointer text-center text-lg mt-10"
          onClick={onLearnMoreAboutUsClick}
        >
          <div className="relative font-semibold">learn more about us</div>
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0"
            alt=""
            src="/arrow--arrow-bottom-left-1.svg"
          />
        </div>
      <div className="w-full mt-10 flex flex-col items-center justify-start gap-[10px] text-4xl">
        <div className="flex flex-col items-start justify-center text-center">
          <div className="relative leading-[123%] font-semibold">
            Introducing,
          </div>
          <div className="relative leading-[123%]">
            <b className="text-updated-color-blue">interpret</b>
          </div>
        </div>
        <div className="relative text-6xl leading-[123%] font-semibold">
          created by BlueJay AI
        </div>
      </div>
      <div
        className="w-full px-4 text-lg font-medium text-updated-color-grey2 text-center inline-block mt-10"
        data-scroll-to="enablingIndividualsFacing"
      >
        Enabling individuals facing language barriers to enjoy seamless
        conversations with an AI-powered transcription tool.
      </div>
      <div className="w-full px-4 mt-10 flex flex-col items-center justify-start gap-[20px] text-3xl text-updated-color-new-black">
        <div className="w-full shadow-md rounded-xl bg-white box-border overflow-hidden border-[1px] border-solid border-updated-color-grey1 p-8">
          <div className="flex flex-col md:flex-row items-center justify-between gap-[20px]">
            <div className="flex-1">
              <div className="font-semibold">Simple to use mobile transcriptions.</div>
              <div className="text-xl font-medium text-updated-color-grey2 mt-4">
                Visit interpretapp.ai on your mobile device to transcribe audio, text, and images on the go.
              </div>
            </div>
            <img
              className="w-full max-w-[347px] h-auto object-cover mt-4 md:mt-0"
              alt=""
              src="/mask-group@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="w-full px-4 mt-8 flex flex-col items-center justify-center gap-[20px] text-xl">
        <div className="relative tracking-[-0.01em] leading-[123%]">
          Integrated with:
        </div>
        <div className="flex flex-wrap items-start justify-center gap-[20px]">
          <img
            className="w-[100px] h-auto object-cover"
            alt=""
            src="/image-74@2x.png"
          />
          <img
            className="w-[100px] h-auto object-cover"
            alt=""
            src="/image-75@2x.png"
          />
          <img
            className="w-[100px] h-auto object-cover"
            alt=""
            src="/image-76@2x.png"
          />
          <img
            className="w-[100px] h-auto object-cover"
            alt=""
            src="/image-77@2x.png"
          />
          <img
            className="w-[100px] h-auto object-cover"
            alt=""
            src="/image-78@2x.png"
          />
        </div>
        <div className="w-full h-[400px] px-4 mt-8 items-center justify-center relative">
        <img
          className="w-full max-w-[300px] h-auto absolute top-0 left-1/2 transform -translate-x-1/2"
          alt=""
          src="/group-1707478120.svg"
        />
        <div className="w-full max-w-[500px] flex flex-col md:flex-row items-center justify-center relative">
          <img
            className="w-full max-w-[250px] h-auto absolute top-[60px] left-1/4 transform -translate-x-1/2"
            alt=""
            src="/subject-2@2x.png"
          />
          <img
            className="w-full max-w-[250px] h-auto absolute top-[69px] left-3/4 transform -translate-x-1/2"
            alt=""
            src="/subject@2x.png"
          />
        </div>
      </div>
      </div>
    </div>
  );
};

export default HomepageMobile;
